import React, { useContext, useEffect, useState } from 'react';
import Switch from 'rc-switch';
import appContext from '../../../utils/context/appContext';
import { TasksData } from '../../../api/eva/tasks/task.get';
import subscribe, {
  TaskUserSubscriptionPostResponseError, TaskUserSubscriptionPostResponseMessageOk,
  TaskUserSubscriptionPostResponseOk,
} from '../../../api/eva/tasks/task.user-subscription.post';
import unsubscribe, {
  TaskUserSubscriptionDeleteResponseError, TaskUserSubscriptionDeleteResponseMessageOk,
  TaskUserSubscriptionDeleteResponseOk,
} from '../../../api/eva/tasks/task.user-subscription.delete';


const TaskIsWorksSwitch = (props: { task: TasksData, disableExecutionSwitch: () => void , enableExecutionSwitch: () => void }) => {
  const [isWorks, setIsWorks] = useState<boolean>(props.task.isWorksOnUsersCampaigns);
  const [disabled, setDisabled] = useState<boolean>(false);
  const MainContext = useContext(appContext);

  useEffect(() => {
    setIsWorks(props.task.isWorksOnUsersCampaigns);
  }, [props.task]);

  const updateIsWorks = (newIsWorks: boolean) => {

    setDisabled(true);
    props.disableExecutionSwitch();

    if (newIsWorks)
      subscribe(props.task.id).then(r => {
        updateSwitchState(r, newIsWorks);
      });
    else
      unsubscribe(props.task.id).then(r => {
        updateSwitchState(r, newIsWorks);
      });
  };

  const isResponseOK = (r: TaskUserSubscriptionPostResponseOk|TaskUserSubscriptionPostResponseError|TaskUserSubscriptionDeleteResponseOk|TaskUserSubscriptionDeleteResponseError) => {
    return typeof r.message === 'object';
  }

  const updateSwitchState = (r: TaskUserSubscriptionPostResponseOk|TaskUserSubscriptionPostResponseError|TaskUserSubscriptionDeleteResponseOk|TaskUserSubscriptionDeleteResponseError, newState: boolean) => {
    setDisabled(false);
    if (!isResponseOK(r)) {
      setIsWorks(isWorks);
      props.task.isWorksOnUsersCampaigns = isWorks;
      if (isWorks)
        props.enableExecutionSwitch();

      MainContext.notify(r.message as string, 'error');
      return;
    }

    let message = r.message as TaskUserSubscriptionPostResponseMessageOk|TaskUserSubscriptionDeleteResponseMessageOk,
      newValue= message.data.isWorks === undefined ? newState : message.data.isWorks;
    props.task.isWorksOnUsersCampaigns = newValue;

    setIsWorks(newValue);
    if (newValue) {
      props.enableExecutionSwitch();
      props.task.parameters.map((param) => {param.isSubscribedOnParam = true});
    } else {
      props.disableExecutionSwitch;
      props.task.parameters.map((param) => {param.isSubscribedOnParam = false});
    }
  }

  const onChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (checked) {
      updateIsWorks(true);
    } else {
      updateIsWorks(false);
    }
  };

  return (<Switch
      disabled={disabled}
      checked={isWorks}
      onChange={onChange}
      checkedChildren='on'
      unCheckedChildren='off' />
  );
};
export default TaskIsWorksSwitch;
