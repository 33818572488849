import React, { ComponentProps } from 'react';
import { DataColumns } from '../../../utils/campaigns/datatable';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import TableCellRaw from '@mui/material/TableCell';
import { TotalCalculated } from '../../../api/campaigns/campaigns.get';
import { DataColumnsRealtimeBlacklist } from '../../../utils/realtime-blacklist/datatable';


export default function(props: ComponentProps<any> & { row: TotalCalculated, columns: DataColumns[] }) {
  function dataCutMouseOverHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.currentTarget.innerText = e.currentTarget.dataset.cut === undefined ? '' : e.currentTarget.dataset.cut;
  }

  function dataCutMouseOutHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.currentTarget.innerText = e.currentTarget.dataset.base === undefined ? '' : e.currentTarget.dataset.base;
  }
  
  function rounding(num: number) {
    if(num > 1_000 && num < 1_000_000)
      return (num / 1_000).toFixed(1) + 'k'
    if(num > 1_000_000 && num < 1_000_000_000)
      return (num / 1_000_000).toFixed(1) + 'kk';
    if(num > 1_000_000_000)
      return (num / 1_000_000_000).toFixed(1) + 'kkk';
  }

  return (<React.Fragment>
    <TableRow
      classes={{ root: 'total-row' }}
      style={{ backgroundColor: 'rgba(50,200,50,0.1)' }}
      key={'total'}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCellRaw className={'sticky-width-50'} style={{wordBreak: "unset"}}>Сумма</TableCellRaw>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.TYPE}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-110'} targetColumn={DataColumnsRealtimeBlacklist.SYSTEM}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-110'} targetColumn={DataColumnsRealtimeBlacklist.PUBLISHER}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-110'} targetColumn={DataColumnsRealtimeBlacklist.DOMAIN_ID}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-110'} targetColumn={DataColumnsRealtimeBlacklist.DOMAIN}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-150'} targetColumn={DataColumnsRealtimeBlacklist.TAG}></TableCell>
      <TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.IMPS}
                 data-cut={props.row.reqs}
                 data-base={rounding(props.row.reqs)}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}>
        {rounding(props.row.reqs)}
      </TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.IMPS_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.HITS}
                 data-cut={props.row.hits}
                 data-base={rounding(props.row.hits)}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}>
        {rounding(props.row.hits)}
      </TableCell>
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.HITREAL}
                 data-cut={props.row.hitreal}
                 data-base={rounding(props.row.hitreal)}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}>
        {rounding(props.row.hitreal)}
      </TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.HITREAL_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.CLICKS}
                 data-cut={props.row.clicks}
                 data-base={rounding(props.row.clicks)}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}>
        {rounding(props.row.clicks)}
      </TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.VIEWS_PROC}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CTR}></TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.CTR_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.VTR}></TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.VTR_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.LIMIT_SPENT}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.COSTS}>
        {Number(props.row.spent.toFixed(2))}
      </TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CPM}>
        {props.row.cpm}
      </TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.CPM_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CPC}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.PDP}>
        {props.row.leads}
      </TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CR}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CR_PROC}>
        {props.row.crProc}
      </TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.CPA}>
        {props.row.cpa}
      </TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.CPA_VARIANCE}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.CONV_COST}></TableCell>
      {/*<TableCell columns={props.columns} targetColumn={DataColumnsRealtimeBlacklist.KOEF}></TableCell>*/}
      <TableCell columns={props.columns} className={'sticky-width-90'} targetColumn={DataColumnsRealtimeBlacklist.RATE}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.LISTS}></TableCell>
      <TableCell columns={props.columns} className={'sticky-width-70'} targetColumn={DataColumnsRealtimeBlacklist.MIN}></TableCell>
    </TableRow>
  </React.Fragment>);
}