//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../../../apiKey';

const PATH_URL = '/eva/task/{taskId}/parameter/{paramId}/subscription';

// @ts-ignore
const key = new apiKey();

function getUrl(taskId: number, paramId: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL)
    .replace('{taskId}', taskId)
    .replace('{paramId}', paramId);
}

export type TaskParameterUserSubscriptionDeleteResponseOk = {
  'status': number,
  'message': TaskParameterUserSubscriptionDeleteResponseMessageOk,
}

export type TaskParameterUserSubscriptionDeleteResponseMessageOk = {
  data: {
    isSubscribed: boolean
  },
}

export type TaskParameterUserSubscriptionDeleteResponseError = {
  'status': number,
  'message': TaskParameterUserSubscriptionDeleteResponseMessageError,
}

export type TaskParameterUserSubscriptionDeleteResponseMessageError = string


export default async function unsubscribeParameter(taskId: number, paramId: number): Promise<TaskParameterUserSubscriptionDeleteResponseOk | TaskParameterUserSubscriptionDeleteResponseError> {
  try {
    let r = await axios.delete(getUrl(taskId, paramId), {
      headers: {
        apiKey: key.get(),
      },
    });
    return <TaskParameterUserSubscriptionDeleteResponseOk | TaskParameterUserSubscriptionDeleteResponseError>r.data;
  } catch (e) {
    const resp: TaskParameterUserSubscriptionDeleteResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  }
}

export function isResponseOK(response: TaskParameterUserSubscriptionDeleteResponseOk | TaskParameterUserSubscriptionDeleteResponseError): boolean {
  return typeof response.message === 'object';
}
