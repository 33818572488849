//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';

const PATH_URL = '/campaigns/{id}/bundles/ban-with-rules';

export type CampaignBundleBanWithRulesPostRequest = {
  minSpend?: number
  maxConvs?: number
  minConvs?: number
}
export type CampaignBundleBanWithRulesPostResponseOk = {
  'status': number,
  'message': CampaignBundleBanWithRulesPostResponseMessageOk,
}

export type CampaignBundleBanWithRulesPostResponseMessageOk = {
  data: null,
}

export type CampaignBundleBanWithRulesPostResponseError = {
  'status': number,
  'message': CampaignBundleBanWithRulesPostResponseMessageError,
}

export type CampaignBundleBanWithRulesPostResponseMessageError = string

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function post(campaignId: number, request: CampaignBundleBanWithRulesPostRequest): Promise<CampaignBundleBanWithRulesPostResponseOk | CampaignBundleBanWithRulesPostResponseError> {
  return axios.post(getUrl(campaignId), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignBundleBanWithRulesPostResponseMessageOk | CampaignBundleBanWithRulesPostResponseError>r.data;
  }).catch(e => {
    const resp: CampaignBundleBanWithRulesPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignBundleBanWithRulesPostResponseOk | CampaignBundleBanWithRulesPostResponseError): boolean {
  return typeof response.message !== 'string';
}