//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/minute-stats';

export type CampaignMinuteStatsResponseOk = {
  'status': number,
  'message': CampaignMinuteStatsResponseMessageOk,
}

export type CampaignMinuteStatsResponseMessageOk = {
  data: CampaignMinuteStats[],
}

export type CampaignMinuteStatsResponseError = {
  'status': number,
  'message': CampaignMinuteStatsResponseMessageError,
}

export type CampaignMinuteStatsResponseMessageError = string


export type CampaignMinuteStats = {
  convs: number
  cpa: number
  cpc: number
  cpm: number
  ctr: number
  date: string | Date
  rate: number
  rateAvg: number
  reqs: number
  spent: number
  vtr: number
  wins: number
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string
{
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}
export default function get(id: number): Promise<CampaignMinuteStatsResponseOk | CampaignMinuteStatsResponseError> {
  return axios.get(getUrl(id), {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignMinuteStatsResponseMessageOk | CampaignMinuteStatsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignMinuteStatsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignMinuteStatsResponseOk | CampaignMinuteStatsResponseError): boolean {
  return typeof response.message !== 'string';
}