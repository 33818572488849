import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import post, {
  CampaignBundleCopyBlacklistToWhitelistPostResponseError,
  isResponseOK,
} from '../../api/campaigns/campaigns.bundle.copy-bl-to-wl.post';

export default function(props: ComponentProps<any> & { toggle: () => {}, campaignToId: number, isOpen: boolean }) {
  const dataContext = useContext(appContext);
  const [campaignFromId, setCampaignFromId] = useState<string>("");
  const [campaignToId, setCampaignToId] = useState<string>(String(props.campaignToId));

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
  }, [props.isOpen]);

  useEffect(() => {
    setCampaignToId(String(props.campaignToId));
  }, [props.campaignToId]);


  const copyBlToWl = () => {
    post(campaignFromId, campaignToId).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignBundleCopyBlacklistToWhitelistPostResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      props.toggle();
    });
  };

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}>
    <ModalBody>
      <h3 className='h5 text-center'>
        <b>Добавление блэклист связок в вайтлист другой кампании</b>
      </h3>
      <div className={'text-left'}>
        <label className={'d-flex'} htmlFor='campaignFrom'>
          <div className={'col-md-8 text-left'}>ID исходной кампании</div>
          <input id={'campaignFrom'} className={'form-control'} value={campaignFromId} onChange={(e) => setCampaignFromId(e.currentTarget.value)} type='text' />
        </label>
        <label className={'d-flex'} htmlFor='campaignTo'>
          <div className={'col-md-8 text-left'}>ID целевой кампании</div>
          <input id={'campaignTo'} className={'form-control'} value={campaignToId} onChange={(e) => setCampaignToId(e.currentTarget.value)} type='text' />
        </label>
      </div>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        copyBlToWl();
      }}>
        Копировать
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}