import { DateTime } from 'luxon';
import { IfInvalid } from 'luxon/src/_util';

export function formatServerDateYmd(d: Date): string | IfInvalid<'Invalid DateTime'> {
  return DateTime.fromJSDate(d).toFormat('yyyy-MM-dd');
}

export function getDateNDaysAgo(days: number): Date {
  const msInDay = 86400000;
  return new Date(Date.now() - days * msInDay);
}

export function getCurrentWeekStartDate(): Date {
  const curr = new Date();
  const first = curr.getDate() - curr.getDay() + 1;
  curr.setDate(first);
  return curr;
}

export function getLastWeekStartDate(): Date {
  const date = getCurrentWeekStartDate();
  date.setDate(date.getDate() - 7);
  return date;
}

export function getLastWeekEndDate(): Date {
  const date = getCurrentWeekStartDate();
  date.setDate(date.getDate() - 1);
  return date;
}

export function getCurrentMonthStartDate(): Date {
  const date = new Date();
  date.setDate(date.getDate() - date.getDate() + 1);
  return date;
}

export function getLastMonthStartDate(): Date {
  const date = getCurrentMonthStartDate();
  if (date.getMonth() === 1) {
    date.setFullYear(date.getFullYear(), 12, 1);
  } else {
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
  }
  return date;
}

export function getLastMonthEndDate(): Date {
  const date = getCurrentMonthStartDate();
  date.setDate(0);
  return date;
}

export function getNMonthAgoStartDate(numberOfMonth: number): Date {
  const date = getCurrentMonthStartDate();
  date.setMonth(date.getMonth() - numberOfMonth);
  date.setDate(1);
  return date;
}

export function getCurrentYearStartDate(): Date {
  return new Date(new Date().getFullYear(), 0, 1);
}

export function getLastYearStartDate(): Date {
  const date = getLastYearEndDate();
  date.setMonth(0, 1);
  return date;
}

export function getLastYearEndDate(): Date {
  return new Date((new Date()).getFullYear(), 0, 0);
}

export const formatDate = (d: Date) => {
  const day = d.getDate() <= 9 ? '0' + String(d.getDate()) : d.getDate();
  const month = (d.getMonth() + 1) <= 9 ? '0' + String(d.getMonth() + 1) : (d.getMonth() + 1);
  return d.getFullYear() + '-' + month + '-' + day;
};

export const formatDateTime = (d: Date) => {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  let hour = String(d.getHours());
  let min = String(d.getMinutes());
  let sec = String(d.getSeconds());
  if (month.length == 1) {
    month = '0' + month;
  }
  if (day.length == 1) {
    day = '0' + day;
  }
  if (hour.length == 1) {
    hour = '0' + hour;
  }
  if (min.length == 1) {
    min = '0' + min;
  }
  if (sec.length == 1) {
    sec = '0' + sec;
  }
  return d.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
};

export function addDays(date: string, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}