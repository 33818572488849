import React, { ComponentProps, useContext, useEffect, useState,  } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import post, {
  OffersPostRequest,
  OffersPostResponseError,
  isResponseOK as isPostResponseOk,
} from '../../api/offers/offers.post';
import { SelectData } from '../../api/campaigns/campaigns.get';
import CreatableSelect from 'react-select/creatable';

export default function(props: ComponentProps<any> & { toggle: () => {}, isOpen: boolean, wrapperNeedUpdate: () => {}, domains: [], categories: [], countries: [] }) {
  const dataContext = useContext(appContext);
  const [name, setName] = useState<string>('');
  const [domain, setDomain] = useState<any>(0);
  const [urls, setUrls] = useState<any[]>([{
    'id': 0,
    'url': ''
  }]);
  const [country, setCountry] = useState<any>('');
  const [conv, setConv] = useState<number>(0.01);
  const [category, setCategory] = useState<any>(0);
  const [dailyLimit, setDailyLimit] = useState<number>(1);
  const [gmt, setGmt] = useState<number>(0);
  const [checkDayLimit, setCheckDayLimit] = useState<boolean>(false);


  const createOffer = () => {
    if(name == '' || !checkUrls() || domain.value == 0 || country.value == '' || conv == 0 || category.value == 0) return;
    function buildRequest(): OffersPostRequest {
      return {
        name: name,
        domain: domain.value,
        url: urls,
        conv: conv,
        country: country.value,
        category: category.value,
        gmt: gmt,
        limit: dailyLimit,
        checkDayLimit: checkDayLimit ? 1 : 0
      }
    }
    dataContext.setIsLoading(true);
    post(buildRequest()).then(r => {
      if (!isPostResponseOk(r)) {
        r = r as OffersPostResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      clearFill();
    }).finally(() => {
      dataContext.setIsLoading(false);
      props.wrapperNeedUpdate();
    });
  };

  const checkUrls = () => {
    let list = urls;
    if(list.length === 0) return false;
    let result = true;
    list.map((url) => {
      result = (url.url == '') ? false : result;
    });
    return result;
  }

  const clearFill = () => {
    props.toggle();
    setName('');
    setDomain(0);
    setUrls([{
      'id': 0,
      'url': ''
    }]);
    setCountry('');
    setConv(0.01);
    setCategory(0);
    setGmt(0);
    setDailyLimit(1);
    setCheckDayLimit(false);
  }

  const onChangeDomainsSelect = (options: any) => {
    setDomain(options);
  };

  const onChangeCountriesSelect = (options: any) => {
    setCountry(options);
  };

  const onChangeCategorySelect = (options: any) => {
    setCategory(options);
  };

  const addToUrls = (key: number, value: any) => {
    let list = urls;
    list[key].url = value;
    setUrls([...list]);
  }

  const removeUrlInput = (index: number) => {
    let list = urls;
    list.splice(index, 1);
    setUrls([...list]);
  }

  const addUrlInput = () => {
    setUrls([...urls, {
      'id': 0,
      'url': ''
    }]);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}>
    <ModalBody className="text-center">
      <h3 className='h5 text-center'>
        <b>Добавление оффера</b>
      </h3>
      <label className={'d-flex'} htmlFor='offerName'>
        <div className={'col-md-3 text-left'}>Название</div>
        <input className={'form-control col-md-9'} id={'offerName'}
               onChange={e => setName(String(e.target.value))} type='text' value={name} minLength={1}/>
      </label>
      {urls.map((url, index) => (
        <label className={'d-flex'} htmlFor='offerUrl'>
          <div className={'col-md-3 text-left'}>Ссылка</div>
          <input className={'form-control col-md-8'} id={'offerUrl'}
                 onChange={e => addToUrls(index, String(e.target.value))} type='text' value={url.url} minLength={1}/>
          {urls.length > 1 &&
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                 className="css-tj5bde-Svg mt-2 col-md-1 text-center" onClick={e => removeUrlInput(index)}>
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          }
        </label>
      ))}
      {urls.length < 5 &&
        <button id={'addUrlInput'} className={'btn btn-success mb-1'} onClick={addUrlInput}>Добавить ссылку</button>
      }
      <label className={'d-flex'} htmlFor='offerConv'>
        <div className={'col-md-3 text-left'}>Стоимость конверсии</div>
        <input className={'form-control col-md-9'} id={'offerConv'}
               onChange={e => setConv(Number(e.target.value))} type='number' value={conv} minLength={1} step="any" min={0}/>
      </label>
      <label className={'d-flex'} htmlFor='offerDomain'>
        <div className={'col-md-3 text-left'}>Домен</div>
        <CreatableSelect isClearable options={props.domains} id={'offerDomain'} className={'col-md-9 no-padd'}
                         onChange={onChangeDomainsSelect}/>
      </label>
      <label className={'d-flex'} htmlFor='offerCountry'>
        <div className={'col-md-3 text-left'}>Страна</div>
        <CreatableSelect isClearable options={props.countries} id={'offerCountry'} className={'col-md-9 no-padd'}
                         onChange={onChangeCountriesSelect} isValidNewOption={() => false}/>
      </label>
      <label className={'d-flex'} htmlFor='offerCategory'>
        <div className={'col-md-3 text-left'}>Категория</div>
        <CreatableSelect isClearable options={props.categories} id={'offerCategory'} className={'col-md-9 no-padd'}
                         onChange={onChangeCategorySelect} isValidNewOption={() => false}/>
      </label>
      <label className={'d-flex'} htmlFor='offerConv'>
        <div className={'col-md-3 text-left'}>GMT</div>
        <input className={'form-control col-md-9'} id={'offerGmt'}
               onChange={e => setGmt(Number(e.target.value))} type='number' value={gmt} minLength={1} step="1"/>
      </label>
      <label className={'d-flex'} htmlFor='offerConv'>
        <div className={'col-md-10 text-left'}>Отключение по дневному лимиту</div>
        <input id='checkDayLimit' name={'checkDayLimit'} type='checkbox' className=''
               onChange={e => setCheckDayLimit(e.target.checked)} />
      </label>
      <label className={'d-flex'} htmlFor='offerConv'>
        <div className={'col-md-3 text-left'}>Дневной лимит</div>
        <input className={'form-control col-md-9'} id={'offerDailyLimit'}
               onChange={e => setDailyLimit(Number(e.target.value))} type='number' value={dailyLimit} minLength={1} step="any" min={0}/>
      </label>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        createOffer();
      }}>
        Создать
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        clearFill();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}