import React, { useContext, useEffect, useState } from 'react';
import { Campaign, CampaignStatus as CampaignStatusType } from '../../api/campaigns/campaigns.get';
import Switch from 'rc-switch';
import patch, { isResponseOK } from '../../api/campaigns/campaigns.patch';
import appContext from '../../utils/context/appContext';
import { CampaignMinuteStatsResponseError } from '../../api/campaigns/campaign.minute-stats.get';


const CampaignStatus = (props: { campaign: Campaign, status?: CampaignStatusType }) => {
  const [status, setStatus] = useState<CampaignStatusType>(props.status !== undefined ? props.status : props.campaign.status as CampaignStatusType);
  const [disabled, setDisabled] = useState<boolean>(false);
  const MainContext = useContext(appContext);

  useEffect(() => {
    if (props.status !== undefined) {
      setStatus(props.status);
    }
  }, [props.status]);

  const updateStatus = (status: CampaignStatusType) => {
    setDisabled(true);
    patch(props.campaign.id, { status }).then(r => {
      setDisabled(false);
      if (!isResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        MainContext.notify(r.message, 'error');
        return;
      }
      setStatus(status);
    });
  };

  const onChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {

    if (checked) {
      updateStatus(CampaignStatusType.ON);
    } else {
      updateStatus(CampaignStatusType.OFF);
    }
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      {status !== CampaignStatusType.ARCHIVED ?
      <Switch className={props.campaign.status == 'on' && props.campaign.isSleep ? 'sleep' : ''} disabled={disabled}
              checked={status === CampaignStatusType.ON} onChange={onChange} checkedChildren='on'
              unCheckedChildren='off' /> : <span>в архиве</span>}
    </div>);
};
export default CampaignStatus;
