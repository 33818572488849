//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaign-group';

export type CampaignGroupPostResponseOk = {
  'status': number,
  'message': CampaignGroupPostResponseMessageOk,
}

export type CampaignGroupPostResponseMessageOk = {
  data: CampaignGroupPostData,
}

export type CampaignGroupPostResponseError = {
  'status': number,
  'message': CampaignGroupPostResponseMessageError,
}

export type CampaignGroupPostResponseMessageError = string

export type CampaignGroupPostData = {
  group: {
    id: number,
    name: string,
  }
}

// @ts-ignore
const key = new apiKey();

export default function post(groupName: string): Promise<CampaignGroupPostResponseOk | CampaignGroupPostResponseError> {
  return axios.post(process.env.REACT_APP_API_URL + PATH_URL, { groupName }, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignGroupPostResponseMessageOk | CampaignGroupPostResponseError>r.data;
  }).catch(e => {
    const resp: CampaignGroupPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignGroupPostResponseOk | CampaignGroupPostResponseError): boolean {
  return typeof response.message !== 'string';
}