import { createContext } from 'react';
import { CampaignRequest, CampaignResponseOk } from '../../api/campaigns/campaigns.get';

export default createContext({
  requestData: (forceScroll: boolean = true) => {
  },
  requestFulfilmentTelegramChatId: () => {
  },
  filter: null as CampaignRequest | null,
  response: null as CampaignResponseOk | null,
});