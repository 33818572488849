//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/subscription';

export type CampaignSubscriptionPostResponseOk = {
  'status': number,
  'message': CampaignSubscriptionPostResponseMessageOk,
}

export type CampaignSubscriptionPostMessageOk = {
  data: CampaignSubscriptionPostData,
}

export type CampaignSubscriptionPostResponseError = {
  'status': number,
  'message': CampaignSubscriptionPostResponseMessageError,
}

export type CampaignSubscriptionPostResponseMessageError = string

export type CampaignSubscriptionPostData = {
  info: 'ok'
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function post(id: number): Promise<CampaignSubscriptionPostResponseOk | CampaignSubscriptionPostResponseError> {
  return axios.post(getUrl(id), {}, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignSubscriptionPostResponseMessageOk | CampaignSubscriptionPostResponseError>r.data;
  }).catch(e => {
    const resp: CampaignSubscriptionPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignSubscriptionPostResponseOk | CampaignSubscriptionPostResponseError): boolean {
  return typeof response.message !== 'string';
}