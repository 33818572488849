import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import get, {
  CommonMinuteStatsHoursCount, CommonMinuteStatsRequest,
  CommonMinuteStatsResponseOk,
  isResponseOK,
} from '../../api/campaigns/campaigns.minute-stats.get';
import { CampaignRequest } from '../../api/campaigns/campaigns.get';

export default function(props: ComponentProps<any> & { filter: CampaignRequest, id: string, campaignsId: number[], regions: number }) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState(getChartOptions([], [], []));
  const [hoursCount, setHoursCount] = useState<CommonMinuteStatsHoursCount>(1);
  const regions = useRef(props.regions);
  const campaignsId = useRef(props.campaignsId);
  const hoursCountRef = useRef<CommonMinuteStatsHoursCount>(1);

  const updateOptions = (hour: CommonMinuteStatsHoursCount) => {
    const req: CommonMinuteStatsRequest = {
      hoursCount: hour,
    };
    let key: string;
    for(key in props.filter) {
      // @ts-ignore
      req[key] = props.filter[key];
    }
    get(req).then(response => {
      if (!isResponseOK(response)) {
        return;
      }
      response = response as CommonMinuteStatsResponseOk;
      setOptions(getChartOptions(response.message.data.requests, response.message.data.wins, response.message.data.releases));
    });
  };

  useEffect(() => {
    campaignsId.current = props.campaignsId;
    regions.current = props.regions;
    hoursCountRef.current = hoursCount;
    updateOptions(hoursCount);
    const interval = setInterval(() => {
      updateOptions(hoursCountRef.current);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [props.filter, hoursCount]);

  useEffect(() => {
    updateOptions(hoursCount);
  }, []);


  return (
    <div id={props.id}>
      <div className={'d-flex flex-wrap mt-3'} style={{ gap: '3px' }}>
        <button type={'button'} onClick={() => setHoursCount(1)}
                className={'btn btn-sm btn-' + (1 === hoursCount ? 'primary' : 'info')}>1 час
        </button>
        <button type={'button'} onClick={() => setHoursCount(3)}
                className={'btn btn-sm btn-' + (3 === hoursCount ? 'primary' : 'info')}>3 часа
        </button>
        <button type={'button'} onClick={() => setHoursCount(6)}
                className={'btn btn-sm btn-' + (6 === hoursCount ? 'primary' : 'info')}>6 часов
        </button>
        <button type={'button'} onClick={() => setHoursCount(12)}
                className={'btn btn-sm btn-' + (12 === hoursCount ? 'primary' : 'info')}>12 часов
        </button>
        <button type={'button'} onClick={() => setHoursCount(24)}
                className={'btn btn-sm btn-' + (24 === hoursCount ? 'primary' : 'info')}>24 часа
        </button>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props}
      />
    </div>
  );
}

function getChartOptions(requests: [number, number][], wins: [number, number][], releases: number[]): Highcharts.Options {
  const GMT_PLUS_3 = 10800; // 3600 * 3
  const mapDataFromSecondsToMs = (row: [number, number]): [number, number] => {
    row[0] = convertFromSecondsToMs(row[0]+GMT_PLUS_3);
    return row;
  };
  const convertFromSecondsToMs = (timestamp: number) => {
    return timestamp * 1000;
  };
  const makeReleaseObject = (timestamp: number) => {
    return {
      value: convertFromSecondsToMs(timestamp),
      color: '#FF0000',
      width: 2,
      id: 'rate',
      zIndex: 1,
      label: {
        text: 'Релиз',
        style: {
          color: '#686A3B',
          fontWeight: 'bold',
        },
      },
    };
  };

  return {
    chart: {
      type: 'spline',
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        format: '{value:%H:%M}',
      },
      type: 'datetime',
      plotLines: releases.map((row) => makeReleaseObject(row)),
    },

    yAxis: [
      {
        title: {
          text: 'Wins',
        },
        opposite: true,
        min: 0,
        showEmpty: false,
      },
      {
        title: {
          text: 'Requests',
        },
        min: 0,
        showEmpty: false,
      },
      {
        title: {
          text: 'Releases',
        },
        min: 0,
      },

    ],
    tooltip: {
      split: false,
      shared: true,
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        let s = Highcharts.dateFormat('%H:%M', Number(this.x));
        let hits = -1;
        let imps = -1;
        if (this.points?.length) for (let i = 0; i < this.points?.length; i++) {
          if (String(this.points[i].series.name) === 'Wins') {
            hits = Number(this.y);
            s += '<br/>Wins: ' + hits;
          } else if (this.points[i].series.name === 'Releases') {
            //@todo
            // let releaseTime = this.x;
            //console.log(releaseTime);
            // s += '<br/>' + releases[releaseTime / 1000];
          } else {
            imps = Number(this.points[i].y);
            s += '<br/>Requests: ' + imps;
          }
        }
        if (imps != -1 && hits != -1) {
          s += '<br/>VTR: ' + Highcharts.numberFormat((imps == 0 ? 0 : hits / imps) * 100, 1) + '%';
        }
        return s;
      },

    },
    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, '#7cb5ec'],
            [1, String(Highcharts.color('#7cb5ec').setOpacity(0).get())],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: [
      {
        type: 'area',
        name: 'Wins',
        yAxis: 0,
        color: '#f7c02b',
        data: wins.map((row) => mapDataFromSecondsToMs(row)),
      },
      {
        type: 'area',
        name: 'Requests',
        yAxis: 1,
        color: '#2c3af7',
        data: requests.map((row) => mapDataFromSecondsToMs(row)),
      },
    ],
  };
}