//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/preferences/user-columns/';

export const sections = {
  ALL_CAMPAIGNS: 'all_campaigns',
  REALTIME_BLACKLIST: 'realtime_blacklist',
};

export type UserColumnRequest = {
  columns: string[],
}

export type UserColumnResponseOk = {
  'status': number,
  'message': UserColumnResponseMessageOk,
}

export type UserColumnResponseMessageOk = {
  data: {
    info: string,
  },
}


export type UserColumnResponseError = {
  'status': number,
  'message': UserColumnResponseMessageError,
}

export type UserColumnResponseMessageError = string



// @ts-ignore
const key = new apiKey();


export default function update(section: string, request: UserColumnRequest): Promise<UserColumnResponseOk | UserColumnResponseError> {
  return axios.put(process.env.REACT_APP_API_URL + PATH_URL +section, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <UserColumnResponseMessageOk | UserColumnResponseError>r.data;
  }).catch(e => {
    const resp: UserColumnResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: UserColumnResponseOk | UserColumnResponseError): boolean {
  return typeof response.message !== 'string';
}