import React, { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import appContext from '../../utils/context/appContext';
import { useSearchParams } from 'react-router-dom';
import FilterPreview from '../../components/CampaignSmartBidHistory/FilterPreview';
import DataTableColumns from '../../components/CampaignSmartBidHistory/DataTable/Columns';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import get, {
  CampaignSmartbidHistoryDataEl,
  CampaignSmartbidHistoryRequest,
  CampaignSmartbidHistoryResponseError,
  CampaignSmartbidHistoryResponseOk,
  isResponseOK,
  recognizeCampaignSmartbidHistoryRequest,
} from '../../api/campaigns/campaign.smartbid.history.get';

const CampaignSmartBidHistoryPage = function() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [response, setResponse] = useState<CampaignSmartbidHistoryResponseOk>();
    const [filter, setFilter] = useState<CampaignSmartbidHistoryRequest>(recognizeCampaignSmartbidHistoryRequest);
    const [pagination, setPagination] = useState({
      pageIndex: filter?.page ? filter?.page - 1 : 0,
      pageSize: filter?.results ?? 100, //customize the default page size
    });
    const [data, setData] = useState<CampaignSmartbidHistoryDataEl[]>([]);
    const [campaignId, setCampaignId] = React.useState<string>('');
    const dataContext = useContext(appContext);
    const columns = DataTableColumns(filter);

    useEffect(() => {
      setFilter({ ...filter, page: pagination.pageIndex + 1, results: pagination.pageSize });
    }, [pagination]);

    const updateFilter = (filter: CampaignSmartbidHistoryRequest) => {
      setFilter(filter);
    };

    const requestData = () => {
      if (campaignId.length === 0) {
        return;
      }

      dataContext.setIsLoading(true);
      get(Number(campaignId), filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as CampaignSmartbidHistoryResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as CampaignSmartbidHistoryResponseOk;
        setData(r.message.data.data);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    useEffect(() => {
      setSearchParams(qs.stringify(filter, { arrayFormat: 'brackets' }));
      requestData();
    }, [filter]);

    useEffect(() => {
      if (campaignId.length === 0) {
        return;
      }
      requestData();
    }, [campaignId]);

    return (<Page
      className='CampaignsAlerts'
      title='Смартбид: история'
    >
      <Filter filter={filter} setFilter={updateFilter} setCampaignId={(val: string) => setCampaignId(val)} />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilterModes
        enableColumnOrdering
        enableColumnDragging
        positionToolbarAlertBanner='bottom'
        localization={MRT_Localization_RU}
        initialState={{
          pagination: {
            pageSize: 10,
            pageIndex: 0,
          },
          sorting: [{ id: 'date', desc: true }],
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 100, 500],
          showFirstButton: true,
          showLastButton: true,
          SelectProps: {
            native: true,
          },
        }}
        manualPagination={true}
        pageCount={response?.message?.pages ?? 0}
        rowCount={response?.message?.total ?? 0}
        state={{ pagination }}
        onPaginationChange={((val) => {
          setPagination(val);
        })
        }
        enableColumnResizing
        enableStickyHeader
        enableStickyFooter
        enablePinning
        muiTableContainerProps={{ sx: { maxHeight: '100vh' } }}
      />
    </Page>);
  }
;

function Filter(props: any) {
  return (
    <React.Fragment>
      <FilterPreview {...props} />
    </React.Fragment>
  );
}

export default CampaignSmartBidHistoryPage;
;
