import React, { ChangeEvent, ComponentProps, useContext, useEffect, useMemo, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DataColumns } from '../../utils/campaigns/datatable';
import appContext from '../../utils/context/appContext';
import update, { isResponseOK, sections, UserColumnResponseError } from '../../api/preferences/user-columns.put';
import dashboardContext from '../../utils/context/dashboardContext';
import { MdClose } from 'react-icons/md';

export const keyPeriodicallyUpdatePage = 'periodicallyUpdatePage';
export default function(props: ComponentProps<any> & { needUpdatePage: boolean, setNeedUpdatePage: (val: boolean) => {}, toggle: () => {}, isOpen: boolean, className: string }) {
  const dataContext = useContext(appContext);
  const pageContext = useContext(dashboardContext);
  const [availableColumns, setAvailableColumns] = useState<Array<string>>([]);
  const columnsSeparated = useMemo(() => {
    return () => {
      const columns = DataColumns.getAllInString();
      const columnsSeparated: Array<Array<string>> = [];
      let indexToPush = 0;

      for (let i = 0; i < columns.length; i++) {
        if (i % 12 === 0) {
          indexToPush = columnsSeparated.push([]) - 1;
        }
        columnsSeparated[indexToPush].push(columns[i]);
      }
      return columnsSeparated;
    };
  }, [availableColumns]);

  useEffect(() => {
    setAvailableColumns((pageContext.response?.message.headers ?? []).filter(val => {
      return DataColumns.getAllInString().includes(val);
    }));
  }, [pageContext.response]);

  function onChangeInput(e: ChangeEvent<HTMLInputElement> | undefined) {
    if (!e) return;
    const target = e.currentTarget;

    setAvailableColumns((prev) => {
      if (target.checked) {
        return [...prev, target.value];
      }

      return prev.filter((val) => {
        return val !== target.value;
      });
    });
  }

  function saveNewColumns() {
    dataContext.setIsLoading(true);
    update(sections.ALL_CAMPAIGNS, { columns: availableColumns })
      .then(r => {
        if (!isResponseOK(r)) {
          r = r as UserColumnResponseError;
          dataContext.notify(r.message, 'error');
          dataContext.setIsLoading(false);
          return;
        }
        pageContext.requestData();
      }).catch(r => {
      dataContext.setIsLoading(false);
    });
  }

  return <Modal
    size={'lg'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Настройки</ModalHeader>
    <ModalBody>
      <form className='row'>
        <div className='col-12'>
          <FormGroup check>
            <Input
              id={keyPeriodicallyUpdatePage}
              name='check'
              type='checkbox'
              value={''}
              onChange={(e) => props.setNeedUpdatePage((prev: boolean) => !prev)}
              checked={props.needUpdatePage}
            />
            <Label check for={keyPeriodicallyUpdatePage}>
              Обновлять страницу раз в минуту
            </Label>
          </FormGroup>
        </div>
        {
          columnsSeparated().map((arr: Array<string>, index) => {
            return (<div className='col-4' style={index !== columnsSeparated().length - 1 ? {borderRight: "1px solid #dee2e6"} : {}} key={index}>
              {arr.map((value: string) => {
                return (<FormGroup check key={value}>
                  <Input
                    id={'tablecolumns-' + value}
                    name='check'
                    type='checkbox'
                    value={value}
                    onChange={(e) => onChangeInput(e)}
                    checked={availableColumns.includes(value)}
                  />
                  <Label check for={'tablecolumns-' + value}>
                    {DataColumns.getName(DataColumns.get(value) as DataColumns)}
                  </Label>
                </FormGroup>);
              })}
            </div>);
          })
        }
      </form>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        saveNewColumns();
        props.toggle();
      }}>
        Сохранить
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}