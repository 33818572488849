//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatServerDateYmd } from '../../utils/date';

const PATH_URL = '/campaigns/alerts';

export type CampaignsAlertsRequest = {
  startDate?: string,
  endDate?: string,
};

export function createCampaignsAlertsRequest(): CampaignsAlertsRequest {
  const date = new Date();
  return { startDate: formatServerDateYmd(date), endDate: formatServerDateYmd(date) };
}

export function recognizeCampaignsAlertsRequest(): CampaignsAlertsRequest {
  const date = new Date();
  const r = { startDate: formatServerDateYmd(date), endDate: formatServerDateYmd(date) };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createCampaignsAlertsRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type CampaignsAlertsResponseOk = {
  'status': number,
  'message': CampaignsAlertsResponseMessageOk,
}

export type CampaignsAlertsData = {
  cid: number,
  campaignName: string,
  time: string,
  message: string,
}

export type CampaignsAlertsResponseMessageOk = {
  data: {
    data: CampaignsAlertsData[]
  },
}

export type CampaignsAlertsResponseError = {
  'status': number,
  'message': CampaignsAlertsResponseMessageError,
}

export type CampaignsAlertsResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function get(request: CampaignsAlertsRequest): Promise<CampaignsAlertsResponseOk | CampaignsAlertsResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignsAlertsResponseOk | CampaignsAlertsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignsAlertsResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignsAlertsResponseOk | CampaignsAlertsResponseError): boolean {
  return typeof response.message === 'object';
}