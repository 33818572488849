//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';

const PATH_URL = '/campaigns/{id}/bundles';

export type CampaignBundlePatchRequest = {
  tagid: string
  domainId: number
  systemId: number
  app: 1 | 0,
  data: {
    dailyBudget?: number|null
    rate?: number|null
    list?: 'white'|'black'
    ignoreAi?: 1|0
    removeFromIgnoreAi?: 1|0
  }
}
export type CampaignBundlePatchResponseOk = {
  'status': number,
  'message': CampaignBundlePatchResponseMessageOk,
}

export type CampaignBundlePatchResponseMessageOk = {
  data: null,
}

export type CampaignBundlePatchResponseError = {
  'status': number,
  'message': CampaignBundlePatchResponseMessageError,
}

export type CampaignBundlePatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function patch(campaignId: number, request: CampaignBundlePatchRequest): Promise<CampaignBundlePatchResponseOk | CampaignBundlePatchResponseError> {
  return axios.patch(getUrl(campaignId), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignBundlePatchResponseMessageOk | CampaignBundlePatchResponseError>r.data;
  }).catch(e => {
    const resp: CampaignBundlePatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignBundlePatchResponseOk | CampaignBundlePatchResponseError): boolean {
  return typeof response.message !== 'string';
}