import { Content } from '../../components/Layout';
import React from 'react';
import logo200Image from '../../assets/img/logo/logo_200.jpg';

const EmptyLayout = ({ children, ...restProps }) => (
  <div>
    <main className="cr-app bg-light" {...restProps} style={{height: '75vh'}}>
      <Content style={{minHeight: '75vh'}} fluid>{children}</Content>
    </main>
    <footer className={'bg-light text-left p-3'} style={{height: '25vh', borderTop: "4px solid rgb(222, 226, 230)"}}>
      <h6><img src={logo200Image} width={25} alt='logo' /> © {new Date().getFullYear()} Clickium LTD</h6>
    </footer>
  </div>

);

export default EmptyLayout;
