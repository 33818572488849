import React, {
  ComponentProps,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CampaignRequest, createCampaignRequest, SelectData, Selects } from '../../api/campaigns/campaigns.get';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import {
  formatDate,
  getCurrentMonthStartDate,
  getCurrentWeekStartDate,
  getCurrentYearStartDate,
  getDateNDaysAgo,
  getLastMonthEndDate,
  getLastMonthStartDate,
  getLastWeekEndDate,
  getLastWeekStartDate,
  getLastYearEndDate,
  getLastYearStartDate,
  getNMonthAgoStartDate,
} from '../../utils/date';
import { DateTime } from 'luxon';
import { formatServerDateYmd } from '../../utils/date';

const formatDateValue = (dateValue: string): Date => {
  return DateTime.fromFormat(dateValue, 'yyyy-MM-dd').toJSDate()
};
import CreatableSelect from 'react-select/creatable';
import UserColumnSettingsModal from './UserColumnSettingsModal';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { DataColumns } from '../../utils/campaigns/datatable';


const regionsOptions = [
  {
    name: 'Америка',
    value: '2',
    extra: null,
  },
  {
    name: 'Азия',
    value: '5',
    extra: null,
  },
  {
    name: 'Европа',
    value: '14',
    extra: null,
  },
];


function setToMonday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 7;
  if (day !== 1) {
    date.setHours(-24 * (day - 1));
  }
  return date;
}

function setToSunday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 0;
  if (day !== 0) {
    date.setHours(24 * (7 - day));
  }
  return date;
}

function setToFirstMonthDate(date: Date, monthAgo: number = 0) {
  date.setMonth(date.getMonth() - monthAgo);
  date.setDate(1);
  return date;
}

function setToLastMonthDate(date: Date, monthAgo: number = 0) {
  return new Date(date.getFullYear(), date.getMonth() - monthAgo + 1, 0);
}

const dateRanges: SelectData[] = [
  {
    name: '',
    value: '',
    extra: null,
  },
  {
    name: '7 дней',
    value: '7_days',
    extra: null,
  },
  {
    name: '30 дней',
    value: '30_days',
    extra: null,
  },
  {
    name: '90 дней',
    value: '90_days',
    extra: null,
  },
  {
    name: 'текущая неделя',
    value: 'curr_week',
    extra: null,
  },
  {
    name: 'прошлая неделя',
    value: 'last_week',
    extra: null,
  },
  {
    name: 'текущий месяц',
    value: 'curr_month',
    extra: null,
  },
  {
    name: 'прошлый месяц',
    value: 'last_month',
    extra: null,
  },
  {
    name: 'последние 3 месяца',
    value: '3_months',
    extra: null,
  },
  {
    name: 'текущий год',
    value: 'curr_year',
    extra: null,
  },
  {
    name: 'прошлый год',
    value: 'last_year',
    extra: null,
  },
];

export default (props: ComponentProps<any> & { filter: CampaignRequest, selects: Selects }) => {
  const DatePeriod = useRef<HTMLSelectElement>(null);
  const [dateRange, setDateRange] = React.useState<SelectData>({ name: '', value: '', extra: null });
  const navigate = useNavigate();

  const resetDatePeriod = () => {
    if (DatePeriod.current !== null) {
      DatePeriod.current.value = '';
    }
  };

  function clearFilter(e: any) {
    e.preventDefault();
    navigate('/');
    props.setFilter(createCampaignRequest());
  }

  function calculateDatePeriodDiff(date: Date | null, filterParam: string) {
    const secondsInDay = 86400;
    let endDate = null;
    let startDate = null;
    if (filterParam === 'startDate') {
      startDate = date;
      endDate = formatDateValue(props.filter.endDate);
    } else {
      endDate = date;
      startDate = formatDateValue(props.filter.startDate);
    }

    if (!startDate || !endDate) {
      return null;
    }

    let mondayDate = setToMonday(new Date());
    let sundayDate = setToSunday(new Date());
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('curr_week');
      return;
    }

    mondayDate = setToMonday(new Date(), 1);
    sundayDate = setToSunday(new Date(), 1);
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('last_week');
      return;
    }

    let firstDateMonth = setToFirstMonthDate(new Date(), 1);
    let lastDateMonth = setToLastMonthDate(new Date(), 1);
    if (Math.abs(endDate.getTime() - lastDateMonth.getTime()) < 86400000
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('last_month');
      return;
    }

    firstDateMonth = setToFirstMonthDate(new Date(), 2);
    lastDateMonth = setToLastMonthDate(new Date());
    if (((endDate.getTime() - lastDateMonth.getTime()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('3_months');
      return;
    }

    let firstDateYear = getCurrentYearStartDate();
    if (((endDate.getTime() - Date.now()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateYear.getTime()) < 86400000) {
      onDataRangeChange('curr_year');
      return;
    }

    if ((endDate.getTime() - getLastYearEndDate().getTime()) < 86400000
      && Math.abs(startDate.getTime() - getLastYearStartDate().getTime()) < 86400000) {
      onDataRangeChange('last_year');
      return;
    }

    const diff = Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / 1000 / secondsInDay) + 1;
    switch (diff) {
      case 7:
        onDataRangeChange('7_days');
        return;
      case 30:
        onDataRangeChange('30_days');
        return;
      case 90:
        onDataRangeChange('90_days');
        return;
      default:
        break;
    }
  }

  const onChangeDatePicker = (date: Date | null, filterParam: string) => {
    resetDatePeriod();
    calculateDatePeriodDiff(date, filterParam);

    if (date === null) {
      const filter = { ...props.filter, page: 1 };
      delete (filter[filterParam]);
      props.setFilter(filter);
      return;
    }
    props.setFilter({ ...props.filter, [filterParam]: formatServerDateYmd(date), page: 1 });

  };
  const setDate = (dateStart: Date, dateEnd: Date) => {
    props.setFilter({ ...props.filter, startDate: formatDate(dateStart), endDate: formatDate(dateEnd), page: 1 });
  };

  function onDataRangeChange(value: string) {
    switch (value) {
      case '7_days':
        setDate(getDateNDaysAgo(7), new Date());
        setDateRange({ name: '7 дней', value: '7_days', extra: null });
        break;
      case '30_days':
        setDate(getDateNDaysAgo(30), new Date());
        setDateRange({ name: '30 дней', value: '30_days', extra: null });
        break;
      case '90_days':
        setDate(getDateNDaysAgo(90), new Date());
        setDateRange({ name: '90 дней', value: '90_days', extra: null });
        break;
      case 'curr_week':
        setDate(getCurrentWeekStartDate(), new Date());
        setDateRange({ name: 'текущая неделя', value: 'curr_week', extra: null });
        break;
      case 'last_week':
        setDate(getLastWeekStartDate(), getLastWeekEndDate());
        setDateRange({ name: 'прошлая неделя', value: 'last_week', extra: null });
        break;
      case 'curr_month':
        setDate(getCurrentMonthStartDate(), new Date());
        setDateRange({ name: 'текущий месяц', value: 'curr_month', extra: null });
        break;
      case 'last_month':
        setDate(getLastMonthStartDate(), getLastMonthEndDate());
        setDateRange({ name: 'прошлый месяц', value: 'last_month', extra: null });
        break;
      case '3_months':
        setDate(getNMonthAgoStartDate(2), new Date());
        setDateRange({ name: 'последние 3 месяца', value: '3_months', extra: null });
        break;
      case 'curr_year':
        setDate(getCurrentYearStartDate(), new Date());
        setDateRange({ name: 'текущий год', value: 'curr_year', extra: null });
        break;
      case 'last_year':
        setDate(getLastYearStartDate(), getLastYearEndDate());
        setDateRange({ name: 'прошлый год', value: 'last_year', extra: null });
        break;
      default:
        setDateRange({ name: '', value: '', extra: null });
        break;
    }
  }

  let regions = [] as string[];
  // console.log(props.filter);
  if (Array.isArray(props.filter.regions)) {
    regionsOptions.map(el => {
      if (~props.filter.regions.indexOf(el.value)) {
        regions.push(el.name);
      }
    });
  }
  const systems = [] as string[];
  if (Array.isArray(props.filter.systems)) {
    props.selects.systems.map((el: SelectData) => {
      if (~props.filter.systems.indexOf(el.value)) {
        systems.push(el.name);
      }
    })
  }
  const operators = [] as string[];
  if (Array.isArray(props.filter.operators)) {
    props.selects.operators.map((el: SelectData) => {
      if (~props.filter.operators.indexOf(el.value)) {
        operators.push(el.name);
      }
    })
  }
  const countries = [] as string[];
  if (Array.isArray(props.filter.countries)) {
    props.selects.countries.map((el: SelectData) => {
      if (~props.filter.countries.indexOf(el.value)) {
        countries.push(el.name);
      }
    })
  }
  const offers = [] as string[];
  if (Array.isArray(props.filter.offers)) {
    props.selects.offers.map((el: SelectData) => {
      if (~props.filter.offers.indexOf(el.value)) {
        offers.push(el.name);
      }
    })
  }

  const categories = [...props.filter?.categories ?? []] as string[];
  const formats = [...props.filter?.formats ?? []] as string[];
  const users = [...props.filter?.users ?? []] as string[];
  const campaigns = [...props.filter?.campaigns ?? []] as string[];
  let groupName = '';
  const selected = props.selects.groups.filter((el: SelectData) => {
    return el.value === props.filter?.groupId;
  });
  if (selected.length > 0) {
    groupName = selected[0].name;
  }

  return (<div>
      <div id={'campaign-filter-preview'} className='row campaign-filter-preview campaign-filter-preview--expanded'>
        {regions.length !== 3 && regions.length !== 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p className={'m-0'}>Регионы: {regions.join(', ')}</p>
        </div>}
        {offers.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Офферы: {offers.splice(0, 2).join(', ') + (offers.length > 0 ? ' и ещё ' + offers.length : '')}</p>
        </div>}
        {systems.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Системы: {systems.splice(0, 2).join(', ') + (systems.length > 0 ? ' и ещё ' + systems.length : '')}</p>
        </div>}
        {operators.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Операторы: {operators.splice(0, 2).join(', ') + (operators.length > 0 ? ' и ещё ' + operators.length : '')}</p>
        </div>}
        {formats.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Форматы: {formats.splice(0, 2).join(', ') + (formats.length > 0 ? ' и ещё ' + formats.length : '')}</p>
        </div>}
        {countries.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>{(props.filter.excludeCountries !== undefined ? 'Исключенные ' : '')} Страны: {countries.splice(0, 2).join(', ') + (countries.length > 0 ? ' и ещё ' + countries.length : '')}</p>
        </div>}
        {categories.length > 0 && categories.length != 2 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Категории: {categories.splice(0, 2).join(', ') + (categories.length > 0 ? ' и ещё ' + categories.length : '')}</p>
        </div>}
        {users.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Пользователи: {users.splice(0, 2).join(', ') + (users.length > 0 ? ' и ещё ' + users.length : '')}</p>
        </div>}
        {groupName.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p className={'m-0'}>Группы кампаний: {groupName}</p>
        </div>}
        {campaigns.length > 0 && <div className='col-12 m-0 col-sm-4 col-md-4 col-lg-3'>
          <p
            className={'m-0'}>Кампании: {campaigns.splice(0, 2).join(', ') + (campaigns.length > 0 ? ' и ещё ' + campaigns.length : '')}</p>
        </div>}
      </div>
      <div className={'row'}>
        <div className={'col-sm-6 col-md-4'}>
          <div>От</div>
          <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                      onChange={(date: Date) => onChangeDatePicker(date, 'startDate')}
                      value={formatDateValue(props.filter.startDate)} />
        </div>
        <div className={'col-sm-6 col-md-4'}>
          <div>До</div>
          <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                      onChange={(date: Date) => onChangeDatePicker(date, 'endDate')}
                      value={formatDateValue(props.filter.endDate)} />
        </div>
        <div className={'col-sm-6 col-md-4'}>
          <div>Период</div>
          <Select
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: '100%',
              }),
            }}
            name={''}
            placeholder={''}
            value={dateRange}
            onChange={(values: OnChangeValue<any, any>, meta: ActionMeta<any>) => {
              onDataRangeChange(values.value);
            }}
            getOptionLabel={(option: SelectData) => option.name}
            getOptionValue={(option: SelectData) => option.value} options={dateRanges} />
        </div>
        <div className='col-sm-12 m-3'>
          <div className='row' style={{ gap: '3px' }}>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                resetDatePeriod();
                return setDate(new Date(), new Date());
              }} className='btn btn-sm btn-info'>Сегодня
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                resetDatePeriod();
                setDate(getDateNDaysAgo(1), getDateNDaysAgo(1));
              }} className='btn btn-sm btn-info'>Вчера
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={clearFilter} className='btn btn-sm btn-primary'>очистить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};