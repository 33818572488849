import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { XYChart } from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import get, {
  CampaignMinuteStats,
  CampaignMinuteStatsResponseError,
  CampaignMinuteStatsResponseOk,
  isResponseOK,
} from '../../api/campaigns/campaign.minute-stats.get';

am4core.useTheme(am4themes_animated);

function createAxisAndSeries(chart: any, field: string, name: string, opposite: boolean, bullet: string, hidden: boolean = false) {
  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.strokeWidth = 3;
  series.yAxis = valueAxis;
  series.name = name;
  series.tooltipText = '{name}: [bold]{valueY}[/]';
  series.tensionX = 1;
  series.tensionY = 1;
  series.showOnInit = true;
  series.hidden = hidden;

  var interfaceColors = new am4core.InterfaceColorSet();

  switch (bullet) {
    case 'triangle':
      let bul = series.bullets.push(new am4charts.Bullet());
      bul.width = 12;
      bul.height = 12;
      bul.horizontalCenter = 'middle';
      bul.verticalCenter = 'middle';

      let triangle = bul.createChild(am4core.Triangle);
      triangle.stroke = interfaceColors.getFor('background');
      triangle.strokeWidth = 2;
      triangle.direction = 'top';
      triangle.width = 12;
      triangle.height = 12;
      break;
    case 'rectangle':
      bul = series.bullets.push(new am4charts.Bullet());
      bul.width = 10;
      bul.height = 10;
      bul.horizontalCenter = 'middle';
      bul.verticalCenter = 'middle';

      let rectangle = bul.createChild(am4core.Rectangle);
      rectangle.stroke = interfaceColors.getFor('background');
      rectangle.strokeWidth = 2;
      rectangle.width = 10;
      rectangle.height = 10;
      break;
    default:
      bul = series.bullets.push(new am4charts.CircleBullet());
      bul.circle.stroke = interfaceColors.getFor('background');
      bul.circle.strokeWidth = 2;
      break;
  }

  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 3;
  valueAxis.renderer.line.stroke = series.stroke;
  valueAxis.renderer.labels.template.fill = series.stroke;
  valueAxis.renderer.opposite = opposite;
}

type DataTableMinuteStats = {
  data: CampaignMinuteStats[],
  campaignId: number
};

export default function(props: ComponentProps<any> & DataTableMinuteStats) {
  const chart = useRef<null | XYChart>(null);
  const [data, setData] = useState<CampaignMinuteStats[]>([]);

  useEffect(() => {
    let isMounted = true;
    get(props.campaignId).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        // dataContext.notify(r.message, 'error');
        return [];
      }
      r = r as CampaignMinuteStatsResponseOk;
      if (isMounted) {
        setData(r.message.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    const plot = am4core.create('chartdiv_' + props.campaignId, am4charts.XYChart);
    plot.colors.step = 2;
    let chartData = data;

    for (let i = 0; i < chartData.length; i++) {
      if (chartData[i]['date']) {
        chartData[i]['date'] = new Date(chartData[i]['date']);
      }
    }
    plot.data = chartData;
    let dateAxis = new am4charts.DateAxis();

    dateAxis.baseInterval = {
      'timeUnit': 'minute',
      'count': 1,
    };
    plot.xAxes.push(dateAxis);

    let lastTime = (chartData[chartData.length - 1]['date']) as Date;
    plot.events.on('ready', function() {
      lastTime.setHours(lastTime.getHours() - 1);
      const startDate = new Date(lastTime);
      lastTime.setHours(lastTime.getHours() + 1);
      const endDate = new Date(lastTime);

      dateAxis.zoomToDates(
        startDate,
        endDate,
      );
    });
    plot.dateFormatter.dateFormat = 'yyyy-MM-dd H:i';
    dateAxis.renderer.minGridDistance = 50;


    createAxisAndSeries(plot, 'rate', 'Rate', true, 'circle', true);
    createAxisAndSeries(plot, 'reqs', 'Requests', true, 'circle', true);
    createAxisAndSeries(plot, 'wins', 'Wins', true, 'circle', true);
    createAxisAndSeries(plot, 'spent', 'Spent', true, 'circle');
    createAxisAndSeries(plot, 'convs', 'Conversions', true, 'circle', true);
    createAxisAndSeries(plot, 'rateAvg', 'Rate avg', true, 'circle', true);

    plot.scrollbarX = new am4charts.XYChartScrollbar();
    plot.legend = new am4charts.Legend();
    plot.cursor = new am4charts.XYCursor();

    chart.current = plot;
    return () => {
      plot.dispose();
    };
  }, [data]);

  return (<div style={{ height: '400px' }} id={'chartdiv_' + props.campaignId}></div>);
}