//@ts-nocheck
import { apiKey } from '../../apiKey';
import axios from 'axios';

const PATH_URL = '/eva/task/{id}/user-subscription';

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export type TaskUserSubscriptionPostResponseOk = {
  'status': number,
  'message': TaskUserSubscriptionPostResponseMessageOk,
}

export type TaskUserSubscriptionPostResponseMessageOk = {
  data: {
    isWorks: boolean
  },
}

export type TaskUserSubscriptionPostResponseError = {
  'status': number,
  'message': TaskUserSubscriptionPostResponseMessageError,
}

export type TaskUserSubscriptionPostResponseMessageError = string

export default async function subscribe(id: number): Promise<TaskUserSubscriptionPostResponseOk | TaskUserSubscriptionPostResponseError> {

  return axios.post(getUrl(id), {}, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <TaskUserSubscriptionPostResponseOk | TaskUserSubscriptionPostResponseError>r.data;
  }).catch(e => {
    const resp: TaskUserSubscriptionPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: TaskUserSubscriptionPostResponseOk | TaskUserSubscriptionPostResponseError): boolean {
  return typeof response.message === 'object';
}
