import gifSource from './../assets/img/preloader.gif';
import React, { useContext, useEffect } from 'react';
import appContext from '../utils/context/appContext';

export default function Preloader() {
  const dataContext = useContext(appContext);

  return (<div className={`preloader ${dataContext.isLoading ? '  open' : ''}`}>
    <img src={gifSource} alt='loading' width={'180px'} />
  </div>)
}