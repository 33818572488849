import React, { ComponentProps, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { TaskParametersData, TasksData } from '../../../api/eva/tasks/task.get';
import TaskParameterIsSubscribedCheckbox from './TaskParameterIsSubscribedCheckbox';

export default function(props: ComponentProps<any> & { task: TasksData, toggle: () => {}, isOpen: boolean, className: string }) {

  useEffect(() => {
    if (!props.isOpen || props.task == null) {
      return;
    }

  }, [props.isOpen]);

  if (typeof props.task === 'undefined')
    return <Modal></Modal>;

  return (<Modal
    size={'lg'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Настройки параметров</ModalHeader>
    <ModalBody>
      <div className={'d-flex my-3'}>
        <div className={'col-sm-10 text-left'}>Наименование</div>
        <div className={'col-sm-2 text-right'}>Включен?</div>
      </div>

      {props.task.parameters.map((param: TaskParametersData) => (
        <TaskParameterIsSubscribedCheckbox taskParameter={param} task={props.task} />
      ))}


    </ModalBody>
  </Modal>);
}