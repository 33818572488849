import React, { useContext, useEffect, useState } from 'react';
import appContext from '../../../utils/context/appContext';
import { TaskParametersData, TasksData } from '../../../api/eva/tasks/task.get';
import { Checkbox } from '@mui/material';
import subscribeParameter, {
  TaskParameterUserSubscriptionPostResponseError, TaskParameterUserSubscriptionPostResponseMessageOk,
  TaskParameterUserSubscriptionPostResponseOk,
} from '../../../api/eva/tasks/parameter/task.parameter.user-subscription.post';
import unsubscribeParameter, {
  TaskParameterUserSubscriptionDeleteResponseError, TaskParameterUserSubscriptionDeleteResponseMessageOk,
  TaskParameterUserSubscriptionDeleteResponseOk,
} from '../../../api/eva/tasks/parameter/task.parameter.user-subscription.delete';


const TaskParameterIsSubscribedCheckbox = (props: { taskParameter: TaskParametersData, task: TasksData }) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(props.taskParameter.isSubscribedOnParam);
  const [disabled, setDisabled] = useState<boolean>(!props.task.isWorksOnUsersCampaigns);
  const MainContext = useContext(appContext);

  useEffect(() => {
    if (props.task == null) {
      return;
    }

    setIsSubscribed(props.taskParameter.isSubscribedOnParam);
  }, [props.task]);

  const updateIsWorks = (newIsSubscribed: boolean) => {
    setDisabled(true);

    if (newIsSubscribed)
      subscribeParameter(props.task.id, props.taskParameter.id).then(r => {
        updateSwitchState(r, newIsSubscribed);
      });
    else
      unsubscribeParameter(props.task.id, props.taskParameter.id).then(r => {
        updateSwitchState(r, newIsSubscribed);
      });

  };

  const isResponseOK = (r: TaskParameterUserSubscriptionPostResponseOk|TaskParameterUserSubscriptionPostResponseError|TaskParameterUserSubscriptionDeleteResponseOk|TaskParameterUserSubscriptionDeleteResponseError) => {
    return typeof r.message === 'object';
  }

  const updateSwitchState = (r: TaskParameterUserSubscriptionPostResponseOk|TaskParameterUserSubscriptionPostResponseError|TaskParameterUserSubscriptionDeleteResponseOk|TaskParameterUserSubscriptionDeleteResponseError, newState: boolean) => {
    setDisabled(false);
    if (!isResponseOK(r)) {
      setIsSubscribed(isSubscribed);
      MainContext.notify(r.message as string, 'error');
      return;
    }
    let message = r.message as TaskParameterUserSubscriptionPostResponseMessageOk|TaskParameterUserSubscriptionDeleteResponseMessageOk,
      newValue = message.data.isSubscribed === undefined ? newState : message.data.isSubscribed;
    props.taskParameter.isSubscribedOnParam = newValue;
    setIsSubscribed(newValue);
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    //React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (checked) {
      updateIsWorks(true);
    } else {
      updateIsWorks(false);
    }
  };

  return (
    <div className={'d-flex my-1 border-bottom align-items-center'}>
      <div className={'col-sm-10 text-left'}>{props.taskParameter.description}</div>
      <div className={'col-sm-2 text-right'}>
        <Checkbox
          disabled={disabled}
          checked={isSubscribed}
          onChange={onChange} />
      </div>
    </div>
  );
};
export default TaskParameterIsSubscribedCheckbox;
