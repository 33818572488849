//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';
import { MaterialStatus } from './campaign.materials.get';

const PATH_URL = '/campaigns/{id}/materials/status-update';

export type CampaignMaterialMassStatusUpdateResponseOk = {
  'status': number,
  'message': CampaignMaterialMassStatusUpdateResponseMessageOk,
}

export type CampaignMaterialMassStatusUpdateResponseMessageOk = {
  data: CampaignMaterialMassStatusUpdate,
}

export type CampaignMaterialMassStatusUpdateResponseError = {
  'status': number,
  'message': CampaignMaterialMassStatusUpdateResponseMessageError,
}
1
export type CampaignMaterialMassStatusUpdateResponseMessageError = string


export type CampaignMaterialMassStatusUpdate = {
  info: object
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string
{
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}
export default function materialMassStatusUpdate(campaignId: number, status: MaterialStatus): Promise<CampaignMaterialMassStatusUpdateResponseOk | CampaignMaterialMassStatusUpdateResponseError> {
  return axios.post(getUrl(campaignId), {
    status: status
  }, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignMaterialMassStatusUpdateResponseMessageOk | CampaignMaterialMassStatusUpdateResponseError>r.data;
  }).catch(e => {
    const resp: CampaignMaterialMassStatusUpdateResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignMaterialMassStatusUpdateResponseOk | CampaignMaterialMassStatusUpdateResponseError): boolean {
  return typeof response.message !== 'string';
}