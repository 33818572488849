//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/minute-stats';

export type CommonMinuteStatsHoursCount = 1 | 3 | 6 | 12 | 24;

export type CommonMinuteStatsRequest = {
  archived?: string,
  disabled?: string,
  onlyAutoCpa?: string,
  systems?: number[],
  operators?: number[],
  formats?: string[],
  countries?: string[],
  excludeCountries?: boolean,
  categories?: string[],
  users?: string[],
  networkSystemsShow?: string,
  groupId?: number
  hoursCount: CommonMinuteStatsHoursCount,
  campaigns?: number[] | string[],
  regions?: number[],
}

export type CommonMinuteStatsResponseOk = {
  'status': number,
  'message': CommonMinuteStatsResponseMessageOk,
}

export type CommonMinuteStatsResponseMessageOk = {
  data: {
    requests: [number, number][],
    wins: [number, number][],
    releases: TimestampsReleases
  },
}

export type TimestampsReleases = number[];

export type CommonMinuteStatsResponseError = {
  'status': number,
  'message': CommonMinuteStatsResponseMessageError,
}

export type CommonMinuteStatsResponseMessageError = string


// @ts-ignore
const key = new apiKey();


export default function get(request: CommonMinuteStatsRequest): Promise<CommonMinuteStatsResponseOk | CommonMinuteStatsResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CommonMinuteStatsResponseMessageOk | CommonMinuteStatsResponseError>r.data;
  }).catch(e => {
    const resp: CommonMinuteStatsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CommonMinuteStatsResponseOk | CommonMinuteStatsResponseError): boolean {
  return typeof response.message !== 'string';
}