import React, { ComponentProps, Dispatch, SetStateAction } from 'react';
import DatePicker from 'react-date-picker';
import { formatDate } from '../../utils/date';
import CreatableSelect from 'react-select/creatable';
import { AutoCpaLogsRequest } from '../../api/campaigns/campaigns.autocpa-logs.get';

export default (props: ComponentProps<any> & { filter: AutoCpaLogsRequest, setFilter: Dispatch<SetStateAction<any>>, campaignId: string, setCampaignId: (val: string) => {}  }) => {
  const onChangeCampaignSelect = (options: any) => {
    if (options === null) {
      props.setCampaignId('');
      return
    }
    props.setCampaignId(options.value);
  };

  const onChangeDatePicker = (date: Date | null, filterParam: string) => {
    if (date === null) {
      const filter = { ...props.filter };
      delete (filter[filterParam]);
      props.setFilter(filter);
      return;
    }
    props.setFilter({ ...props.filter, [filterParam]: formatDate(date) });

  };

  return (
    <div>
      <div className={'pb-2 pt-2'}>
        <div className='row'>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>Кампания</div>
            <CreatableSelect
              components={{ DropdownIndicator: null }}
              isClearable
              options={[]}
              onChange={onChangeCampaignSelect}
            />
          </div>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>От</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'startDate')}
                        value={(new Date(props.filter.startDate))} />
          </div>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>До</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'endDate')}
                        value={(new Date(props.filter.endDate))} />
          </div>
        </div>
      </div>
    </div>
  );
};