//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';
import { DateTime } from 'luxon';
import qs from 'qs';
import { getDateNDaysAgo } from '../../utils/date';

const PATH_URL = '/campaigns/{id}/smartbid/history';

export type CampaignSmartbidHistoryResponseOk = {
  'status': number,
  'message': CampaignSmartbidHistoryResponseMessageOk,
}

export type CampaignSmartbidHistoryResponseMessageOk = {
  total: number,
  pages: number,
  data: CampaignSmartbidHistory,
}

export type CampaignSmartbidHistoryResponseError = {
  'status': number,
  'message': CampaignSmartbidHistoryResponseMessageError,
}
1;
export type CampaignSmartbidHistoryResponseMessageError = string


export type CampaignSmartbidHistory = {
  data: CampaignSmartbidHistoryDataEl[],
}

export function recognizeCampaignSmartbidHistoryRequest(): CampaignSmartbidHistoryRequest {
  const dateStr = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  const dateEndStr = DateTime.fromJSDate(getDateNDaysAgo(-1)).toFormat('yyyy-MM-dd');
  const r = { startDate: dateStr, endDate: dateEndStr };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return r;
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    if (key === 'startDate' || key === 'endDate') {
      r[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
      if (r[key] === 'Invalid DateTime') {
        r[key] = DateTime.fromFormat(params[key], 'yyyy-M-dd').toFormat('yyyy-MM-dd');
      }
      if (r[key] === 'Invalid DateTime') {
        r[key] = dateStr;
      }
      continue;
    }
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type CampaignSmartbidHistoryRequest = {
  page?: number
  results?: number
  startDate?: string
  endDate?: string,
}

export type CampaignSmartbidHistoryDataEl = {
  id: number,
  campaignId: number,
  domainId: number,
  tagid: string,
  systemId: number,
  app: boolean,
  date: string,
  strategy: number,
  params: string,
};

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function materialMassStatusUpdate(campaignId: number, request: CampaignSmartbidHistoryRequest): Promise<CampaignSmartbidHistoryResponseOk | CampaignSmartbidHistoryResponseError> {
  return axios.get(getUrl(campaignId), {
    params: request,
    headers: {
      'apiKey': key.get(),
    },
  }).then(r => {
    return <CampaignSmartbidHistoryResponseMessageOk | CampaignSmartbidHistoryResponseError>r.data;
  }).catch(e => {
    const resp: CampaignSmartbidHistoryResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignSmartbidHistoryResponseOk | CampaignSmartbidHistoryResponseError): boolean {
  return typeof response.message !== 'string';
}