const apiKeyValue = 'apikey';
export function apiKey(this: any) {
  const searchKeyInCookies = () => {
    const cookies = document.cookie.split(';');
    for (let k = 0; k < cookies.length; k++) {
      const statements = cookies[k].trim().split('=');
      if (statements[0] === apiKeyValue) {
        return statements[1];
      }
    }
    return null;
  };
  const searchKeyInUri = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get(apiKeyValue);
  };
  this.get = () => {
    let key = searchKeyInCookies();
    if (key !== null) return key;

    key = searchKeyInUri();
    return key;
  }

  this.set = (apiKey: string) => {
    document.cookie = apiKeyValue+'='+apiKey;
  }

  this.setFromUri = () => {
    const key = searchKeyInUri();
    if (key === null) {
      return;
    }
    this.set(key);
  };
}
