//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/autocpa/settings';

export type CampaignAutoCpaSettingsResponseOk = {
  'status': number,
  'message': CampaignAutoCpaSettingsResponseMessageOk,
}

export type CampaignAutoCpaSettingsResponseMessageOk = {
  data: CampaignAutoCpaSettings,
}

export type CampaignAutoCpaSettingsResponseError = {
  'status': number,
  'message': CampaignAutoCpaSettingsResponseMessageError,
}

export type CampaignAutoCpaSettingsResponseMessageError = string


export type CampaignAutoCpaSettings = null | {
  cpa: number,
  packsToAdd: number,
  maxSpentCpaWithoutLeads: number,
  smartbidEnable: boolean
  retestEnable: boolean
  reservationEnable: boolean
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id: number): Promise<CampaignAutoCpaSettingsResponseOk | CampaignAutoCpaSettingsResponseError> {
  return axios.get(getUrl(id), {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignAutoCpaSettingsResponseMessageOk | CampaignAutoCpaSettingsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignAutoCpaSettingsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignAutoCpaSettingsResponseOk | CampaignAutoCpaSettingsResponseError): boolean {
  return typeof response.message !== 'string';
}