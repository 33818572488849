import React, { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import Filter from '../../components/Dashboard/Filter';
import get, {
  CampaignRequest,
  CampaignResponseError,
  CampaignResponseOk,
  isResponseOK,
  recognizeCampaignRequest,
  Selects,
} from '../../api/campaigns/campaigns.get';
import appContext from '../../utils/context/appContext';
import { useSearchParams } from 'react-router-dom';
import CommonMinuteStats from '../../components/Dashboard/CommonMinuteStats';
import DashboardContext from '../../utils/context/dashboardContext';
import MaterialDataTable from '../../components/Dashboard/MaterialDataTable';
import UserSetTelegramChatModal from '../../components/Dashboard/UserSetTelegramChatModal';
import FilterModal from '../../components/Dashboard/FilterModal';
import UserColumnSettingsModal, { keyPeriodicallyUpdatePage } from '../../components/Dashboard/UserColumnSettingsModal';
import FilterPreview from '../../components/Dashboard/FilterPreview';


const elMinuteGraphId = 'minute-graph';

async function scrollToGraph() {
  const el = document.getElementById(elMinuteGraphId);
  if (!el) {
    return;
  }

  setTimeout(function() {
    window.scroll({
      top: el.offsetTop - 5,
      behavior: 'smooth',
    });
  }, 0);
}

const DashboardPage = function() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [response, setResponse] = useState<CampaignResponseOk>();
  const [needUpdatePage, setNeedUpdatePage] = useState<boolean>(localStorage.getItem(keyPeriodicallyUpdatePage) !== null);
  const [filterDataBy, setFilterDataBy] = useState<string>('');
  const [shownModalTelegramChatId, setShownModalTelegramChatId] = useState<boolean>(false);

  const [filter, setFilter] = useState<CampaignRequest>(recognizeCampaignRequest);
  const [selects, setSelects] = useState<Selects>({
    systems: [],
    operators: [],
    formats: [],
    countries: [],
    categories: [],
    users: [],
    groups: [],
    offers: []
  });
  const dataContext = useContext(appContext);

  function fillSelectsFromAPI(response: CampaignResponseOk): void {
    if (!response.message.filters) {
      return;
    }
    setSelects(response.message.filters);
  }

  const updateFilter = (filter: CampaignRequest, forceScroll: boolean = true) => {
    setFilter(filter);
  };

  const requestData = (forceScroll: boolean = true) => {
    dataContext.setIsLoading(true);
    get(filter).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignResponseOk;
      setResponse(r);
      fillSelectsFromAPI(r);
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
      if (forceScroll) {
        setTimeout(scrollToGraph, 1);
      }
    });
  };

  const requestFulfilmentTelegramChatId = () => {
    setShownModalTelegramChatId(true);
  };


  function onInputFilterBy(event: any) {
    setFilterDataBy(event.target.value);
  }


  useEffect(() => {
    setSearchParams(qs.stringify(filter, { arrayFormat: 'brackets' }));
    requestData();
  }, [filter]);
  useEffect(() => {
    function getResp(filter:any) {
      get(filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as CampaignResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as CampaignResponseOk;
        setResponse(r);
        fillSelectsFromAPI(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      })
    }
    const interval = setInterval((filter, needUpdatePage) => {
      if (!needUpdatePage) {
        return;
      }
      getResp(filter)
    }, 60000, filter, needUpdatePage)
    return () => {
      clearInterval(interval);
    }
  }, [filter, needUpdatePage]);
  useEffect(() => {
    if(!needUpdatePage) {
      localStorage.removeItem(keyPeriodicallyUpdatePage);
      return;
    }
    localStorage.setItem(keyPeriodicallyUpdatePage, "1");
    return;
  }, [needUpdatePage]);
  return (<Page
    className='DashboardPage'
    title='Список кампаний'
  >
    <DashboardContext.Provider
      value={{ requestData, response: response ?? null, filter, requestFulfilmentTelegramChatId }}>
      {/*<Filter filter={filter} setFilter={updateFilter} selects={selects} />*/}
      <RandomFilter filter={filter} setFilter={updateFilter} selects={selects} needUpdatePage={needUpdatePage} setNeedUpdatePage={setNeedUpdatePage} />
      <CommonMinuteStats id={elMinuteGraphId} filter={filter} campaignsId={filter.campaigns} regions={filter.regions} />
      <div className={'row mt-4 mb-1'}>
        <div className='col-12 col-sm-12 col-md-4 col-lg-3 mb-0'>
          <input onInput={onInputFilterBy} type='text' className={'form-control'} placeholder={'Поиск'} />
        </div>
      </div>
      {!dataContext.isLoading &&
        (response && <MaterialDataTable
          setFilter={updateFilter}
          filter={filter}
          filterBy={filterDataBy}
          response={response}
        />)}
      <UserSetTelegramChatModal isOpen={shownModalTelegramChatId}
                                toggle={() => setShownModalTelegramChatId(prevState => !prevState)} />
    </DashboardContext.Provider>
  </Page>);
};

function RandomFilter(props: any) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <React.Fragment>
      <UserColumnSettingsModal needUpdatePage={props.needUpdatePage} setNeedUpdatePage={props.setNeedUpdatePage} isOpen={isOpenModal} toggle={()=> setIsOpenModal(prevState => !prevState)} headers={props.headers} />
      <h3>
        Фильтр:

        <button type={'button'}
                              onClick={() => {
                                document.getElementById('campaign-filter')?.classList.toggle('campaign-filter--expanded');
                                document.getElementById('campaign-filter-preview')?.classList.toggle('campaign-filter-preview--expanded');
                              }
                              }
                              className={'ml-2 btn btn-sm btn-primary'}>
          фильтр
        </button>
        <button type={'button'} onClick={() => setIsOpenModal(true)} className={'ml-1 btn btn-sm btn-primary'}>
          настройки
        </button></h3>
      <Filter {...props} />
      <FilterPreview {...props} />
    </React.Fragment>
  );
}
export default DashboardPage;
