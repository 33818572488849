import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaign-group/{id}';

export type CampaignGroupPatchRequest = {
  campaigns?: CampaignGroupPatchCampaigns,
}

export type CampaignGroupPatchCampaigns = {
  campaignId: number,
  action: "add" | "delete"
}[]

export type CampaignGroupPatchResponseOk = {
  'status': number,
  'message': CampaignGroupPatchResponseMessageOk,
}

export type CampaignGroupPatchResponseMessageOk = {
  data: {
    info: string
  },
}

export type CampaignGroupPatchResponseError = {
  'status': number,
  'message': CampaignGroupPatchResponseMessageError,
}

export type CampaignGroupPatchResponseMessageError = string


// @ts-ignore
const key = new apiKey();


export default function patch(id: number, request: CampaignGroupPatchRequest): Promise<CampaignGroupPatchResponseOk | CampaignGroupPatchResponseError> {
  return axios.patch(getUrl(id), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignGroupPatchResponseOk | CampaignGroupPatchResponseError>r.data;
  }).catch(e => {
    const resp: CampaignGroupPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id.toString());
}

export function isResponseOK(response: CampaignGroupPatchResponseOk | CampaignGroupPatchResponseError): boolean {
  return typeof response.message !== 'string';
}