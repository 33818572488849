//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/potential-stats';

export type CampaignPotentialStatsResponseOk = {
  'status': number,
  'message': CampaignPotentialStatsResponseMessageOk,
}

export type CampaignPotentialStatsResponseMessageOk = {
  data: CampaignPotentialStats,
}

export type CampaignPotentialStatsResponseError = {
  'status': number,
  'message': CampaignPotentialStatsResponseMessageError,
}

export type CampaignPotentialStatsResponseMessageError = string


export type CampaignPotentialStats = {
  cpa: number,
  profit: number,
  spent: number,
  leads: number,
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id: number, dateStart: string, dateEnd: string): Promise<CampaignPotentialStatsResponseOk | CampaignPotentialStatsResponseError> {
  return axios.get(getUrl(id), {
    params: { dateStart, dateEnd },
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignPotentialStatsResponseMessageOk | CampaignPotentialStatsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignPotentialStatsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignPotentialStatsResponseOk | CampaignPotentialStatsResponseError): boolean {
  return typeof response.message !== 'string';
}