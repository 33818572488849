import React, { ComponentProps, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import pageContext from '../../utils/context/realtimeBlacklistContext';
import { MdClose } from 'react-icons/md';
import {
  CampaignRealtimeBlacklistRequest,
  createCampaignRealtimeBlacklistRequest,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import { formatDate } from '../../utils/date';

export default function(props: ComponentProps<any> & { campaignId: number, toggle: () => {}, isOpen: boolean, filter: CampaignRealtimeBlacklistRequest, setFilter: Dispatch<SetStateAction<any>>, submitFilter: boolean, setSubmitFilter: () => {}  }) {
  const context = useContext(pageContext);
  const dataContext = useContext(appContext);
  const [minSpend, setMinSpend] = useState('0.9');
  const [minCpa, setMinCpa] = useState('0');

  function request() {
    const request = createCampaignRealtimeBlacklistRequest();
    request.startDate = "2020-01-01";
    request.endDate = formatDate(new Date());
    request.minSpend = Number(minSpend);
    request.minCpa = Number(minCpa);
    props.setFilter(request);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Плохой cpa</ModalHeader>
    <ModalBody>
      <form className='row col-12'>
        <label className={'w-100'}>
          Траты больше или равны
          <input type='text' className={'form-control'} onChange={e => setMinSpend(e.currentTarget.value)} value={minSpend} />
        </label>
        <label className={'w-100 mt-2'}>
          CPA больше или равен
          <input type='text' className={'form-control'} onChange={e => setMinCpa(e.currentTarget.value)} value={minCpa} />
        </label>
      </form>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        request();
        props.toggle();
        props.setSubmitFilter(true);
      }}>
        Искать
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}