//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate, getDateNDaysAgo } from '../../utils/date';
import { SelectData } from './campaigns.get';

const PATH_URL = '/campaigns/{id}/bundles/analytics';

export type CampaignBundlesAnalyticsRequest = {
  prevDate: string,
  date: string,
  diff?: string,
  minDiff?: string,
}


export type CampaignBundlesAnalyticsResponseOk = {
  'status': number,
  'message': CampaignBundlesAnalyticsResponseMessageOk,
}

export type CampaignBundlesAnalyticsResponseMessageOk = {
  data: {
    data: CampaignBundlesAnalyticsStat[],
    total: {
      prevDate: number
      date: number
      diffPercent: number
      diff: number
    },
  },
}

export type CampaignBundlesAnalyticsStat = {
  tagid: string
  domainId: string
  reasons: string[]
  profit: {
    prevDate: number
    date: number
    diffPercent: number
    diff: number
  }
}

export type CampaignBundlesAnalyticsResponseError = {
  'status': number,
  'message': CampaignBundlesAnalyticsResponseMessageError,
}

export type CampaignBundlesAnalyticsResponseMessageError = string

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string
{
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(campaignId: number, request: CampaignBundlesAnalyticsRequest = null): Promise<CampaignBundlesAnalyticsResponseOk | CampaignBundlesAnalyticsResponseError> {
  return axios.get(getUrl(campaignId), {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignBundlesAnalyticsResponseOk | CampaignBundlesAnalyticsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignBundlesAnalyticsResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignBundlesAnalyticsResponseOk | CampaignBundlesAnalyticsResponseError): boolean {
  return typeof response.message === 'object';
}