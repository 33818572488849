//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/offers/{id}';

export type OffersDeleteResponseOk = {
  'status': number,
  'message': OffersDeleteResponseMessageOk,
}

export type OffersDeleteResponseMessageOk = {
  data: {
    info: string
  },
}

export type OffersDeleteResponseError = {
  'status': number,
  'message': OffersDeleteResponseMessageError,
}

export type OffersDeleteResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function deleteOffer(id: number): Promise<OffersDeleteResponseOk | OffersDeleteResponseError> {
  return axios.delete(getUrl(id), {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <OffersDeleteResponseOk | OffersDeleteResponseError>r.data;
  }).catch(e => {
    const resp: OffersDeleteResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id.toString());
}

export function isResponseOK(response: OffersDeleteResponseOk | OffersDeleteResponseError): boolean {
  return typeof response.message !== 'string';
}