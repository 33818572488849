import { Content, Footer } from '../Layout';
import React, { useEffect, useState } from 'react';
import { MdImportantDevices } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { Outlet } from 'react-router';
import { NOTIFICATION_SYSTEM_STYLE } from './../../utils/constants';
import Preloader from './../Preloader';
import AppContext from '../../utils/context/appContext';
import { apiKey } from '../../api/apiKey';
import NavigationTop from './NavigationTop';
import { useNavigate } from 'react-router-dom';
import logo200Image from '../../assets/img/logo/logo_200.jpg';

export const NotificationLevels = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};


export default function MainLayout(props) {
  const [isLoading, setIsLoading] = useState(false);
  const notificationSystem = React.useRef();
  const [isAuthCheck, setIsAuthCheck] = useState(false);
  const navigate = useNavigate();
  const isSidebarOpen = () => {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  };

  useEffect(() => {
    const key = (new apiKey());
    if (key.get() === '' || key.get() === null) {
      navigate('/login');
    } else {
      setIsAuthCheck(true)
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!notificationSystem) {
        return;
      }

      if ((new apiKey()).get() === null) {
        notificationSystem.current.addNotification({
          title: <MdImportantDevices />,
          message: 'Вы не авторизованы. Авторизуйтесь',
          level: 'error',
        });
        navigate('/login')
      }
    }, 1500);
  }, []);

  const notify = (message, level = NotificationLevels.info) => {
    if (!notificationSystem) {
      alert(message);
      return;
    }

    notificationSystem.current.addNotification({
      title: <MdImportantDevices />,
      message: message,
      level: NotificationLevels.info,
    });
  };

  return (
    isAuthCheck ? <React.Fragment>
    <AppContext.Provider value={{ isLoading, setIsLoading, notify }}>
      <main className='cr-app bg-light' style={{ flexDirection: 'column' }}>
          <Preloader />
          <NavigationTop />
          <Content fluid>
            <Outlet />
            <Footer />
          </Content>

          <NotificationSystem
            dismissible={false}
            ref={notificationSystem}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
      </main>
      <footer className={'bg-light text-left p-3'} style={{borderTop: "4px solid rgb(222, 226, 230)"}}>
        <h6><img src={logo200Image} width={25} alt='logo' /> © {new Date().getFullYear()} Clickium LTD</h6>
      </footer>
    </AppContext.Provider>
    </React.Fragment> : null
  );
}