import React, { ChangeEvent, ComponentProps, useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import { CampaignStatus as CampaignStatusType, SelectData } from '../../api/campaigns/campaigns.get';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import post, {
  CampaignGroupPostResponseError,
  CampaignGroupPostResponseOk,
  isResponseOK,
} from '../../api/campaigns/campaign-group.post';
import patch, {
  CampaignGroupPatchCampaigns,
  CampaignGroupPatchRequest,
  CampaignGroupPatchResponseError,
  isResponseOK as isPatchResponseOk,
} from '../../api/campaigns/campaign-group.patch';

export default function(props: ComponentProps<any> & { toggle: () => {}, campaigns: number[], callbackAfterSuccess: () => {}, isOpen: boolean, groups: SelectData[] }) {
  const dataContext = useContext(appContext);
  const [groupIdValue, setGroupIdValue] = React.useState<SelectData | null>(null);
  const [newGroupName, setNewGroupName] = React.useState<string>('');
  const [typeGroupProcessing, setTypeGroupProcessing] = React.useState<'create' | 'choose'>('choose');

  const updateTypeProcessing = (val: string) => {
    if (val !== 'create' && val !== 'choose') {
      return;
    }
    setTypeGroupProcessing(val);
  };

  async function createGroup() {
    if (newGroupName.length === 0) {
      dataContext.notify('Введите название группы', 'error');
      return null;
    }

    let r = await post(newGroupName);
    if (!isResponseOK(r)) {
      r = r as CampaignGroupPostResponseError;
      dataContext.notify(r.message, 'error');
      return null;
    }
    r = r as CampaignGroupPostResponseOk;
    dataContext.notify('Новая группа кампаний создана: ' + r.message.data.group.name, 'success');
    return r.message.data.group.id;
  }

  function bindGroupWithCampaigns(groupId: number) {
    let campaigns: CampaignGroupPatchCampaigns = [];
    props.campaigns.map((id: number) => {
      campaigns.push({ campaignId: id, action: 'add' });
    });
    patch(groupId, { campaigns }).then(r => {
      if (!isPatchResponseOk(r)) {
        r = r as CampaignGroupPatchResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      dataContext.notify('Выбранные кампании успешно добавлены в группу', 'success');
      props.toggle();
    });
    if (props.callbackAfterSuccess) {
      props.callbackAfterSuccess();
    }
  }

  async function processData() {
    let groupId: number | null = Number(groupIdValue?.value);
    if (typeGroupProcessing === 'create') {
      groupId = await createGroup();
    }
    if (groupId === null) {
      return;
    }

    bindGroupWithCampaigns(groupId);
  }

  function onChangeGroup(values: OnChangeValue<any, any>, meta: ActionMeta<any>) {
    setGroupIdValue(values);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}>
    <ModalBody>
      <h3 className='h5 text-center'>
        <b>Группировка кампаний</b>
      </h3>
      <div className={'text-left'}>
        <div>Выбрано кампаний: {props.campaigns.length}</div>
        <FormControl className={'mt-3'}>
          <FormLabel id='controlled-radio-buttons-group'>Действие</FormLabel>
          <RadioGroup
            aria-labelledby='controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={typeGroupProcessing}
            onChange={(e) => updateTypeProcessing(e.currentTarget.value)}
          >
            <FormControlLabel value='choose' control={<Radio />} label='Выбрать существующую' />
            <FormControlLabel value='create' control={<Radio />} label='Создать новую' />
          </RadioGroup>
        </FormControl>
        {typeGroupProcessing === 'choose' &&
          <Select
            name={'groupId'}
            placeholder={'группы'}
            value={groupIdValue}
            onChange={onChangeGroup}
            getOptionLabel={(option: SelectData) => option.name}
            getOptionValue={(option: SelectData) => option.value}
            options={props.groups} />}
        {typeGroupProcessing === 'create' &&
          <div>
            <input placeholder={'название группы'} className={'form-control'} type='text' value={newGroupName}
                   onChange={(e) => setNewGroupName(e.currentTarget.value)} />
          </div>}
      </div>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        processData();
      }}>
        Сохранить
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}