import React, { useMemo } from 'react';
import { CampaignsAlertsData } from '../../../api/campaigns-alerts/campaigns-alert.get';
import { type MRT_ColumnDef } from 'material-react-table';
import { DateTime } from 'luxon';

export default () => {
  const columns = useMemo<MRT_ColumnDef<CampaignsAlertsData>[]>(
    () => [
      {
        accessorKey: 'cid',
        header: 'Id кампании',
        enableResizing: true,
      },
      {
        accessorKey: 'campaignName',
        header: 'Наименование кампании',
        enableResizing: true,
      },
      {
        accessorFn: (row) => row.time,
        accessorKey: 'time',
        header: 'Дата',
        enableResizing: true,
        sortingFn: 'datetime',
        Cell: ({
                 cell,
               }) => DateTime.fromISO(cell.getValue<string>(), { setZone: true }).toFormat('yyyy-MM-dd HH:00'),
      },
      {
        accessorKey: 'message',
        header: 'Алерт',
        enableResizing: true,
      },
    ],
    [],
  );

  return columns;
};