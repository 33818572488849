import React, { ComponentProps, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AiRed from '../../assets/img/ai_red.png';
import AiWL from '../../assets/img/white_domain_id.png';
import { MdClose } from 'react-icons/md';
import { CampaignAnalyticsAbsoluteIndicatorTags } from '../../api/campaigns/campaigns.analytics-absolute-indicator.get';

export default function(props: ComponentProps<any> & { tags: CampaignAnalyticsAbsoluteIndicatorTags, toggle: () => {}, isOpen: boolean}) {

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Источники</ModalHeader>
    <ModalBody>
      {props.tags.map((el:any) =><p>
        {el.inWL && <img className={'mr-2'} src={AiWL} alt='' width={20}/>}
        {el.inBL && <img className={'mr-2'} src={AiRed} alt='' width={20} />}
        {el.tagid}
      </p>)}
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Закрыть
      </button>
    </ModalFooter>
  </Modal>;
}