//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/materials';

export type CampaignMaterialsResponseOk = {
  'status': number,
  'message': CampaignMaterialsResponseMessageOk,
}

export type CampaignMaterialsMessageOk = {
  data: CampaignMaterial[],
}

export type CampaignMaterialsResponseError = {
  'status': number,
  'message': CampaignMaterialsResponseMessageError,
}

export type CampaignMaterialsResponseMessageError = string

export enum MaterialStatus {
  ON = 'on',
  OFF = 'off',
  ARCHIVED = 'archived',
}

export enum MaterialFormat {
  BANNER = 1,
  POPUNDER = 2,
  NATIVE = 3,
  VIDEO = 4,
  CONTEXT = 5,
  PUSH_NATIVE = 6,
  IOS_PUSH = 8,
}

export type CreativeImage = {
  img: string
}
export type CreativeVideo = {
  videoName: string | null,
  banner: string | null
}

export type CreativeNative = {
  [name: 'data' | 'img']: { [name: string]: { value: string, desc: string } },
  [name: 'title']: { [name: 'value']: string },
}

export type CampaignMaterial = {
  id: number
  parentId: number
  name: number
  profit: number
  format: CampaignFormat
  status: MaterialStatus
  moderationStatus: number[]
  stats: {
    spend: number,
    wins: number,
    impsAll: number,
    hitreal: number,
    clicks: number,
    convs: number,
    trafficVtr: number,
    trafficCtr: number,
    vtrPercent: number,
    ctrPercent: number,
    cpc: number,
    cpm: number,
    cpa: number,
  }
  envisionStatus?: number
  creative: CreativeImage | CreativeVideo | CreativeNative | null
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id: number, startDate: string = null, endDate: string = null): Promise<CampaignMaterialsResponseOk | CampaignMaterialsResponseError> {
  return axios.get(getUrl(id), {
    params: {
      startDate,
      endDate,
    },
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignMaterialsResponseMessageOk | CampaignMaterialsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignMaterialsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignMaterialsResponseOk | CampaignMaterialsResponseError): boolean {
  return typeof response.message !== 'string';
}