import React, { ComponentProps } from 'react';
import DatePicker from 'react-date-picker';
import { DateTime } from 'luxon';
import { formatServerDateYmd } from '../../utils/date';
import CreatableSelect from 'react-select/creatable';
import { CampaignSmartbidHistoryRequest } from '../../api/campaigns/campaign.smartbid.history.get';

export default (props: ComponentProps<any> & { filter: CampaignSmartbidHistoryRequest, setCampaign: (val: string) => {} }) => {
  const formatDateValue = (dateValue: string): Date => {
    return DateTime.fromFormat(dateValue, 'yyyy-MM-dd').toJSDate();
  };

  const onChangeCampaignSelect = (options: any) => {
    if (options === null) {
      props.setCampaignId('');
      return;
    }
    props.setCampaignId(options.value);
  };

  const onChangeDatePicker = (date: Date | null, filterParam: string) => {
    if (date === null) {
      const filter = { ...props.filter };
      delete (filter[filterParam]);
      props.setFilter(filter);
      return;
    }
    props.setFilter({ ...props.filter, [filterParam]: formatServerDateYmd(date) });
  };

  return (<div>
      <div className={'row'}>
        <div className={'col-sm-6 col-12 col-md-3'}>
          <div>Кампания</div>
          <CreatableSelect
            components={{ DropdownIndicator: null }}
            isClearable
            options={[]}
            onChange={onChangeCampaignSelect}
          />
        </div>
        <div className={'col-sm-6 col-md-4'}>
          <div>От</div>
          <DatePicker
            clearIcon={null}
            className={'w-100 form-control date-picker-foreground'}
            format={'yyy-MM-dd'}
            onChange={(date: Date) => onChangeDatePicker(date, 'startDate')}
            value={formatDateValue(props.filter.startDate)}
          />
        </div>
        <div className={'col-sm-6 col-md-4'}>
          <div>До</div>
          <DatePicker
            clearIcon={null}
            className={'w-100 form-control date-picker-foreground'} format={'yyy-MM-dd'}
            onChange={(date: Date) => onChangeDatePicker(date, 'endDate')}
            value={formatDateValue(props.filter.endDate)}
          />
        </div>
      </div>
    </div>
  );
};