//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';
import { CampaignRequest, createCampaignRequest } from './campaigns.get';
import { DateTime } from 'luxon';

const PATH_URL = '/campaigns/{id}/autocpa/logs';

export type AutoCpaLogsRequest = {
  startDate?: string
  endDate?: string,
}

export function createRequest(): AutoCpaLogsRequest {
  const date = new Date();
  return { startDate: formatDate(date), endDate: formatDate(date) };
}

export function recognizeRequest(): AutoCpaLogsRequest {
  const dateStr = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  const r = { startDate: dateStr, endDate: dateStr };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    if(key === 'startDate' || key === 'endDate') {
      r[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
      if (r[key] === 'Invalid DateTime') {
        r[key] = DateTime.fromFormat(params[key], 'yyyy-M-dd').toFormat('yyyy-MM-dd');
      }
      if (r[key] === 'Invalid DateTime') {
        r[key] = dateStr;
      }
      continue;
    }
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type AutoCpaLogsResponseOk = {
  'status': number,
  'message': AutoCpaLogsResponseMessageOk,
}

export type AutoCpaLogsResponseMessageOk = {
  data: {
    campaign: {
      id: number
      name: string
      availableBundles: number
    },
    logs: AutoCpaLogs[],
  }
}

export type TotalCalculated = {
  wins: number,
  spend: number,
  views: number,
  totalRequests: number,
  profit: number,
  impsReal: number,
  imps: number,
  impressions: number,
  conversions: number,
  clicks: number,
}

export type AutoCpaLogsResponseError = {
  'status': number,
  'message': AutoCpaLogsResponseMessageError,
}

export type AutoCpaLogsResponseMessageError = string

export type AutoCpaLogs = {
  time: string,
  keyChange: string,
  oldValue: string,
  newValue: string,
  description: string,
  user: string | null,
}

// @ts-ignore
const key = new apiKey();

export default function get(id: number, request: AutoCpaLogsRequest): Promise<AutoCpaLogsResponseOk | AutoCpaLogsResponseError> {
  const url = PATH_URL.replace('{id}', id);
  return axios.get(process.env.REACT_APP_API_URL + url, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AutoCpaLogsResponseOk | AutoCpaLogsResponseError>r.data;
  }).catch(e => {
    const resp: AutoCpaLogsResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: AutoCpaLogsResponseOk | AutoCpaLogsResponseError): boolean {
  return typeof response.message === 'object';
}