//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/offers/{id}';

export type OffersPatchResponseOk = {
  'status': number,
  'message': OffersPatchResponseMessageOk,
}

export type OffersPatchRequest = {
  id: number,
  name: string,
  domain: any,
  url: UrlData[],
  conv: number,
  country: string,
  category: number,
  gmt: number,
  limit: number,
  checkDayLimit: number
}

export type UrlData = {
  id: number,
  url: string,
  offer_id: number
}

export type OffersPatchResponseMessageOk = {
  data: {
    info: string
  },
}

export type OffersPatchResponseError = {
  'status': number,
  'message': OffersPatchResponseMessageError,
}

export type OffersPatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function patch(id: number, request: OffersPatchRequest): Promise<OffersPatchResponseOk | OffersPatchResponseError> {
  return axios.patch(getUrl(id), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <OffersPatchResponseOk | OffersPatchResponseError>r.data;
  }).catch(e => {
    const resp: OffersPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id.toString());
}

export function isResponseOK(response: OffersPatchResponseOk | OffersPatchResponseError): boolean {
  return typeof response.message !== 'string';
}