//@ts-nocheck
import { apiKey } from '../../apiKey';
import axios from 'axios';

const PATH_URL = '/eva/alert/{id}/dislike';

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export type AlertDislikePatchResponseOk = {
  'status': number,
  'message': AlertDislikePatchResponseMessageOk,
}

export type AlertDislikePatchResponseMessageOk = {
  data: {
    reaction: string,
  },
}

export type AlertDislikePatchResponseError = {
  'status': number,
  'message': AlertDislikePatchResponseMessageError,
}

export type AlertDislikePatchResponseMessageError = string

export default async function patch(id: number): Promise<AlertDislikePatchResponseOk | AlertDislikePatchResponseError> {
  try {
    const r = await axios.patch(getUrl(id), {},{
      headers: {
        apiKey: key.get(),
      },
    });
    return <AlertDislikePatchResponseMessageOk | AlertDislikePatchResponseError>r.data;
  } catch (e) {
    const resp: AlertDislikePatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  }
}

export function isResponseOK(response: AlertDislikePatchResponseOk | AlertDislikePatchResponseError): boolean {
  return typeof response.message !== 'string';
}