import React, { useContext, useState } from 'react';
import Select from 'react-select';
import { Campaign, CampaignLists } from '../../api/campaigns/campaigns.get';
import patch, {
  CampaignPatchResponseError,
  CampaignPatchResponseOk,
  isResponseOK,
} from './../../api/campaigns/campaigns.patch';
import { CampaignMinuteStatsResponseError } from '../../api/campaigns/campaign.minute-stats.get';
import appContext from '../../utils/context/appContext';
import { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';

const options = [
  { value: 'ignore', label: 'Все' },
  { value: 'white', label: 'Вайтлист' },
  { value: 'black', label: 'Блэклист' },
];

function getOption(lists: string) {
  for (let key in options) {
    if (options[key].value === lists) {
      return options[key];
    }
  }
}

const SelectTargetingDomain = (props: { campaign: Campaign, }) => {
  const [restrictionType, setRestrictionType] = useState<CampaignLists>(props.campaign.lists as unknown as CampaignLists);
  const [disabled, setDisabled] = useState<boolean>(false);
  const MainContext = useContext(appContext);

  const updateRestrictionType = (restrictionType: CampaignLists) => {
    setDisabled(true);
    patch(props.campaign.id, { restrictionType }).then((r: CampaignPatchResponseOk | CampaignPatchResponseError) => {
      setDisabled(false);
      if (!isResponseOK(r)) {
        r = r as CampaignMinuteStatsResponseError;
        MainContext.notify(r.message, 'error');
        return;
      }
      setRestrictionType(restrictionType);
    });
  };

  function onChange(newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) {
    updateRestrictionType(newValue.value);
  }

  return (<div onClick={(e) => e.stopPropagation()}>
    <Select
      className={'w-100'}
      onChange={onChange}
      options={options}
      defaultValue={getOption(props.campaign.lists)}
      isDisabled={disabled} // нет функционала для изменения таргетирования
    />
  </div>);
};
export default SelectTargetingDomain;
