import React, { ComponentProps, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import pageContext from '../../utils/context/realtimeBlacklistContext';
import { MdClose } from 'react-icons/md';
import {
  CampaignRealtimeBlacklistRequest,
  createCampaignRealtimeBlacklistRequest,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import { formatDate } from '../../utils/date';

export default function(props: ComponentProps<any> & { campaignId: number, toggle: () => {}, isOpen: boolean, filter: CampaignRealtimeBlacklistRequest, setFilter: Dispatch<SetStateAction<any>>, submitFilter: boolean, setSubmitFilter: () => {} }) {
  const context = useContext(pageContext);
  const dataContext = useContext(appContext);
  const [cpa30, setCpa30] = useState('');
  const [cpa7, setCpa7] = useState('');

  function request() {
    const request = createCampaignRealtimeBlacklistRequest();
    request.startDate = "2020-01-01";
    request.endDate = formatDate(new Date());
    request.cpa30 = Number(cpa30);
    request.cpa7 = Number(cpa7);
    props.setFilter(request);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Пушка источники</ModalHeader>
    <ModalBody>
      <form className='row col-12'>
        <label className={'w-100'}>
          Cpa 30 дней
          <input type='text' className={'form-control'} onChange={e => setCpa30(e.currentTarget.value)} value={cpa30} />
        </label>
        <label className={'w-100 mt-2'}>
          Cpa 7 дней
          <input type='text' className={'form-control'} onChange={e => setCpa7(e.currentTarget.value)} value={cpa7} />
        </label>
      </form>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        request();
        props.toggle();
        props.setSubmitFilter(true);
      }}>
        Искать
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}