import React, { ComponentProps, useEffect } from 'react';
import { CountryTopProfitBundles, TopProfitBundle } from '../../../api/eva/alerts/alert.get';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MdClose } from 'react-icons/md';

const CountryTopProfitModal = (props: ComponentProps<any> & { countryTopProfitBundles: CountryTopProfitBundles, toggle: () => {}, isOpen: boolean, className: string }) => {
  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
  }, [props.countryTopProfitBundles, props.isOpen]);


  return (<Modal
    id={props.countryTopProfitBundles.bundles[0].osName}
    size={'lg'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>
      {props.countryTopProfitBundles.country} - Топ 10 бандлов {props.countryTopProfitBundles.bundles[0].osName}
    </ModalHeader>
    <ModalBody>
      <p>Общий профит страны: ${Math.round(props.countryTopProfitBundles.bundles[0].countryProfit)}</p>
      <div className={'d-flex my-3'}>
        <div className={'col-sm-6 '}><b>Наименование</b></div>
        <div className={'col-sm-6 '}><b>Профит</b></div>
      </div>

      {props.countryTopProfitBundles.bundles.map((bundle: TopProfitBundle) => (
        <div key={bundle.appBundle} className={'d-flex my-3'}>
          <div className={'col-sm-6 text-break'}>{bundle.appBundle}</div>
          <div className={'col-sm-6 '}>${Math.round(bundle.profit)}</div>
        </div>
      ))}
    </ModalBody>
  </Modal>);
}

export default CountryTopProfitModal;