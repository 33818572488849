import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import get, {
  CampaignFraudStats,
  CampaignFraudStatsResponseError,
  CampaignFraudStatsResponseOk,
  isResponseOK,
} from '../../../api/campaigns/campaign.fraud-stats.get';
import DashboardContext from '../../../utils/context/dashboardContext';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

export default function(props: ComponentProps<any> & { campaignId: number }) {
  const [stats, setStats] = useState<CampaignFraudStats[]>([]);
  const pageContext = useContext(DashboardContext);
  useEffect(() => {
    let isMounted = true;
    get(props.campaignId).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignFraudStatsResponseError;
        // dataContext.notify(r.message, 'error');
        return [];
      }

      r = r as CampaignFraudStatsResponseOk;
      if (isMounted) {
        setStats(r.message.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <TableContainer sx={{ m: '10px' }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='материалы'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: '10px' }}>Фрод</TableCell>
            <TableCell sx={{ p: '10px' }}>Доля фрода</TableCell>
            <TableCell sx={{ p: '10px' }}>Запросов с фродом в %</TableCell>
            <TableCell sx={{ p: '10px' }}>Количество запросов с фродом</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map(fraud =>
            <TableRow>
              <TableCell sx={{ p: '10px' }}>
                {fraud.isEnabledFraudOption && <DoneIcon style={{ color: 'green' }} />}
                {!fraud.isEnabledFraudOption && <ClearIcon style={{ color: 'red' }} />}
                {fraud.name}
              </TableCell>
              <TableCell sx={{ p: '10px' }}>{fraud.fraudPart}</TableCell>
              <TableCell sx={{ p: '10px' }}>{fraud.fraudReqsPercent}</TableCell>
              <TableCell sx={{ p: '10px' }}>{fraud.fraudReqs}</TableCell>
            </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>);
}