//@ts-nocheck
import React, { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import { DateTime } from 'luxon';
import { CampaignSmartbidHistoryDataEl } from '../../../api/campaigns/campaign.smartbid.history.get';
import { formatDate } from '../../../utils/date';

export default (filter: any) => {
  const columns: MRT_ColumnDef<CampaignSmartbidHistoryDataEl>[] = [
    {
      accessorKey: 'campaignId',
      header: 'Id кампании',
      enableResizing: true,
    },
    {
      accessorFn: (row) => row.date,
      accessorKey: 'date',
      header: 'Дата',
      enableResizing: true,
      sortingFn: 'datetime',
      Cell: ({
               cell,
             }) => cell.getValue<string>(),
    },
    {
      accessorKey: 'domainId',
      header: 'Domain id',
      enableResizing: true,
      Cell: ({cell}) => {
        return (<a target={'_blank'} href={`https://new-admin.inspidsp.com/campaigns/${cell.row.original.campaignId}/analytics?startDate=${formatDate(new Date(filter.startDate))}&endDate=${formatDate(new Date(filter.endDate))}&domainId=${cell.row.original.domainId}`}>
          {cell.row.original.domainId}
        </a>)
      }
    },
    {
      accessorKey: 'tagid',
      header: 'Tagid',
      enableResizing: true,
      Cell: ({cell}) => {
        return (<a target={'_blank'} href={`https://new-admin.inspidsp.com/campaigns/${cell.row.original.campaignId}/analytics?startDate=${formatDate(new Date(filter.startDate))}&endDate=${formatDate(new Date(filter.endDate))}&tagid=${cell.row.original.tagid}&domainId=${cell.row.original.domainId}`}>
          {cell.row.original.tagid}
        </a>)
      }
    },
    {
      accessorKey: 'systemId',
      header: 'System id',
      enableResizing: true,
    },
    {
      accessorKey: 'app',
      header: 'App',
      enableResizing: true,
      Cell: ({
               cell,
             }) => cell.getValue<boolean>() ? 1 : 0,
    },
    {
      accessorKey: 'params',
      header: 'Params',
      enableResizing: true,
      Cell: ({
               cell,
             }) => cell.getValue<string>(),
    },
  ];

  return columns;
};