//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../../../apiKey';

const PATH_URL = '/eva/task/{taskId}/parameter/{paramId}/subscription';

export type TaskParameterUserSubscriptionPostResponseOk = {
  'status': number,
  'message': TaskParameterUserSubscriptionPostResponseMessageOk,
}

export type TaskParameterUserSubscriptionPostResponseMessageOk = {
  data: {
    isSubscribed: boolean
  },
}

export type TaskParameterUserSubscriptionPostResponseError = {
  'status': number,
  'message': TaskParameterUserSubscriptionPostResponseMessageError,
}

export type TaskParameterUserSubscriptionPostResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default async function subscribeParameter(taskId: number, paramId: number): Promise<TaskParameterUserSubscriptionPostResponseOk | TaskParameterUserSubscriptionPostResponseError> {
  try {
    let r = await axios.post(getUrl(taskId, paramId), {},{
      headers: {
        apiKey: key.get(),
      },
    });
    return <TaskParameterUserSubscriptionPostResponseOk | TaskParameterUserSubscriptionPostResponseError>r.data;
  } catch (e) {
    const resp: TaskParameterUserSubscriptionPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  }
}

function getUrl(taskId: number, paramId: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL)
    .replace('{taskId}', taskId)
    .replace('{paramId}', paramId);
}

export function isResponseOK(response: TaskParameterUserSubscriptionPostResponseOk | TaskParameterUserSubscriptionPostResponseError): boolean {
  return typeof response.message === 'object';
}
