import React, { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import get, {
  CampaignsAlertsRequest,
  CampaignsAlertsResponseError,
  CampaignsAlertsResponseOk,
  CampaignsAlertsData,
  isResponseOK,
  recognizeCampaignsAlertsRequest,
} from '../../api/campaigns-alerts/campaigns-alert.get';
import appContext from '../../utils/context/appContext';
import { useSearchParams } from 'react-router-dom';
import CampaignsAlertsContext from '../../utils/context/campaignsAlertsContext';
import FilterPreview from '../../components/CampaignsAlerts/FilterPreview';
import DataTableColumns from '../../components/CampaignsAlerts/DataTable/Columns';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';

const CampaignsAlertsPage = function() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [response, setResponse] = useState<CampaignsAlertsResponseOk>();
    const [data, setData] = useState<CampaignsAlertsData[]>([]);
    const [filter, setFilter] = useState<CampaignsAlertsRequest>(recognizeCampaignsAlertsRequest);
    const dataContext = useContext(appContext);
    const columns = DataTableColumns();

    const updateFilter = (filter: CampaignsAlertsRequest) => {
      setFilter(filter);
    };

    const requestData = () => {
      dataContext.setIsLoading(true);
      get(filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as CampaignsAlertsResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as CampaignsAlertsResponseOk;
        setData(r.message.data.data);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    useEffect(() => {
      setSearchParams(qs.stringify(filter, { arrayFormat: 'brackets' }));
      requestData();
    }, [filter]);

    return (<Page
      className='CampaignsAlerts'
      title='Алерты по кампаниям'
    >
      <CampaignsAlertsContext.Provider
        value={{ requestData, response: response ?? null, filter }}>
        <Filter filter={filter} setFilter={updateFilter} />
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilterModes
          enableColumnOrdering
          enableColumnDragging
          positionToolbarAlertBanner='bottom'
          localization={MRT_Localization_RU}
          initialState={{
            pagination: {
              pageSize: 10,
              pageIndex: 0,
            },
            sorting: [{ id: 'time', desc: true }],
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 100, 500],
            showFirstButton: true,
            showLastButton: true,
            SelectProps: {
              native: true,
            },
          }}
          enableColumnResizing
          enableStickyHeader
          enableStickyFooter
          enablePinning
          muiTableContainerProps={{ sx: { maxHeight: '100vh' } }}
        />
      </CampaignsAlertsContext.Provider>
    </Page>);
  }
;

function Filter(props: any) {
  return (
    <React.Fragment>
      <FilterPreview {...props} />
    </React.Fragment>
  );
}

export default CampaignsAlertsPage;
;
