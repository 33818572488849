import React, { ComponentProps } from 'react';
import { DataColumns } from '../../../utils/campaigns/datatable';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import TableCellRaw from '@mui/material/TableCell';
import { TotalCalculated } from '../../../api/campaigns/campaigns.get';


export default function(props: ComponentProps<any> & { row: TotalCalculated, columns: DataColumns[] }) {
  function dataCutMouseOverHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.currentTarget.innerText = e.currentTarget.dataset.base === undefined ? '' : e.currentTarget.dataset.base;
  }

  function dataCutMouseOutHandler(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    const base = Number(e.currentTarget.dataset.base === undefined ? 0 : e.currentTarget.dataset.base);
    if (base < 1_000_000) {
      return;
    }
    e.currentTarget.innerText = e.currentTarget.dataset.cut === undefined ? '' : e.currentTarget.dataset.cut;
  }

  return (<React.Fragment>
    <TableRow
      classes={{ root: 'total-row' }}
      style={{ backgroundColor: 'rgba(50,200,50,0.1)' }}
      key={'total'}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCellRaw className={'sticky-width-70'}></TableCellRaw>
      <TableCellRaw className={'sticky-width-70'}>Сумма</TableCellRaw>
      <TableCell className={'sticky-width-110'} columns={props.columns} targetColumn={DataColumns.CREATOR}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CAMPAIGN_ID}></TableCell>
      <TableCell className={'sticky-width-150'} columns={props.columns} targetColumn={DataColumns.NAME}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.TYPE}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.STATUS}></TableCell>
      <TableCell className={'sticky-width-150'} columns={props.columns} targetColumn={DataColumns.TARGETING_DOMAIN}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.RATE}>$</TableCell>
      <TableCell className={'sticky-width-110'} columns={props.columns} targetColumn={DataColumns.BUDGET}></TableCell>
      <TableCell className={'sticky-width-110'} columns={props.columns} targetColumn={DataColumns.DAILY_BUDGET}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.SPENT}>
        {Number(props.row.spend.toFixed(2))}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.POTENTIAL}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns}
                 data-cut={(props.row.totalRequests / 1_000_000).toFixed(1) + 'kk'}
                 data-base={props.row.totalRequests}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.REQUESTS}
      >
        {props.row.totalRequests < 1_000_000 ? props.row.totalRequests : (props.row.totalRequests / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.FRAUD_PERCENT}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns}
                 data-cut={(props.row.wins / 1_000_000).toFixed(1) + 'kk'}
                 data-base={props.row.wins}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.HITS}
      >
        {props.row.wins < 1_000_000 ? props.row.wins : (props.row.wins / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns}
                 data-cut={(props.row.impressions / 1_000_000).toFixed(1) + 'kk'}
                 data-base={props.row.impressions}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.IMPRESSIONS}
      >
        {props.row.impressions < 1_000_000 ? props.row.impressions : (props.row.impressions / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns}
                 data-cut={(props.row.views / 1_000_000).toFixed(1) + 'kk'}
                 data-base={props.row.views}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.VIEWS}
      >
        {props.row.views < 1_000_000 ? props.row.views : (props.row.views / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns}
                 data-cut={(props.row.clicks / 1_000_000).toFixed(1) + 'kk'}
                 data-base={props.row.clicks}
                 onMouseOver={dataCutMouseOverHandler}
                 onMouseOut={dataCutMouseOutHandler}
                 targetColumn={DataColumns.CLICKS}
      >
        {props.row.clicks < 1_000_000 ? props.row.clicks : (props.row.clicks / 1_000_000).toFixed(1) + 'kk'}
      </TableCell>
      <TableCell className={'sticky-width-150'} columns={props.columns} targetColumn={DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.WIN_RATE}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CTR_PERCENT}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CPC}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.LEADS_PROFIT}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.LEADS_LIMIT}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.LIMIT_LEADS_PER_DAY}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.LEADS}>
        {props.row.conversions}
      </TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.PROFIT}>{props.row.profit.toFixed(2)}</TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.RATIO}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CR_PERCENT}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CPM}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.CPA}></TableCell>
      <TableCell className={'sticky-width-70'} columns={props.columns} targetColumn={DataColumns.ACTIONS}></TableCell>
    </TableRow>
  </React.Fragment>);
}