import React, { ComponentProps, useEffect, useState } from 'react';
import { AlertsData, AlertTextTypeJson, CountryTopProfitBundles } from '../../../api/eva/alerts/alert.get';
import TableCellRaw from '@mui/material/TableCell';
import { List, ListItem } from '@mui/material';
import CountryTopProfitModal from './CountryTopProfitModal';


const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const detectAlertTextType = (alertTextType: AlertTextTypeJson) => {
  if (alertTextType.type === 'CountryTopProfitBundles')
    return topProfitCountriesList(alertTextType.list);
}


const topProfitCountriesList = (countryBundles: CountryTopProfitBundles[]) => {
  const [countryTopProfitBundles, setCountryTopProfitBundles]
    = useState<CountryTopProfitBundles>(countryBundles[0]);
  const [isOpenCountryTopProfitModalModal, setIsOpenCountryTopProfitModalModal]
    = useState<boolean>(false);
  const openModal = (newCountryTopProfitBundles: CountryTopProfitBundles) => {
    setCountryTopProfitBundles(newCountryTopProfitBundles);
    setIsOpenCountryTopProfitModalModal(true);
  }

  useEffect(() => {

  }, [countryTopProfitBundles, isOpenCountryTopProfitModalModal, openModal]);

  return (
    <List>
      <CountryTopProfitModal countryTopProfitBundles={countryTopProfitBundles}
                             isOpen={isOpenCountryTopProfitModalModal}
                             toggle={() => setIsOpenCountryTopProfitModalModal((prev) => !prev)}
      />
      {countryBundles.map((item) => (
        <ListItem key={item.country} className={'py-0'}>
          <a onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openModal(item);
             }}
             href='#'
             className={"link-info link-underline-opacity-0"}
          >
            {item.country}
          </a>
        </ListItem>
      ))}
    </List>
  );
}

export default function(props: ComponentProps<any> & { alert: AlertsData[] }) {
  return (
    <TableCellRaw>{isJson(props.alert.text)
      ? detectAlertTextType(JSON.parse(props.alert.text))
      : (<div dangerouslySetInnerHTML={{ __html: props.alert.text }} />)}
    </TableCellRaw>
  );
}