import React, { ComponentProps } from 'react';
import TableCell from '@mui/material/TableCell';
import { DataColumns } from '../../../utils/campaigns/datatable';

type TableCellType = {
  columns: DataColumns[],
  targetColumn: DataColumns
}
export default function(props: ComponentProps<any> & TableCellType) {
  const {targetColumn, columns, ...propsComponent} = props;
  return (<React.Fragment>
    {columns.includes(targetColumn) &&
      <TableCell align='right' {...propsComponent} ></TableCell>}
  </React.Fragment>);
}

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
  tableWrapper: {
    style: {
      overflow: 'unset',
      // paddingBottom: "120px"
    },
  },
};