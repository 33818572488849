//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/subscription';

export type CampaignSubscriptionDeleteResponseOk = {
  'status': number,
  'message': CampaignSubscriptionDeleteResponseMessageOk,
}

export type CampaignSubscriptionDeleteMessageOk = {
  data: CampaignSubscriptionDeleteData,
}

export type CampaignSubscriptionDeleteResponseError = {
  'status': number,
  'message': CampaignSubscriptionDeleteResponseMessageError,
}

export type CampaignSubscriptionDeleteResponseMessageError = string

export type CampaignSubscriptionDeleteData = {
  info: 'ok'
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function remove(id: number): Promise<CampaignSubscriptionDeleteResponseOk | CampaignSubscriptionDeleteResponseError> {
  return axios.delete(getUrl(id), {
    headers: {
      apikey: key.get(),
    },
  }).then(r => {
    return <CampaignSubscriptionDeleteResponseMessageOk | CampaignSubscriptionDeleteResponseError>r.data;
  }).catch(e => {
    const resp: CampaignSubscriptionDeleteResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignSubscriptionDeleteResponseOk | CampaignSubscriptionDeleteResponseError): boolean {
  return typeof response.message !== 'string';
}