//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/preferences/telegram/chat';

export type TelegramChatPatchResponseOk = {
  'status': number,
  'message': TelegramChatPatchResponseMessageOk,
}

export type TelegramChatPatchResponseMessageOk = {
  data: TelegramChatPatch[],
}

export type TelegramChatPatchResponseError = {
  'status': number,
  'message': TelegramChatPatchResponseMessageError,
}

export type TelegramChatPatchResponseMessageError = string


export type TelegramChatPatchRequest = {
  chatid: number,
  group?: number,
}

export type TelegramChatPatch = {
  chatid: number,
  group: number,
}

// @ts-ignore
const key = new apiKey();


export default function patch(request: TelegramChatPatchRequest): Promise<TelegramChatPatchResponseOk | TelegramChatPatchResponseError> {
  return axios.patch(process.env.REACT_APP_API_URL + PATH_URL, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <TelegramChatPatchResponseMessageOk | TelegramChatPatchResponseError>r.data;
  }).catch(e => {
    const resp: TelegramChatPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: TelegramChatPatchResponseOk | TelegramChatPatchResponseError): boolean {
  return typeof response.message !== 'string';
}