//@ts-nocheck
import { apiKey } from '../../apiKey';
import axios from 'axios';

const PATH_URL = '/eva/alert/{id}/approve';

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}
export type AlertApprovePatchResponseOk = {
  'status': number,
  'message': AlertApprovePatchResponseMessageOk,
}

export type AlertApprovePatchResponseMessageOk = {
  data: {
    reaction: string,
  },
}

export type AlertApprovePatchResponseError = {
  'status': number,
  'message': AlertApprovePatchResponseMessageError,
}

export type AlertApprovePatchResponseMessageError = string

export default async function patch(id: number): Promise<AlertApprovePatchResponseOk | AlertApprovePatchResponseError> {
  try {
    const r = await axios.patch(getUrl(id), {},{
      headers: {
        apiKey: key.get(),
      },
    });
    return <AlertApprovePatchResponseMessageOk | AlertApprovePatchResponseError>r.data;
  } catch (e) {
    const resp: AlertApprovePatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  }
}

export function isResponseOK(response: AlertApprovePatchResponseOk | AlertApprovePatchResponseError): boolean {
  return typeof response.message !== 'string';
}