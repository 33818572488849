import { createTheme, Paper, TableFooter, ThemeProvider } from '@mui/material';
import React, { ComponentProps, useEffect, useState } from 'react';
import { AlertsData } from '../../../api/eva/alerts/alert.get';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellRaw from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import approvePatch, {
  AlertApprovePatchResponseError, AlertApprovePatchResponseOk,
  isResponseOK as isApproveResponseOk,
} from '../../../api/eva/alerts/alert.approve.patch';
import dislikePatch, {
  AlertDislikePatchResponseError, AlertDislikePatchResponseOk,
  isResponseOK as isDislikeResponseOk,
} from '../../../api/eva/alerts/alert.dislike.patch';
import AlertTextCellRaw from './AlertTextCellRaw';


async function approve(alertId: number, e: any) {
  let r = await approvePatch(alertId);
  if (!isApproveResponseOk(r)) {
    r = r as AlertApprovePatchResponseError;

    e.target.parentElement.innerHTML = r.message;

    return null;
  }

  r = r as AlertApprovePatchResponseOk;
  e.target.parentElement.innerHTML = r.message.data.reaction;
}


async function dislike(alertId: number, e: any) {
  let r = await dislikePatch(alertId);
  if (!isDislikeResponseOk(r)) {
    r = r as AlertDislikePatchResponseError;

    e.target.parentElement.innerHTML = r.message;

    return null;
  }

  r = r as AlertDislikePatchResponseOk;
  e.target.parentElement.innerHTML = r.message.data.reaction;
}


const theme = createTheme({
  components: {},
});


export default function(props: ComponentProps<any> & { alerts: AlertsData[] }) {
  const [alerts, setAlerts] = useState<Array<AlertsData>>([]);

  useEffect(() => {
    setAlerts(props.alerts);
  }, [props.alerts]);

  return (<TableContainer component={Paper}>

    <ThemeProvider theme={theme}>
      <Table id={'alerts-table'} sx={{ minWidth: 650 }} aria-label='alerts'>
        <TableHead>
          <TableRow>
            <TableCellRaw><b>Пользователь</b></TableCellRaw>
            <TableCellRaw><b>Уведомление</b></TableCellRaw>
            <TableCellRaw><b>Сообщение</b></TableCellRaw>
            <TableCellRaw><b>Ваша реакция на уведомление</b></TableCellRaw>
            <TableCellRaw><b>Дата</b></TableCellRaw>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((alert) => (
            <AlertRows alert={alert}></AlertRows>
          ))}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
    </ThemeProvider>
  </TableContainer>);

  function AlertRows(props: { alert: AlertsData }) {
    const alert = props.alert;

    return (
      <React.Fragment>
        <TableRow key={alert.id}>
          <TableCellRaw>{alert.userLogin}</TableCellRaw>
          <TableCellRaw>{alert.name}</TableCellRaw>
          <AlertTextCellRaw alert={alert} />
          <TableCellRaw>{alert.reactionResult === 'Ожидает реакции'
            ?
            <>
              <button className={'m-1 btn btn-sm btn-danger'}
                      onClick={(e) => approve(alert.id, e)}>
                Выполнить
              </button>
              <button className={'m-1 btn btn-sm btn-dark'}
                      onClick={(e) => dislike(alert.id, e)}>
                Не интересует
              </button>
            </>
            : alert.reactionResult}
          </TableCellRaw>
          <TableCellRaw>{DateTime.fromISO(alert.createdAt, { setZone: true }).toFormat('dd.MM.yyyy HH:mm')}</TableCellRaw>
        </TableRow>
      </React.Fragment>
    );
  }
}