//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';

const PATH_URL = '/campaigns/bundles/from-bl/{fromId}/to-wl/{toId}';

export type CampaignBundleCopyBlacklistToWhitelistPostRequest = {}
export type CampaignBundleCopyBlacklistToWhitelistPostResponseOk = {
  'status': number,
  'message': CampaignBundleCopyBlacklistToWhitelistPostResponseMessageOk,
}

export type CampaignBundleCopyBlacklistToWhitelistPostResponseMessageOk = {
  data: null,
}

export type CampaignBundleCopyBlacklistToWhitelistPostResponseError = {
  'status': number,
  'message': CampaignBundleCopyBlacklistToWhitelistPostResponseMessageError,
}

export type CampaignBundleCopyBlacklistToWhitelistPostResponseMessageError = string

// @ts-ignore
const key = new apiKey();

function getUrl(fromId: number|string, toId: number|string): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{fromId}', fromId).replace('{toId}', toId);
}

export default function post(campaignFromId: number|string, campaignToId: number|string): Promise<CampaignBundleCopyBlacklistToWhitelistPostResponseOk | CampaignBundleCopyBlacklistToWhitelistPostResponseError> {
  return axios.post(getUrl(campaignFromId, campaignToId), {}, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignBundleCopyBlacklistToWhitelistPostResponseMessageOk | CampaignBundleCopyBlacklistToWhitelistPostResponseError>r.data;
  }).catch(e => {
    const resp: CampaignBundleCopyBlacklistToWhitelistPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignBundleCopyBlacklistToWhitelistPostResponseOk | CampaignBundleCopyBlacklistToWhitelistPostResponseError): boolean {
  return typeof response.message !== 'string';
}