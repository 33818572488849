//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate, getDateNDaysAgo } from '../../utils/date';
import { SelectData } from './campaigns.get';

const PATH_URL = '/campaigns/analytics';

export type CampaignAnalyticsRequest = {
  prevDate: string,
  date: string,
  page?: number
  campaigns?: number[],
}


export type CampaignAnalyticsResponseOk = {
  'status': number,
  'message': CampaignAnalyticsResponseMessageOk,
}

export type CampaignAnalyticsResponseMessageOk = {
  data: {
    data: CampaignAnalyticsStat[],
    total: {
      prevDate: number
      date: number
      diffPercent: number
      diff: number
    },
    filter: {
      groups: SelectData[]
    },

  },
}

export type CampaignAnalyticsStat = {
  id: number
  name: string
  reasons: string[]
  author: {
    id: number
    name: string
  }
  profit: {
    prevDate: number
    date: number
    diffPercent: number
    diff: number
  }

  sourcesTotal: {
    prevDate: number
    date: number
    diffPercent: number
    diff: number
  }
  sources: {
    tagid: string
    domainId: string
    reasons: string[]
    profit: {
      prevDate: number
      date: number
      diffPercent: number
      diff: number
    }
  }[]
}

export type CampaignAnalyticsResponseError = {
  'status': number,
  'message': CampaignAnalyticsResponseMessageError,
}

export type CampaignAnalyticsResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function get(request: CampaignAnalyticsRequest = null): Promise<CampaignAnalyticsResponseOk | CampaignAnalyticsResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignAnalyticsResponseOk | CampaignAnalyticsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignAnalyticsResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignAnalyticsResponseOk | CampaignAnalyticsResponseError): boolean {
  return typeof response.message === 'object';
}