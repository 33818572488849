//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../../apiKey';

const PATH_URL = '/eva/task/{id}/is-auto-executable';

export type TaskIsAutoExecutablePatchResponseOk = {
  'status': number,
  'message': TaskIsAutoExecutablePatchResponseMessageOk,
}

export type TaskIsAutoExecutablePatchRequest = {
  isAutoExecutable: boolean
}

export type TaskIsAutoExecutablePatchResponseMessageOk = {
  data: {
    isAutoExecutable: boolean
  },
}

export type TaskIsAutoExecutablePatchResponseError = {
  'status': number,
  'message': TaskIsAutoExecutablePatchResponseMessageError,
}

export type TaskIsAutoExecutablePatchResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function patch(id: number, request: TaskIsAutoExecutablePatchRequest): Promise<TaskIsAutoExecutablePatchResponseOk | TaskIsAutoExecutablePatchResponseError> {
  return axios.patch(getUrl(id), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <TaskIsAutoExecutablePatchResponseOk | TaskIsAutoExecutablePatchResponseError>r.data;
  }).catch(e => {
    const resp: TaskIsAutoExecutablePatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export function isResponseOK(response: TaskIsAutoExecutablePatchResponseOk | TaskIsAutoExecutablePatchResponseError): boolean {
  return typeof response.message === 'object';
}
