//@ts-nocheck
import { apiKey } from '../../apiKey';
import axios from 'axios';

const PATH_URL = '/eva/task/{id}/user-subscription';

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export type TaskUserSubscriptionDeleteResponseOk = {
  'status': number,
  'message': TaskUserSubscriptionDeleteResponseMessageOk,
}

export type TaskUserSubscriptionDeleteResponseMessageOk = {
  data: {
    isWorks: boolean
  },
}

export type TaskUserSubscriptionDeleteResponseError = {
  'status': number,
  'message': TaskUserSubscriptionDeleteResponseMessageError,
}

export type TaskUserSubscriptionDeleteResponseMessageError = string

export default async function unsubscribe(id: number): Promise<TaskUserSubscriptionDeleteResponseOk | TaskUserSubscriptionDeleteResponseError> {
  try {
    const r = await axios.delete(getUrl(id), {
      headers: {
        apiKey: key.get(),
      },
    });
    return <TaskUserSubscriptionDeleteResponseOk | TaskUserSubscriptionDeleteResponseError>r.data;
  } catch (e) {
    const resp: TaskUserSubscriptionDeleteResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  }
}

export function isResponseOK(response: TaskUserSubscriptionDeleteResponseOk | TaskUserSubscriptionDeleteResponseError): boolean {
  return typeof response.message === 'object';
}
