import React, { ComponentProps, useContext, useEffect, useRef, useState } from 'react';
import getStatistic, {
  OffersResponseError,
  OffersResponseOk,
  isResponseOK,
  Statistic
} from '../../api/offers/offer_statistic.get';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import DashboardContext from '../../utils/context/dashboardContext';
import appContext from '../../utils/context/appContext';


export default function(props: ComponentProps<any> & { offerId: number, statistic: boolean}) {
  const pageContext = useContext(DashboardContext);
  const dataContext = useContext(appContext);
  const [statistic, setStatistic] = useState<Statistic[]>([]);

  const updateStatistic = (id: number) => {
    getStatistic(id).then(r => {
      if (!isResponseOK(r)) {
        r = r as OffersResponseError;
        return [];
      }
      r = r as OffersResponseOk;
      setStatistic(r.message.data);
    });
  }

  useEffect(() => {
    updateStatistic(props.offerId);
  }, [props.t]);

  return (
    <TableContainer sx={{ m: '10px' }}>
      <Table aria-label='Статистика'>
        <TableHead>
          <TableRow>
            <TableCell>Показатель</TableCell>
            {statistic.map((st: Statistic) => (
              <TableCell>{st.date}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>CPA</TableCell>
            {statistic.map((st: Statistic) => (
              <TableCell>{st.cpa}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Profit</TableCell>
            {statistic.map((st: Statistic) => (
              <TableCell>{st.profit}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Spent</TableCell>
            {statistic.map((st: Statistic) => (
              <TableCell>{st.spent}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Convs</TableCell>
            {statistic.map((st: Statistic) => (
              <TableCell>{st.convs}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>);
}