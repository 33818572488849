import React, { useContext, useEffect, useState } from 'react';
import Switch from 'rc-switch';
import { CampaignMaterial, MaterialStatus as MaterialStatusEnum } from '../../../api/campaigns/campaign.materials.get';
import appContext from '../../../utils/context/appContext';
import patch, { isResponseOK } from '../../../api/materials/materials.patch';


const MaterialStatus = (props: { material: CampaignMaterial }) => {
  const [status, setStatus] = useState<MaterialStatusEnum>(props.material.status);
  const [disabled, setDisabled] = useState<boolean>(true);
  const MainContext = useContext(appContext);
  useEffect(() => {
    setStatus(props.material.status)
  }, [props.material]);

  const updateStatus = (status: MaterialStatusEnum) => {
    setDisabled(true);
    patch(props.material.id, { status }).then(r => {
      setDisabled(false);
      if (!isResponseOK(r.message)) {
        MainContext.notify(r.message as string, 'error');
        return;
      }
      setStatus(status);
    });
  };

  const onChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if (checked) {
      updateStatus(MaterialStatusEnum.ON);
    } else {
      updateStatus(MaterialStatusEnum.OFF);
    }
  };

  return (
    <Switch checked={status === MaterialStatusEnum.ON} checkedChildren='on'
            onChange={onChange} unCheckedChildren='off' />);
};
export default MaterialStatus;
