import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../components/Page';
import get, {
  AlertsRequest,
  AlertsResponseError,
  AlertsResponseOk,
  AlertsData,
  isResponseOK,
  recognizeAlertsRequest,
} from '../../../api/eva/alerts/alert.get';
import appContext from '../../../utils/context/appContext';
import AlertsContext from '../../../utils/context/eva/alertsContext';
import { Button } from 'reactstrap';
import { Link, } from '@mui/material';
import { BiCog } from 'react-icons/bi';
import MaterialDataTable from '../../../components/Eva/Alerts/MaterialDataTable';

const EvaAlertsPage = function() {
    const [response, setResponse] = useState<AlertsResponseOk>();
    const [data, setData] = useState<AlertsData[]>([]);
    const [filter, setFilter] = useState<AlertsRequest>(recognizeAlertsRequest);
    const dataContext = useContext(appContext);

    const requestData = () => {
      dataContext.setIsLoading(true);
      get(filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as AlertsResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as AlertsResponseOk;
        setData(r.message.data.data);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    useEffect(() => {
      requestData();
    }, [filter]);

    return (<Page
      title='Уведомления Евы'
    >
      <Link href={"/eva/tasks"}>
        <Button className={'mb-4'}>
          <BiCog /> Открыть настройки задач Евы
        </Button>
      </Link>

      <AlertsContext.Provider
        value={{ requestData, response: response ?? null, filter }}>
        <MaterialDataTable alerts={data}/>
      </AlertsContext.Provider>
    </Page>);
  }
;

export default EvaAlertsPage;
