//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';
import { MaterialStatus } from '../campaigns/campaign.materials.get';

const PATH_URL = '/materials/{id}';

export type MaterialsPatchResponseOk = {
  'status': number,
  'message': MaterialsPatchResponseMessageOk,
}

export type MaterialsPatchResponseError = {
  'status': number,
  'message': string,
}

export type MaterialsPatchResponseMessageOk = { data: { info: 'ok' } };
export type MaterialsPatchResponseMessageError = string;

export type MaterialsPatchRequest = {
  status?: MaterialStatus
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function patch(id: number, request: MaterialsPatchRequest): Promise<MaterialsPatchResponseOk | MaterialsPatchResponseError> {
  return axios.patch(getUrl(id), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <MaterialsPatchResponseOk | MaterialsPatchResponseError>r.data;
  }).catch(e => {
    const resp: MaterialsPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: MaterialsPatchResponseMessageOk | MaterialsPatchResponseMessageError): boolean {
  return typeof response.message !== 'string';
}