import { Campaign, CampaignRequest, CampaignResponseMessageOk } from '../../api/campaigns/campaigns.get';
import { TableColumn } from 'react-data-table-component';
import React from 'react';
import parse from 'html-react-parser';
import { Styles } from 'styled-components/native/dist/types';
import 'rc-switch/assets/index.css';
import SelectTargetingDomain from '../../components/Dashboard/SelectTargetingDomain';
import CampaignStatus from '../../components/Dashboard/CampaignStatus';

export enum DataColumns {
  CREATOR,
  CAMPAIGN_ID,
  NAME,
  TYPE,
  STATUS,
  TARGETING_DOMAIN,
  RATE,
  BUDGET,
  DAILY_BUDGET,
  SPENT,
  POTENTIAL,
  REQUESTS,
  FRAUD_PERCENT,
  HITS,
  IMPRESSIONS,
  VIEWS,
  CLICKS,
  TRAFFIC_DYNAMIC_HOUR_CHART,
  WIN_RATE,
  CTR_PERCENT,
  CPC,
  LEADS_PROFIT,
  LEADS_LIMIT,
  LIMIT_LEADS_PER_DAY,
  LEADS,
  PROFIT,
  RATIO,
  CR_PERCENT,
  CPM,
  CPA,
  ACTIONS,
}

export namespace DataColumns {
  export function getAllInString(): string[] {
    return [
      // 'col_tick',
      'col_creator',
      'col_id',
      'col_name',
      'col_format',
      'col_status',
      'col_targeting_domain',
      'col_rate',
      'col_limit',
      'col_limit_today',
      'col_spent_all',
      'col_imps_real',
      'col_imps',
      'col_fraud_percent',
      'col_traffic',
      'col_hitreal',
      'col_views',
      'col_clicks',
      'col_traffic_hour',
      'col_vtr_proc',
      'col_ctr',
      'col_cpc',
      'col_conv_cost',
      'col_pdp_limit_total',
      'col_pdp_limit_day',
      'col_pdp',
      'col_profit',
      'col_cr_proc',
      'col_cpm',
      'col_cpa',
      'col_actions',
    ];
  }

  export function getSortAssociations(): { [key: number]: string; } {
    {
      return {
        [DataColumns.CREATOR]: 'creator',
        [DataColumns.CAMPAIGN_ID]: 'id',
        [DataColumns.NAME]: 'name',
        [DataColumns.TYPE]: 'type',
        [DataColumns.STATUS]: 'status',
        [DataColumns.RATE]: 'rate',
        [DataColumns.BUDGET]: 'limitCommon',
        [DataColumns.DAILY_BUDGET]: 'limitDay',
        [DataColumns.LEADS_LIMIT]: 'limitConvsCommon',
        [DataColumns.CR_PERCENT]: 'conversionRate',
        [DataColumns.SPENT]: 'spent',
        [DataColumns.CTR_PERCENT]: 'ctr',
        [DataColumns.PROFIT]: 'profit',
      };
    }
  }

  export function getName(column: DataColumns): string {
    switch (column) {
      case DataColumns.CREATOR:
        return 'Создатель';
      case DataColumns.CAMPAIGN_ID:
        return 'ID';
      case DataColumns.NAME:
        return 'Название';
      case DataColumns.TYPE:
        return 'Тип';
      case DataColumns.STATUS:
        return 'Статус';
      case DataColumns.TARGETING_DOMAIN:
        return 'Таргетинг на домены';
      case DataColumns.RATE:
        return 'Ставка';
      case DataColumns.BUDGET:
        return 'Лимит';
      case DataColumns.DAILY_BUDGET:
        return 'Лимит сегодня';
      case DataColumns.SPENT:
        return 'Потрачено';
      case DataColumns.POTENTIAL:
        return 'Потенциал';
      case DataColumns.REQUESTS:
        return 'Запросы';
      case DataColumns.FRAUD_PERCENT:
        return '% фрода';
      case DataColumns.HITS:
        return 'Хиты';
      case DataColumns.IMPRESSIONS:
        return 'Показы';
      case DataColumns.VIEWS:
        return 'Просмотры';
      case DataColumns.CLICKS:
        return 'Клики';
      case DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART:
        return 'Динамика трафика за час';
      case DataColumns.WIN_RATE:
        return 'Win rate';
      case DataColumns.CTR_PERCENT:
        return 'CTR, %';
      case DataColumns.CPC:
        return 'CPC';
      case DataColumns.LEADS_PROFIT:
        return 'Leads profit';
      case DataColumns.LEADS_LIMIT:
        return 'Лимит лидов';
      case DataColumns.LIMIT_LEADS_PER_DAY:
        return 'Лимит лидов сегодня';
      case DataColumns.LEADS:
        return 'Лиды';
      case DataColumns.PROFIT:
        return 'Прибыль';
      case DataColumns.RATIO:
        return 'Ratio';
      case DataColumns.CR_PERCENT:
        return 'Cr %';
      case DataColumns.CPM:
        return 'CPM';
      case DataColumns.CPA:
        return 'CPA';
      case DataColumns.ACTIONS:
        return 'Действия';
      default:
        return '';
    }
  }

  export function sortBy(data: Campaign[], column: DataColumns, dir: 'asc' | 'desc'): Campaign[] {
    return data.sort((a, b) => {
      let key: keyof Campaign;
      switch (column) {
        case DataColumns.CREATOR:
          key = 'userLogin';
          break;
        case DataColumns.NAME:
          key = 'name';
          break;
        case DataColumns.TYPE:
          key = 'format';
          break;
        case DataColumns.STATUS:
          key = 'status';
          break;
        case DataColumns.TARGETING_DOMAIN:
          key = 'lists';
          break;
        case DataColumns.RATE:
          key = 'rate';
          break;
        case DataColumns.BUDGET:
          key = 'limitCommon';
          break;
        case DataColumns.DAILY_BUDGET:
          key = 'limitDaily';
          break;
        case DataColumns.SPENT:
          key = 'spend';
          break;
        case DataColumns.POTENTIAL:
          key = 'impsReal';
          break;
        case DataColumns.REQUESTS:
          key = 'totalRequests';
          break;
        case DataColumns.HITS:
          key = 'wins';
          break;
        case DataColumns.IMPRESSIONS:
          key = 'impressions';
          break;
        case DataColumns.VIEWS:
          key = 'views';
          break;
        case DataColumns.CLICKS:
          key = 'clicks';
          break;
        case DataColumns.CPC:
          key = 'cpc';
          break;
        case DataColumns.LEADS_LIMIT:
          key = 'limitConvsCommon';
          break;
        case DataColumns.LIMIT_LEADS_PER_DAY:
          key = 'limitConvsDay';
          break;
        case DataColumns.CPM:
          key = 'cpm';
          break;
        case DataColumns.PROFIT:
          key = 'profit';
          break;
        case DataColumns.CPA:
          key = 'cpa';
          break;
        case DataColumns.WIN_RATE:
          key = 'winRate';
          break;
        case DataColumns.LEADS:
          key = 'convs';
          break;
        case DataColumns.CAMPAIGN_ID:
        default:
          key = 'id';
      }

      if (dir === 'asc')
        return a[key] > b[key] ? 1 : -1;
      else
        return a[key] < b[key] ? 1 : -1;
    });
  }

  export function getSelector(column: DataColumns, campaign: any, filter?: CampaignRequest): any {
    const isTotal = isTotalRow(campaign);
    switch (column) {
      case DataColumns.CREATOR:
        return !isTotal ? campaign.userLogin : 'Сумма';
      case DataColumns.CAMPAIGN_ID:
        return !isTotal ? campaign.id : '';
      case DataColumns.NAME:
        return !isTotal ? campaign.name : '';
      case DataColumns.TYPE:
        return !isTotal ? (campaign.format === 'push-native') ? 'PN' : campaign.format[0].toUpperCase() : '';
      case DataColumns.STATUS:
        return !isTotal ? (<CampaignStatus campaign={campaign} />) : '';
      case DataColumns.TARGETING_DOMAIN:
        return !isTotal ? <SelectTargetingDomain campaign={campaign} /> : '';
      case DataColumns.RATE:
        return !isTotal ? campaign.rate : '$';
      case DataColumns.BUDGET:
        return !isTotal ? campaign.limitCommon : '';
      case DataColumns.DAILY_BUDGET:
        return !isTotal ? campaign.limitDaily : '';
      case DataColumns.SPENT:
        return Number(campaign.spend.toFixed(2));
      case DataColumns.POTENTIAL:
        return campaign.impsReal;
      case DataColumns.REQUESTS:
        return campaign.totalRequests;
      case DataColumns.FRAUD_PERCENT:
        return '';
      case DataColumns.HITS:
        return campaign.wins;
      case DataColumns.IMPRESSIONS:
        return campaign.impressions;
      case DataColumns.VIEWS:
        return campaign.views;
      case DataColumns.CLICKS:
        return campaign.clicks;
      case DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART:
        return !isTotal ? campaign.trafficDynamicForHourChart : '';
      case DataColumns.WIN_RATE:
        return !isTotal ? campaign.winRate : '';
      case DataColumns.CTR_PERCENT:
        return Number(campaign.ctrPercent.toFixed(2));
      case DataColumns.CPC:
        return Number(campaign.cpc.toFixed(2));
      case DataColumns.LEADS_PROFIT:
        return !isTotal ? 'нет данных' : '';
      case DataColumns.LEADS_LIMIT:
        return campaign.limitConvsCommon;
      case DataColumns.LIMIT_LEADS_PER_DAY:
        return campaign.limitConvsDay;
      case DataColumns.LEADS:
        return campaign.convs;
      case DataColumns.PROFIT:
        return !isTotal ? 'нет данных' : '';
      case DataColumns.RATIO:
        return campaign.conversionRate;
      case DataColumns.CR_PERCENT:
        return Number((campaign.clicks > 0 ? campaign.convs / campaign.clicks : 0).toFixed(2));
      case DataColumns.CPM:
        return Number(campaign.cpm.toFixed(2));
      case DataColumns.CPA:
        return Number(campaign.cpa.toFixed(2));
      case DataColumns.ACTIONS:
        return '';
      default:
        return '';
    }
  }

  export function getFromResponse(message: CampaignResponseMessageOk): DataColumns[] {
    let columns: DataColumns[] = [];
    let headers = DataColumns.getAllInString();
    if (message.headers.length > 0) {
      headers = message.headers;
    }

    headers.map((header) => {
      const column = get(header);
      if (typeof column === 'number') {
        columns.push(column);
      }
    });


    columns.sort((a, b) => {
      return a > b ? 1 : -1;
    });
    return columns;
  }

  export function get(header: string): DataColumns | null {
    switch (header) {
      case 'col_tick':
        break;
      case 'col_creator':
        return DataColumns.CREATOR;
      case 'col_id':
        return DataColumns.CAMPAIGN_ID;
      case 'col_name':
        return DataColumns.NAME;
      case 'col_format':
        return DataColumns.TYPE;
      case 'col_status':
        return DataColumns.STATUS;
      case 'col_targeting_domain':
        return DataColumns.TARGETING_DOMAIN;
      case 'col_rate':
        return DataColumns.RATE;
      case 'col_limit':
        return DataColumns.BUDGET;
      case 'col_limit_today':
        return DataColumns.DAILY_BUDGET;
      case 'col_spent_all':
        return DataColumns.SPENT;
      case 'col_imps_real':
        return DataColumns.POTENTIAL;
      case 'col_imps':
        return DataColumns.REQUESTS;
      case 'col_fraud_percent':
        return DataColumns.FRAUD_PERCENT;
      case 'col_traffic':
        return DataColumns.HITS;
      case 'col_hitreal':
        return DataColumns.IMPRESSIONS;
      case 'col_views':
        return DataColumns.VIEWS;
      case 'col_clicks':
        return DataColumns.CLICKS;
      case 'col_traffic_hour':
        return DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART;
      case 'col_vtr_proc':
        return DataColumns.WIN_RATE;
      case 'col_ctr':
        return DataColumns.CTR_PERCENT;
      case 'col_cpc':
        return DataColumns.CPC;
      case 'col_conv_cost':
        return DataColumns.LEADS_PROFIT;
      case 'col_pdp_limit_total':
        return DataColumns.LEADS_LIMIT;
      case 'col_pdp_limit_day':
        return DataColumns.LIMIT_LEADS_PER_DAY;
      case 'col_pdp':
        return DataColumns.LEADS;
      case 'col_profit':
        return DataColumns.PROFIT;
      case 'col_cr_proc':
        return DataColumns.CR_PERCENT;
      case 'col_cpm':
        return DataColumns.CPM;
      case 'col_cpa':
        return DataColumns.CPA;
      case 'col_actions':
        return DataColumns.ACTIONS;
    }
    return null;
  }

  export function prepare(column: DataColumns, filter: CampaignRequest): TableColumn<any> {
    const result: TableColumn<any> = {
      name: DataColumns.getName(column),
      selector: (campaign: any) => DataColumns.getSelector(column, campaign, filter),
      sortable: true,
      maxWidth: '150px',
      style: getStyles(column),
    };

    switch (column) {
      case DataColumns.TARGETING_DOMAIN:
        result.allowOverflow = true;
        result.width = '180px';
        result.cell = (campaign: any): any => {
          return (DataColumns.getSelector(column, campaign));
        };
        break;
      case DataColumns.NAME:
        result.wrap = true;
        result.minWidth = '200px';
        break;
      case DataColumns.TRAFFIC_DYNAMIC_HOUR_CHART:
        result.cell = (campaign: any): any => {
          return (parse(DataColumns.getSelector(column, campaign)));
        };
        break;
      default:
        break;
    }

    return result;
  }

  export function getStyles(column: DataColumns) {
    const styles: Styles<any> = {};
    switch (column) {
      case DataColumns.NAME:
        break;
      default:
        break;
    }

    return {};
  }
}

export type TotalCalculatedRow = {
  id: number,
  spend: number,
  impsReal: number,
  totalRequests: number,
  wins: number,
  impressions: number,
  views: number,
  clicks: number,
  imps: number,
  trafficVtr: number,
  ctrPercent: number,
  cpc: number,
  winRate: number,
  convs: number,
  cpm: number,
  cpa: number,
  profit: number,
};

export function createTotalCalculatedRow(data: Campaign[]) {
  const total: TotalCalculatedRow = {
    id: -1, //total row
    clicks: 0,
    convs: 0,
    cpa: 0,
    cpc: 0,
    cpm: 0,
    ctrPercent: 0,
    impressions: 0,
    imps: 0,
    impsReal: 0,
    spend: 0,
    totalRequests: 0,
    trafficVtr: 0,
    views: 0,
    winRate: 0,
    wins: 0,
    profit: 0,
  };
  data.map(c => {
    total.clicks += c.clicks;
    total.convs += c.convs;
    total.cpa += c.cpa;
    total.cpc += c.cpc;
    total.cpm += c.cpm;
    total.ctrPercent += c.ctrPercent;
    total.impressions += c.impressions;
    total.imps += c.imps;
    total.impsReal += c.impsReal;
    total.spend += c.spend;
    total.totalRequests += c.totalRequests;
    total.trafficVtr += c.trafficVtr;
    total.views += c.views;
    total.winRate += c.winRate;
    total.wins += c.wins;
    total.profit += c.profit;
  });
  return total;
}

export function isTotalRow(row: Campaign | TotalCalculatedRow) {
  return row.id <= 0;
}