//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/fraud-stats';

export type CampaignFraudStatsResponseOk = {
  'status': number,
  'message': CampaignFraudStatsResponseMessageOk,
}

export type CampaignFraudStatsResponseMessageOk = {
  data: CampaignFraudStats[],
}

export type CampaignFraudStatsResponseError = {
  'status': number,
  'message': CampaignFraudStatsResponseMessageError,
}

export type CampaignFraudStatsResponseMessageError = string


export type CampaignFraudStats = {
  id: number,
  name: string,
  fraudPart: number,
  fraudReqs: number,
  fraudReqsPercent: number,
  isEnabledFraudOption: boolean
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id: number): Promise<CampaignFraudStatsResponseOk | CampaignFraudStatsResponseError> {
  return axios.get(getUrl(id), {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignFraudStatsResponseMessageOk | CampaignFraudStatsResponseError>r.data;
  }).catch(e => {
    const resp: CampaignFraudStatsResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignFraudStatsResponseOk | CampaignFraudStatsResponseError): boolean {
  return typeof response.message !== 'string';
}