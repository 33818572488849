import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../components/Page';
import get, {
  TasksRequest,
  TasksResponseError,
  TasksResponseOk,
  TasksData,
  isResponseOK,
  recognizeTasksRequest,
} from '../../../api/eva/tasks/task.get';
import appContext from '../../../utils/context/appContext';
import TasksContext from '../../../utils/context/eva/tasksContext';
import { Link } from '@mui/material';
import MaterialDataTable from '../../../components/Eva/Tasks/MaterialDataTable';
import { Button } from 'reactstrap';
import { MdNewReleases } from 'react-icons/md';

const EvaTasksPage = function() {
    const [response, setResponse] = useState<TasksResponseOk>();
    const [data, setData] = useState<TasksData[]>([]);
    const [filter, setFilter] = useState<TasksRequest>(recognizeTasksRequest);
    const dataContext = useContext(appContext);

    const requestData = () => {
      dataContext.setIsLoading(true);
      get(filter).then(r => {
        if (!isResponseOK(r)) {
          r = r as TasksResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as TasksResponseOk;
        let tasksData = Object.values(r.message.data.data) as TasksData[];
        setData(tasksData);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    useEffect(() => {
      requestData();
    }, [filter]);

    return (<Page
      title='Задачи Евы'
    >
      <Link href={"/eva/alerts"}>
        <Button className={'mb-4'}>
          <MdNewReleases /> Открыть уведомления Евы
        </Button>
      </Link>

      <TasksContext.Provider
        value={{ requestData, response: response ?? null, filter }}>
        <MaterialDataTable tasks={data} />
      </TasksContext.Provider>
    </Page>);
  }
;

export default EvaTasksPage;
