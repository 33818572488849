import React, { useContext, useEffect, useState, useCallback } from 'react';
import Page from '../../components/Page';
import get, {
  OffersResponseError,
  OffersResponseOk,
  OffersData,
  CountriesData,
  CategoriesData,
  DomainsData,
  isResponseOK,
} from '../../api/offers/offers.get';
import appContext from '../../utils/context/appContext';
import MaterialDataTable from '../../components/Offers/MaterialDataTable';
import OfferPostModal from '../../components/Offers/OfferPostModal';
import { BiPlus } from 'react-icons/bi';


const OffersPage = function() {
    const [response, setResponse] = useState<OffersResponseOk>();
    const [offers, setOffers] = useState<OffersData[]>([]);
    const [countries, setCountries] = useState<CountriesData[]>([]);
    const [categories, setCategories] = useState<CategoriesData[]>([]);
    const [domains, setDomains] = useState<DomainsData[]>([]);
    const [isOpenOfferCreateModal, setIsOpenOfferCreateModal] = useState<boolean>(false);
    const [needUpdate, isNeedUpdate] = useState<boolean>(true);
    const dataContext = useContext(appContext);
    const [viewOfferCreator, setViewOfferCreator] = useState<boolean>(false);

    const requestData = () => {
      dataContext.setIsLoading(true);
      get().then(r => {
        if (!isResponseOK(r)) {
          r = r as OffersResponseError;
          dataContext.notify(r.message, 'error');
          return;
        }
        r = r as OffersResponseOk;
        setOffers(r.message.data);
        setCountries(r.message.countries);
        setCategories(r.message.categories);
        setDomains(r.message.domains);
        setViewOfferCreator(r.message.viewOfferCreator);
        setResponse(r);
      }).catch((e) => {
        dataContext.notify(e.message, 'error');
      }).finally(() => {
        dataContext.setIsLoading(false);
      });
    };

    const wrapperNeedUpdate = useCallback(() => {
      isNeedUpdate(true);
    }, [isNeedUpdate]);

    useEffect(() => {
      isNeedUpdate(false);
      requestData();
    }, [needUpdate]);



    return (<Page
      className='Offers'
      title='Офферы'
    >
      <OfferPostModal isOpen={isOpenOfferCreateModal} domains={domains} countries={countries} categories={categories}
                      toggle={() => setIsOpenOfferCreateModal((prev) => !prev)} wrapperNeedUpdate={wrapperNeedUpdate}/>
      <button type={'button'} onClick={() => setIsOpenOfferCreateModal(true)} className={'ml-2 btn btn-sm btn-primary'}>
        Добавить
      </button>
      <MaterialDataTable offers={offers} response={response} wrapperNeedUpdate={wrapperNeedUpdate} domains={domains} countries={countries} categories={categories} viewOfferCreator={viewOfferCreator}/>
    </Page>);
  }
;


export default OffersPage;

