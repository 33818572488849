import React, { ComponentProps, useContext, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import appContext from '../../../utils/context/appContext';
import { DataColumnsRealtimeBlacklist } from '../../../utils/realtime-blacklist/datatable';
import { CampaignRealtimeBlacklistStat } from '../../../api/campaigns/campaigns.realtime-blacklist.get';
import AiBlackListImg from '../../../assets/img/AI_BLACK_LIST.png';
import AiWhiteListImg from '../../../assets/img/AI_WHITE_LIST.png';
import IgnoreAI from '../../../assets/img/black_ignore.png';
import SleepImg from '../../../assets/img/sleep.png';
import AiPausedImg from '../../../assets/img/AI_PAUSED.png';
import AiNoTraffImg from '../../../assets/img/AI_NO_TRAFF.png';
import AiReservedImg from '../../../assets/img/AI_RESERVED.png';
import AiBannedCpaImg from '../../../assets/img/AI_CPA.png';
import AiBannedCpcImg from '../../../assets/img/AI_CPC.png';
import ManagerWhiteListImg from '../../../assets/img/white_domain_id.png';
import ManagerBlackListImg from '../../../assets/img/block.png';
import { CampaignMinuteStatsResponseError } from '../../../api/campaigns/campaign.minute-stats.get';
import patch, { isResponseOK } from '../../../api/campaigns/campaigns.bundle.patch';
import TableCellRaw from '@mui/material/TableCell';
import { Tooltip } from '@mui/material';


export default function(props: ComponentProps<any> & { row: CampaignRealtimeBlacklistStat, campaignId: number }) {
    const dataContext = useContext(appContext);
    const { columns, row } = props;
    const [rate, setRate] = useState<string>(String(Math.round(row.koef * props.campaignData.rate * 100) / 100));
    const [limitDaily, setLimitDaily] = useState<string>(row.limitSpent ?? '');
    const [rateChanged, setRateChanged] = useState<boolean>(false);
    const [limitDailyChanged, setLimitDailyChanged] = useState<boolean>(false);

    function filterNumber(val: string): string {
        return val.replace(/[^0-9.]/g, '');
    }

    function changeRate() {
        patch(props.campaignId, {
            app: row.isApp ? 1 : 0,
            domainId: row.domainId,
            tagid: row.tagid,
            systemId: row.systemId,
            data: {
                rate: rate === '' ? null : Number(rate),
            },
        }).then(r => {
            if (!isResponseOK(r)) {
                r = r as CampaignMinuteStatsResponseError;
                dataContext.notify(r.message, 'error');
                return;
            }
            setRateChanged(false);
            dataContext.notify(`Ставка связки успешно изменена`, 'success');
        });
    }

    function changeLimitDaily() {
        patch(props.campaignId, {
            app: row.isApp ? 1 : 0,
            domainId: row.domainId,
            tagid: row.tagid,
            systemId: row.systemId,
            data: {
                dailyBudget: limitDaily === '' ? null : Number(limitDaily),
            },
        }).then(r => {
            if (!isResponseOK(r)) {
                r = r as CampaignMinuteStatsResponseError;
                dataContext.notify(r.message, 'error');
                return;
            }
            setLimitDailyChanged(false);
            dataContext.notify(`Дневной лимит связки успешно изменен`, 'success');
        });
    }

    function showStatusIcon(row: CampaignRealtimeBlacklistStat) {
        if (row.groupId === 0 && row.banned === 'ai' && row.list === 'white')
            return (
                    <Tooltip title='В вайтлисте по автоправилу' arrow>
                        <img width={20} src={AiWhiteListImg} alt='white' />
                    </Tooltip>
            );
        else if (row.groupId === 0 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='В блэклисте по автоправилу' arrow>
                        <img width={20} src={AiBlackListImg} alt='AiBlackList' />
                    </Tooltip>
            );
        else if (row.groupId === 1 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='На паузе (в блэклисте) по автоправилу autocpa' arrow>
                        <img width={20} src={SleepImg} alt='PAUSED' />
                    </Tooltip>
            );
        else if (row.groupId === 2 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='В статусе нет трафика (в блэклисте) по автоправилу autocpa' arrow>
                        <img width={20} src={AiNoTraffImg} alt='NO TRAFF' />
                    </Tooltip>
            );
        else if (row.groupId === 3 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='На резервации (в блэклисте) по автоправилу autocpa' arrow>
                        <img width={20} src={AiReservedImg} alt='на резервации' />
                    </Tooltip>
            );
        else if (row.groupId === 4 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='В блэклисте по автоправилу autocpa' arrow>
                        <img width={20} src={AiBannedCpaImg} alt='AiBannedCpa' />
                    </Tooltip>
            );
        else if (row.groupId === 5 && row.banned === 'ai' && row.list === 'black')
            return (
                    <Tooltip title='В блэклисте по автоправилу autocpc' arrow>
                        <img width={20} src={AiBannedCpcImg} alt='AiBannedCpc' />
                    </Tooltip>
            );
        else if (row.groupId === 0 && row.banned === 'manager' && row.list === 'none')
            return ('');
            // return (
            //         <Tooltip title='Без статуса (статус none)' arrow>
            //             <p>&nbsp;</p>
            //         </Tooltip>
            // );//тут умышленно пусто
        else if (row.groupId === 0 && row.banned === 'ai' && row.list === 'none')
          return ('');
            // return (
            //         <Tooltip title='Без статуса, источник возвращен автоправилом' arrow>
            //             <p>&nbsp;</p>
            //         </Tooltip>
            // );//тут умышленно пусто
        else if (row.banned === null && row.list === null)
            return ('');
            // return (
            //         <Tooltip title='Без статуса - данные по источнику получены из детальной статистики' arrow>
            //             <p>&nbsp;</p>
            //         </Tooltip>
            // );//тут умышленно пусто
        else if (row.banned === 'manager' && row.list === 'white')
            return (
                    <Tooltip title='Источник добавлен в вайтлист менеджером' arrow>
                        <img width={20} src={ManagerWhiteListImg} alt='white' />
                    </Tooltip>
            );
        else if (row.banned === 'manager' && row.list === 'black')
            return (
                    <Tooltip title='Источник добавлен в блэклист менеджером' arrow>
                        <img width={20} src={ManagerBlackListImg} alt='ManagerBlackList' />
                    </Tooltip>
            );
        else
            return (
                    <Tooltip title='Источник находится в неизвестном некорректном статусе!' arrow>
                        <p>?</p>
                    </Tooltip>
            );
    }
    function showIgnoreStatusIcon(row: CampaignRealtimeBlacklistStat) {
      if(row.ignoreAi.val === true)
        return (
          <Tooltip title='Игнорируется автоправилами' arrow>
            <img width={20} src={IgnoreAI} alt='IgnoreAI' />
          </Tooltip>
        );
    }
    return (<React.Fragment>
        <TableRow
                onClick={() => {
                    props.setRowsChosen((prevRows: string[] | undefined) => {
                        let rows: string[] = [];
                        if (prevRows !== undefined) {
                            rows = [...prevRows];
                        }
                        const ind = rows.indexOf(props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                        if (~ind) {
                            rows = rows.filter(val => val != props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                        } else {
                            rows.push(props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                        }
                        return rows;
                    });
                }}
                style={{ background: (props.rowsChosen.includes(props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100)) ? 'aliceblue' : '#fff') }}
                key={props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100)}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCellRaw className={'sticky-width-50'}></TableCellRaw>
            <TableCell columns={columns} className={'sticky-width-70 text-break'}
                       targetColumn={DataColumnsRealtimeBlacklist.TYPE}>{row.isApp ? 'app' : 'domain'}</TableCell>
            <TableCell columns={columns} className={'sticky-width-110 text-break'}
                       targetColumn={DataColumnsRealtimeBlacklist.SYSTEM}>{row.system}</TableCell>
            <TableCell columns={columns} className={'sticky-width-110 text-break'}
                       targetColumn={DataColumnsRealtimeBlacklist.PUBLISHER}>{row.publisher}</TableCell>
            <TableCell columns={columns} className={'sticky-width-110'}
                       targetColumn={DataColumnsRealtimeBlacklist.DOMAIN_ID}>{row.domainId}</TableCell>
            <TableCell columns={columns} className={'sticky-width-110 text-break'}
                       targetColumn={DataColumnsRealtimeBlacklist.DOMAIN}>{row.domain}</TableCell>
            <TableCell columns={columns} className={'sticky-width-150 text-break'} targetColumn={DataColumnsRealtimeBlacklist.TAG}
                       sx={{ maxWidth: '150px', wordBreak: 'break-all' }}>{row.tagid}</TableCell>
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.IMPS}>{row.reqs}</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.IMPS_VARIANCE}>
        {(row.reqs - (row.variance?.reqs ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.reqs ?? 0) > 0 && row.reqs !== row.variance?.reqs ? '(' + (((row.reqs - row.variance?.reqs) / row.variance?.reqs) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.HITS}>{row.hits}</TableCell>
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.HITREAL}>{row.hitreal}</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.HITREAL_VARIANCE}>
        {(row.hitreal - (row.variance?.hitreal ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.hitreal ?? 0 > 0) && row.hitreal !== row.variance?.hitreal ? '(' + (((row.hitreal - row.variance?.hitreal) / row.variance?.hitreal) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.CLICKS}>{row.clicks}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.VIEWS_PROC}>{row.viewProc}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CTR}>{row.ctr}%</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.CTR_VARIANCE}>
        {(row.ctr - (row.variance?.ctr ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.ctr ?? 0) > 0 && row.ctr !== row.variance?.ctr ? '(' + (((row.ctr - row.variance?.ctr) / row.variance?.ctr) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.VTR}>{row.winRateProc}</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.VTR_VARIANCE}>
        {(row.winRateProc - (row.variance?.winRateProc ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.winRateProc ?? 0) > 0 && row.winRateProc !== row.variance?.winRateProc ? '(' + (((row.winRateProc - row.variance?.winRateProc) / row.variance?.winRateProc) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.LIMIT_SPENT}>
                <div style={{ position: 'relative' }}>
                    <div>
                        <input type='text' className={'p-1 w-100'}
                               onClick={(e) => e.stopPropagation()}
                               onKeyDown={(e) => {
                                   props.setRowsChosen((prevRows: string[] | undefined) => {
                                     let rows: string[] = [];
                                     if (prevRows !== undefined) {
                                       rows = [...prevRows];
                                     }
                                     const ind = rows.indexOf(props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                                     if (~ind) {
                                       rows = rows.filter(val => val != props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                                     }
                                     return rows;
                                   });
                                   if (e.key !== 'Enter') {
                                       return;
                                   }
                                   changeLimitDaily();
                               }}
                               value={limitDaily}
                               onChange={(e) => {
                                   setLimitDaily(filterNumber(e.target.value));
                                   if(String(e.target.value) === String(props.row.limitSpent) || (String(e.target.value) === '' && props.row.limitSpent === null)){
                                     setLimitDailyChanged(false);
                                   }else{
                                     setLimitDailyChanged(true);
                                   }

                               }} />
                    </div>
                    <div>
                        <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeLimitDaily();
                                }}
                                style={{ top: '0' }}
                                className={`dynamic-save-changes btn-sm btn-success ${limitDailyChanged ? 'active' : ''}`}>сохранить
                        </button>
                    </div>
                </div>
            </TableCell>
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.COSTS}>{row.spent}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CPM}>{row.cpm}</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.CPM_VARIANCE}>
        {(row.cpm - (row.variance?.cpm ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.cpm ?? 0) > 0 && row.cpm !== row.variance?.cpm ? '(' + (((row.cpm - row.variance?.cpm) / row.variance?.cpm) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CPC}>{row.cpc}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.PDP}>{row.leads}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CR}>{row.cr}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CR_PROC}>{row.crProc}</TableCell>
            <TableCell columns={columns} className={'sticky-width-70'}
                       targetColumn={DataColumnsRealtimeBlacklist.CPA}>{row.cpa}</TableCell>
            {/*<TableCell columns={columns}
                 targetColumn={DataColumnsRealtimeBlacklist.CPA_VARIANCE}>
        {(row.cpa - (row.variance?.cpa ?? 0)).toFixed(2)}
        <br />
        {(row.variance?.cpa ?? 0) > 0 && row.cpa !== row.variance?.cpa ? '(' + (((row.cpm - row.variance?.cpa) / row.variance?.cpa) * 100).toFixed(2) + '%)' : ''}
      </TableCell>*/}
            <TableCell columns={columns} className={'sticky-width-90'}
                       targetColumn={DataColumnsRealtimeBlacklist.BIDFLOOR_INTERVAL}>{`${row.bidfloor.min} - ${row.bidfloor.max}`}</TableCell>
            <TableCell columns={columns}
                       targetColumn={DataColumnsRealtimeBlacklist.CONV_COST}>{row.convCost}</TableCell>
            {/*<TableCell columns={columns} targetColumn={DataColumnsRealtimeBlacklist.KOEF}>{row.koef}</TableCell>*/}
            <TableCell className={'sticky-width-90 p-1'} columns={columns}
                       targetColumn={DataColumnsRealtimeBlacklist.RATE}>
                <div style={{ position: 'relative' }}>
                    <div>
                        <input type='text' className={'p-1 w-100'}
                               onClick={(e) => e.stopPropagation()}
                               onKeyDown={(e) => {
                                 props.setRowsChosen((prevRows: string[] | undefined) => {
                                   let rows: string[] = [];
                                   if (prevRows !== undefined) {
                                     rows = [...prevRows];
                                   }
                                   const ind = rows.indexOf(props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                                   if (~ind) {
                                     rows = rows.filter(val => val != props.row.domainId + '%%' + props.row.tagid + '%%' + props.row.systemId + '%%' + props.row.isApp + '%%' + (props.row.limitSpent ?? '') + '%%' + String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100));
                                   }
                                   return rows;
                                 });
                                   if (e.key !== 'Enter') {
                                       return;
                                   }
                                   changeRate();
                               }}
                               value={rate}
                               onChange={(e) => {
                                   setRate(filterNumber(e.target.value));
                                   if(e.target.value !== String(Math.round(props.row.koef * props.campaignData.rate * 100) / 100)){
                                     setRateChanged(true);
                                   }else{
                                     setRateChanged(false);
                                   }
                               }} />
                    </div>
                    <div>
                        <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeRate();
                                }}
                                style={{ top: '0' }}
                                className={`dynamic-save-changes btn-sm btn-success ${rateChanged ? 'active' : ''}`}>сохранить
                        </button>
                    </div>
                </div>
            </TableCell>
            <TableCell className={'sticky-width-70'} columns={columns}
                       targetColumn={DataColumnsRealtimeBlacklist.LISTS}>
                {showStatusIcon(row)} {showIgnoreStatusIcon(row)}
            </TableCell>
            <TableCell className={'sticky-width-70'} columns={columns}
                       targetColumn={DataColumnsRealtimeBlacklist.MIN}>min</TableCell>
        </TableRow>
    </React.Fragment>);
}