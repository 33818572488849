import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, Paper, TableFooter, ThemeProvider } from '@mui/material';
import TableCellRaw from '@mui/material/TableCell';
import { OffersData } from '../../api/offers/offers.get';
import { BiPencil, BiTrash } from 'react-icons/bi';
import OfferEditModal from './OfferEditModal';
import deleteOffer, { isResponseOK as isDeleteResponseOk, OffersDeleteResponseError } from '../../api/offers/offers.delete';
import appContext from '../../utils/context/appContext';
import OfferStatistic from './OfferStatistic';
import {
  MdNewReleases
} from 'react-icons/md';
import { Tooltip } from 'reactstrap';


const theme = createTheme({
  components: {},
});


export default function(props: ComponentProps<any> & { offers: OffersData[], wrapperNeedUpdate: () => {}, domains: [], categories: [], countries: [], viewOfferCreator: boolean }) {
  const dataContext = useContext(appContext);
  const [data, setData] = useState<Array<OffersData>>([]);
  const [editionOffer, setEditOffer] = useState<OffersData>(props.offers[0]);
  const [isOpenOfferEditModal, setIsOpenOfferEditModal] = useState<boolean>(false);



  useEffect(() => {
    setData(props.offers);
  }, [props.offers]);

  function editOffer(data: OffersData) {
    setEditOffer(data);
    setIsOpenOfferEditModal(true);
  }

  function removeOffer(id: number) {
    dataContext.setIsLoading(true);
    deleteOffer(id).then(r => {
      if (!isDeleteResponseOk(r)) {
        r = r as OffersDeleteResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      props.toggle();
    }).finally(() => {
      dataContext.setIsLoading(false);
      props.wrapperNeedUpdate();
    });
  }


  return (<TableContainer component={Paper}>
    <OfferEditModal offer={editionOffer} isOpen={isOpenOfferEditModal}  wrapperNeedUpdate={props.wrapperNeedUpdate}  domains={props.domains} countries={props.countries} categories={props.categories}
                    toggle={() => setIsOpenOfferEditModal((prev) => !prev)} />
    <ThemeProvider theme={theme}>
      <Table id={'offers-table'} sx={{ minWidth: 650 }} aria-label='offers'>
        <TableHead>
          <TableRow>
            <TableCellRaw>id</TableCellRaw>
            <TableCellRaw>Наименование</TableCellRaw>
            <TableCellRaw>Стоимость конверсии</TableCellRaw>
            <TableCellRaw>Домен</TableCellRaw>
            {props.viewOfferCreator &&
              <TableCellRaw>Пользователь</TableCellRaw>
            }
            <TableCellRaw>Страна</TableCellRaw>
            <TableCellRaw>Категория</TableCellRaw>
            <TableCellRaw>Дневной лимит лидов</TableCellRaw>
            <TableCellRaw>Действия</TableCellRaw>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((offer) => (
            <OfferRows offer={offer} wrapperNeedUpdate={props.wrapperNeedUpdate} viewOfferCreator={props.viewOfferCreator}></OfferRows>
          ))}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
    </ThemeProvider>
  </TableContainer>);


  function OfferRows(props: { offer: OffersData, wrapperNeedUpdate: () => {}, viewOfferCreator: boolean}) {
    const [statistic, setStatistic] = useState<boolean>(false);
    const [isTooltip, setIsTooltip] = useState<boolean>(false);
    const offer = props.offer;
    const getOfferStatistic = (id: number) => {
      if(statistic){
        setStatistic(false);
      }else {
        setStatistic(true);
      }
    }

    return (
      <React.Fragment>
        <TableRow key={offer.id} onClick={(e) => {
          e.preventDefault();
          getOfferStatistic(offer.id);
        }}>
          <TableCellRaw>{offer.id}</TableCellRaw>
          <TableCellRaw>
            {offer.problem.length > 0 &&
              <><MdNewReleases color='red' fontSize='1.5em' id={'ICON' + offer.id}></MdNewReleases><Tooltip
                placement='top' isOpen={isTooltip} target={'ICON' + offer.id}
                toggle={() => setIsTooltip(prev => !prev)}>
                Большие изменения по:
                {offer.problem.map((p) => (
                  p+"\t"
                ))}
              </Tooltip></>
            }

            {offer.name}
          </TableCellRaw>
          <TableCellRaw>{offer.thresholdConv}</TableCellRaw>
          <TableCellRaw>{offer.domain.name}</TableCellRaw>
          {props.viewOfferCreator &&
            <TableCellRaw>{offer.user.login}</TableCellRaw>
          }
          <TableCellRaw>{offer.country.name}</TableCellRaw>
          <TableCellRaw>{offer.category.name}</TableCellRaw>
          {offer.checkDayLimit &&
            <TableCellRaw>{offer.dailyLimit}</TableCellRaw>
          }
          {!offer.checkDayLimit &&
            <TableCellRaw>Нет</TableCellRaw>
          }
          <TableCellRaw>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                editOffer(offer);
              }}
              href={''}
              title={'Редактировать'} target={'_blank'}>
              <BiPencil size={19} />
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (confirm('Вы действительно хотите удалить Оффер?')) {
                  removeOffer(offer.id);
                }
              }}
              href={''}
              title={'Удалить'} target={'_blank'}>
              <BiTrash size={19} />
            </a>
          </TableCellRaw>
        </TableRow>
        {statistic &&
          <TableRow>
            <TableCellRaw colSpan={9}><OfferStatistic offerId={offer.id} statistic={statistic}/></TableCellRaw>
          </TableRow>
        }
      </React.Fragment>
    );
  }
}