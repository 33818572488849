import React, { ChangeEvent, ComponentProps, useContext, useEffect, useMemo, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DataColumns } from '../../utils/campaigns/datatable';
import appContext from '../../utils/context/appContext';
import update, { isResponseOK, sections, UserColumnResponseError } from '../../api/preferences/user-columns.put';
import pageContext from '../../utils/context/realtimeBlacklistContext';
import { MdClose } from 'react-icons/md';
import { DataColumnsRealtimeBlacklist } from '../../utils/realtime-blacklist/datatable';

export default function(props: ComponentProps<any> & { toggle: () => {}, isOpen: boolean, className: string, headers: Array<string> }) {
  const context = useContext(pageContext);
  const dataContext = useContext(appContext);
  const [availableColumns, setAvailableColumns] = useState<Array<string>>(props.headers ?? []);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(localStorage.getItem("realtimeblacklist-page.filter.show_additional") !== null);
  const [showBlackListActions, setShowBlackListActions] = useState<boolean>(localStorage.getItem("realtimeblacklist-page.interface.show_list_buttons") !== null);
  const [showIgnoreAiActions, setShowIgnoreAiActions] = useState<boolean>(localStorage.getItem("realtimeblacklist-page.interface.show_ignore_ai_buttons") !== null);
  useEffect(() => {
    if (!showAdditionalFilters ) {
      localStorage.removeItem("realtimeblacklist-page.filter.show_additional")
      return;
    }
    localStorage.setItem("realtimeblacklist-page.filter.show_additional", "1")
  }, [showAdditionalFilters]);
  useEffect(() => {
    if (!showBlackListActions ) {
      localStorage.removeItem("realtimeblacklist-page.interface.show_list_buttons")
      return;
    }
    localStorage.setItem("realtimeblacklist-page.interface.show_list_buttons", "1")
  }, [showBlackListActions]);
  useEffect(() => {
    if (!showIgnoreAiActions) {
      localStorage.removeItem("realtimeblacklist-page.interface.show_ignore_ai_buttons")
      return;
    }
    localStorage.setItem("realtimeblacklist-page.interface.show_ignore_ai_buttons", "1")
  }, [showIgnoreAiActions]);
  const columnsSeparated = useMemo(() => {
    return () => {
      const columns = DataColumnsRealtimeBlacklist.getAllInString();
      const columnsSeparated: Array<Array<string>> = [];
      let indexToPush = 0;

      for (let i = 0; i < columns.length; i++) {
        if (i % 12 === 0) {
          indexToPush = columnsSeparated.push([]) - 1;
        }
        columnsSeparated[indexToPush].push(columns[i]);
      }
      return columnsSeparated;
    };
  }, [availableColumns]);

  function onChangeInput(e: ChangeEvent<HTMLInputElement> | undefined) {
    if (!e) return;
    const target = e.currentTarget;

    setAvailableColumns((prev) => {
      if (target.checked) {
        return [...new Set([...prev, target.value])];
      }

      return prev.filter((val) => {
        return val !== target.value;
      });
    });
  }

  function saveNewColumns() {
    dataContext.setIsLoading(true);
    update(sections.REALTIME_BLACKLIST, { columns: availableColumns })
      .then(r => {
        if (!isResponseOK(r)) {
          r = r as UserColumnResponseError;
          dataContext.notify(r.message, 'error');
        }
      }).catch(r => {
      dataContext.notify(r.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
      context.requestData();
    });
  }

  return <Modal
    size={'lg'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Настройки</ModalHeader>
    <ModalBody>
      <h2>Интерфейс страницы</h2>
      <div className='col-4'>
        <FormGroup check>
          <Input
            id={'realtimeblacklist-page.filter.show_additional'}
            name='check'
            type='checkbox'
            onChange={() => {
              setShowAdditionalFilters(prev => {
                return !prev;
              })
            } }
            checked={showAdditionalFilters}
          />
          <Label check for={'realtimeblacklist-page.filter.show_additional'}>
            Дополнительные фильтры
          </Label>
        </FormGroup>
      </div>
      <div className='col-4'>
        <FormGroup check>
          <Input
            id={'realtimeblacklist-page.interface.show_list_buttons'}
            name='check'
            type='checkbox'
            onChange={() => {
              setShowBlackListActions(prev => {
                return !prev;
              })
            } }
            checked={showBlackListActions}
          />
          <Label check for={'realtimeblacklist-page.interface.show_list_buttons'}>
            Действия с блэклистом
          </Label>
        </FormGroup>
      </div>
      <div className='col-4'>
        <FormGroup check>
          <Input
            id={'realtimeblacklist-page.interface.show_ignore_ai_buttons'}
            name='check'
            type='checkbox'
            onChange={() => {
              setShowIgnoreAiActions(prev => {
                return !prev;
              })
            } }
            checked={showIgnoreAiActions}
          />
          <Label check for={'realtimeblacklist-page.interface.show_ignore_ai_buttons'}>
            Действия с автоправилами
          </Label>
        </FormGroup>
      </div>
      <h2>Интерфейс таблицы</h2>
      <form className='row'>
        {
          columnsSeparated().map((arr: Array<string>, index) => {
            return (<div className='col-4' key={index}>
              {arr.map((value: string) => {
                return (<FormGroup check key={value}>
                  <Input
                    id={'tablecolumns-' + value}
                    name='check'
                    type='checkbox'
                    value={value}
                    onChange={(e) => onChangeInput(e)}
                    checked={availableColumns.includes(value)}
                  />
                  <Label check for={'tablecolumns-' + value}>
                    {DataColumnsRealtimeBlacklist.getName(DataColumnsRealtimeBlacklist.get(value) as DataColumnsRealtimeBlacklist)}
                  </Label>
                </FormGroup>);
              })}
            </div>);
          })
        }
      </form>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        saveNewColumns();
        props.toggle();
      }}>
        Сохранить
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}