import { useEffect, useState } from 'react';

export default function (callback: any, deps: any) {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};
