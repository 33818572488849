import axios from 'axios';
import { apiKey } from '../apiKey';
import { CampaignLists, CampaignStatus } from './campaigns.get';

const PATH_URL = '/campaigns/{id}';

export type CampaignPatchRequest = {
  autoCpaEnable?: 1 | 0 | null,
  desiredCpa?: number,
  smartbidEnable?: 1 | 0 ,
  retestEnable?: 1 | 0 ,
  reservationEnable?: 1 | 0 ,
  maxSpentCpaWithoutLeads?: number,
  packsToAdd?: number,
  status?: CampaignStatus,
  restrictionType?: CampaignLists,
  limitDaily?: number,
  limitCommon?: number,
}

export type CampaignPatchResponseOk = {
  'status': number,
  'message': CampaignPatchResponseMessageOk,
}

export type CampaignPatchResponseMessageOk = {
  data: {
    info: string
  },
}

export type CampaignPatchResponseError = {
  'status': number,
  'message': CampaignPatchResponseMessageError,
}

export type CampaignPatchResponseMessageError = string


// @ts-ignore
const key = new apiKey();


export default function patch(id: number, request: CampaignPatchRequest): Promise<CampaignPatchResponseOk | CampaignPatchResponseError> {
  return axios.patch(getUrl(id), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignPatchResponseOk | CampaignPatchResponseError>r.data;
  }).catch(e => {
    const resp: CampaignPatchResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id.toString());
}

export function isResponseOK(response: CampaignPatchResponseOk | CampaignPatchResponseError): boolean {
  return typeof response.message !== 'string';
}