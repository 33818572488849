import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import patch, {
  CampaignPatchRequest,
  CampaignPatchResponseError,
  isResponseOK as isPatchResponseOk,
} from '../../api/campaigns/campaigns.patch';
import getSettings, {
  CampaignAutoCpaSettings,
  CampaignAutoCpaSettingsResponseError, CampaignAutoCpaSettingsResponseOk,
  isResponseOK,
} from '../../api/campaigns/campaign.autocpa-settings.get';

export default function(props: ComponentProps<any> & { toggle: () => {}, campaignId: number, isOpen: boolean }) {
  const dataContext = useContext(appContext);
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);
  const [cpa, setCpa] = useState<string>('');
  const [packsToAdd, setPacksToAdd] = useState<number>(25);
  const [maxSpentCpaWithoutLeads, setMaxSpentCpaWithoutLeads] = useState<number>(25);
  const [smartbidEnable, setSmartbidEnable] = useState<boolean>(false);
  const [reservationEnable, setReservationEnable] = useState<boolean>(false);
  const [retestEnable, setRetestEnable] = useState<boolean>(false);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    getCpaSettings();
  }, [props.isOpen]);

  const fillCpaSettings = (data: CampaignAutoCpaSettings) => {
    if (data === null) {
      setSettingsEnabled(false);
      return;
    }
    setSettingsEnabled(true);
    setCpa(String(data.cpa));
    setPacksToAdd(data.packsToAdd);
    setMaxSpentCpaWithoutLeads(data.maxSpentCpaWithoutLeads);
    setSmartbidEnable(data.smartbidEnable);
    setRetestEnable(data.retestEnable);
    setReservationEnable(data.reservationEnable);
  };

  const getCpaSettings = () => {
    getSettings(props.campaignId).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignAutoCpaSettingsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignAutoCpaSettingsResponseOk;
      fillCpaSettings(r.message.data);
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };


  const updateSettings = () => {
    function buildRequest(): CampaignPatchRequest {
      if (!settingsEnabled) {
        return {
          autoCpaEnable: 0
        }
      }
      return {
        autoCpaEnable: 1,
        desiredCpa: Number(cpa),
        smartbidEnable: smartbidEnable ? 1 : 0,
        retestEnable: retestEnable ? 1 : 0,
        reservationEnable: reservationEnable ? 1 : 0,
        maxSpentCpaWithoutLeads: maxSpentCpaWithoutLeads,
        packsToAdd: packsToAdd,
      }
    }

    patch(props.campaignId, buildRequest()).then(r => {
      if (!isPatchResponseOk(r)) {
        r = r as CampaignPatchResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      props.toggle();
    });
  };

  function updateCpa(value: string): void {
    value = value.replaceAll(',', '.');
    value = value.replaceAll(/[^0-9.,]/g, '')
    setCpa(value);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}>
    <ModalBody>
      <h3 className='h5 text-center'>
        <b>Auto CPA Настройки кампании {props.campaignId}</b>
      </h3>
      <div className={'text-left'}>
        <label className={'d-flex'} htmlFor='autocpaEnabled'>
          <div className={'col-md-8 text-left'}>АвтоCPA</div>
          <input id={'autocpaEnabled'} onChange={() => setSettingsEnabled((prev) => !prev)} type='checkbox'
                 checked={settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='retestEnable'>
          <div className={'col-md-8 text-left'}>Смартбид</div>
          <input id={'smartbidEnable'} onChange={() => setSmartbidEnable((prev) => !prev)} type='checkbox'
                 checked={smartbidEnable} disabled={!settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='reservationEnable'>
          <div className={'col-md-8 text-left'}>Резервация</div>
          <input id={'reservationEnable'} onChange={() => setReservationEnable((prev) => !prev)} type='checkbox'
                 checked={reservationEnable} disabled={!settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='retestEnable'>
          <div className={'col-md-8 text-left'}>Ретест</div>
          <input id={'retestEnable'} onChange={() => setRetestEnable((prev) => !prev)} type='checkbox'
                 checked={retestEnable} disabled={!settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='autocpaCpa'>
          <div className={'col-md-8 text-left'}>Желаемый Cpa</div>
          <input className={'form-control col-md-4'} id={'autocpaCpa'} onChange={e => updateCpa(e.target.value)}
                 type='text' value={cpa} disabled={!settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='autocpaPacksToAdd'>
          <div className={'col-md-8 text-left'}>Кол-во связок добавлять за раз</div>
          <input className={'form-control col-md-4'} id={'autocpaPackToAdd'}
                 onChange={e => setPacksToAdd(Number(e.target.value))} type='number' value={packsToAdd} step={1}
                 disabled={!settingsEnabled} />
        </label>
        <label className={'d-flex'} htmlFor='autocpaMaxSpent'>
          <div className={'col-md-8 text-left'}>Сколько желаемых CPA потратить, чтобы выключить кампанию без
            конверсий
          </div>
          <input className={'form-control col-md-4'} id={'autocpaMaxSpent'}
                 onChange={e => setMaxSpentCpaWithoutLeads(Number(e.target.value))} type='number'
                 value={maxSpentCpaWithoutLeads} step={1} disabled={!settingsEnabled} />
        </label>
      </div>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        updateSettings();
      }}>
        Сохранить
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}