//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';
import { CampaignRequest, createCampaignRequest } from './campaigns.get';

const PATH_URL = '/campaigns/{id}/autocpa/add-bundles';

export type AutoCpaAddBundlesRequest = {
  bundles: number
}

export function createRequest(): AutoCpaAddBundlesRequest {
  const date = new Date();
  return { startDate: formatDate(date), endDate: formatDate(date) };
}

export type AutoCpaAddBundlesResponseOk = {
  'status': number,
  'message': AutoCpaAddBundlesResponseMessageOk,
}

export type AutoCpaAddBundlesResponseMessageOk = {
  success: true
}

export type AutoCpaAddBundlesResponseError = {
  'status': number,
  'message': AutoCpaAddBundlesResponseMessageError,
}

export type AutoCpaAddBundlesResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function post(id: number, request: AutoCpaAddBundlesRequest): Promise<AutoCpaAddBundlesResponseOk | AutoCpaAddBundlesResponseError> {
  const url = PATH_URL.replace('{id}', id);
  return axios.post(process.env.REACT_APP_API_URL + url, request, {
    headers: {
      apiKey: key.get()
    },
  }).then(r => {
    return <AutoCpaAddBundlesResponseOk | AutoCpaAddBundlesResponseError>r.data;
  }).catch(e => {
    const resp: AutoCpaAddBundlesResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: AutoCpaAddBundlesResponseOk | AutoCpaAddBundlesResponseError): boolean {
  return typeof response.message === 'object';
}