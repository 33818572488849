//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/offers';

export type OffersPostResponseOk = {
  'status': number,
  'message': OffersPostResponseMessageOk,
}

export type OffersPostRequest = {
  name: string,
  domain: any,
  url: string[],
  conv: number,
  country: string,
  category: number,
  gmt: number,
  limit: number,
  checkDayLimit: number
}

export type OffersPostResponseMessageOk = {
  data: OfferPost,
}

export type OfferPost = {
  offer: {
    id: number
  }
}

export type OffersPostResponseError = {
  'status': number,
  'message': OffersPostResponseMessageError,
}

export type OffersPostResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function post(request: OffersPostRequest): Promise<OffersPostResponseOk | OffersPostResponseError> {
  return axios.post(getUrl(), request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <OffersPostResponseOk | OffersPostResponseError>r.data;
  }).catch(e => {
    const resp: OffersPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

function getUrl(): string {
  return (process.env.REACT_APP_API_URL + PATH_URL);
}

export function isResponseOK(response: OffersPostResponseOk | OffersPostResponseError): boolean {
  return typeof response.message !== 'string';
}