import React, { ComponentProps, useContext, useState, useTransition } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import dashboardContext from '../../utils/context/dashboardContext';
import patch, {
  isResponseOK,
  TelegramChatPatchResponseError,
  TelegramChatPatchResponseOk,
} from '../../api/preferences/telegram-chat.patch';

export default function(props: ComponentProps<any> & { toggle: () => {}, isOpen: boolean, className: string }) {
  const dataContext = useContext(appContext);
  const pageContext = useContext(dashboardContext);
  const [chatid, setChatid] = useState<number>(0);
  const [isPending, startTransition] = useTransition();


  function updateTelegramChatId() {
    if (!chatid) {
      alert('Введите Personal ID');
      return;
    }
    dataContext.setIsLoading(true);
    patch({ chatid })
      .then(r => {
        if (!isResponseOK(r)) {
          r = r as TelegramChatPatchResponseError;
          dataContext.notify(r.message, 'error');
          dataContext.setIsLoading(false);
          return;
        }
        r = r as TelegramChatPatchResponseOk;
        dataContext.notify('Telegram Chat id обновлён', 'success');
      }).catch(r => {
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalBody>
      <h3 className='h5 text-center'>
        <b>Настройка уведомлений</b>
      </h3>
      <p className={'text-center'}>
        Чтобы узнать Personal ID нужно написать в телеграмме боту <br />
        <b>@userinfobot</b>
      </p>
      <div className='d-flex justify-content-center align-items-center mt-2'>
        <span>Personal&nbsp;ID</span>
        <input className={'form-control ml-1'} onInput={(e) => {
          startTransition(() => setChatid(parseInt(e.currentTarget.value)));
        }} type='text' />
      </div>
      <p className={'mt-2'}>
        Чтобы уведомления начали приходить, нужно написать <b>/start</b> боту <b>@TradingDeskMetrics_bot</b>
      </p>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        updateTelegramChatId();
        props.toggle();
      }}>
        Сохранить
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
  ;
  ;
}