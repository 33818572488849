import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import appContext from '../../utils/context/appContext';
import SourcesModal from '../../components/Analytics/SourcesModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import get, {
  CampaignAnalyticsAbsoluteIndicatorRequest,
  CampaignAnalyticsAbsoluteIndicatorResponseError,
  CampaignAnalyticsAbsoluteIndicatorResponseOk,
  CampaignAnalyticsAbsoluteIndicatorTags,
  isResponseOK,
  recognizeCampaignAnalyticsRequest,
} from '../../api/campaigns/campaigns.analytics-absolute-indicator.get';
import CreatableSelect from 'react-select/creatable';
import GrafanaIcon from '../../assets/img/grafana_icon.png';
import { SelectData } from '../../api/campaigns/campaigns.get';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { addDays, formatDate, getDateNDaysAgo } from '../../utils/date';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DatePicker from 'react-date-picker';
import patch, {
  CampaignBundlePatchRequest, CampaignBundlePatchResponseError, CampaignBundlePatchResponseOk,
  isResponseOK as isUpdateRateResponseOk,
} from '../../api/campaigns/campaigns.bundle.patch';

const CampaignAnalyticsAbsolutePage = function() {
  const id = parseInt(String(useParams()['id']));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [response, setResponse] = useState<CampaignAnalyticsAbsoluteIndicatorResponseOk>();
  const [inputValueCampaign, setInputValueCampaign] = useState<string>(String(id));
  const [filter, setFilter] = useState<CampaignAnalyticsAbsoluteIndicatorRequest & any>(recognizeCampaignAnalyticsRequest);
  const [inputValueDomainId, setInputValueDomainId] = useState<string>(filter?.domainId ? String(filter?.domainId) : '');
  const [inputValueTagid, setInputValueTagid] = useState<string>(filter?.tagid ?? '');
  const [profitChartOptions, setProfitChartOptions] = useState(getChartOptions(true, [], [], 'Прибыль', 'Прибыль день назад'));
  const [convsChartOptions, setConvsChartOptions] = useState(getChartOptions(true, [], [], 'Лиды', 'Лиды день назад'));
  const [impsChartOptions, setImpsChartOptions] = useState(getChartOptions(true, [], [], 'Показы', 'Показы день назад'));
  const [clicksChartOptions, setClicksChartOptions] = useState(getChartOptions(true, [], [], 'Клики', 'Клики день назад'));
  const [winRateChartOptions, setWinRateChartOptions] = useState(getChartOptions(true, [], [], 'Win Rate', 'Win Rate день назад'));
  const [spendChartOptions, setSpendChartOptions] = useState(getChartOptions(true, [], [], 'Траты', 'Траты день назад'));
  const [cpaChartOptions, setCpaChartOptions] = useState(getChartOptions(true, [], [], 'Cpa', 'Cpa день назад'));
  const [cpmChartOptions, setCpmChartOptions] = useState(getChartOptions(true, [], [], 'Cpm', 'Cpm день назад'));
  const [reqsChartOptions, setReqsChartOptions] = useState(getChartOptions(true, [], [], 'Запросы', 'Запросы день назад'));
  const [rejectedByCappingChartOptions, setRejectedByCappingChartOptions] = useState(getChartOptions(true, [], [], 'Откинуто по каппингу', 'Откинуто по каппингу день назад'));
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [tagIdRate, setTagIdRate] = useState('0');
  const dataContext = useContext(appContext);

  const requestData = () => {
    dataContext.setIsLoading(true);
    get(id, filter).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignAnalyticsAbsoluteIndicatorResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignAnalyticsAbsoluteIndicatorResponseOk;
      setResponse(r);
      setProfitChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.profit ?? [], r.message.data?.prevDate.profit ?? [], 'Прибыль', 'Прибыль день назад'));
      setWinRateChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.winRate ?? [], r.message.data?.prevDate.winRate ?? [], 'Win Rate', 'Win Rate день назад'));
      setSpendChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.spent ?? [], r.message.data?.prevDate.spent ?? [], 'Траты', 'Траты день назад'));
      setImpsChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.imps ?? [], r.message.data?.prevDate.imps ?? [], 'Показы', 'Показы день назад'));
      setClicksChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.clicks ?? [], r.message.data?.prevDate.clicks ?? [], 'Клики', 'Клики день назад'));
      setConvsChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.convs ?? [], r.message.data?.prevDate.convs ?? [], 'Лиды', 'Лиды день назад'));
      setCpaChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.cpa ?? [], r.message.data?.prevDate.cpa ?? [], 'Cpa', 'Cpa день назад'));
      setCpmChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.cpm ?? [], r.message.data?.prevDate.cpm ?? [], 'Cpm', 'Cpm день назад'));
      setReqsChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.reqs ?? [], r.message.data?.prevDate.reqs ?? [], 'Запросы', 'Запросы день назад'));
      setRejectedByCappingChartOptions(getChartOptions(filter?.groupBy === 'day', r.message.data?.date.rejected ?? [], r.message.data?.prevDate.rejected ?? [], 'Откинуто по каппингу', 'Откинуто по каппингу день назад'));
      if (filter?.tagid !== undefined && response?.message?.tags.filter(t => t.tagid === filter?.tagid).length === 1) {
        setTagIdRate(response?.message?.tags.filter(t => t.tagid === filter?.tagid)[0].rate.toString());
      }
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };

  function serialize(obj: object) {
    let str = '?' + Object.keys(obj).reduce(function(a, k) {
      // @ts-ignore
      a.push(k + '=' + encodeURIComponent(obj[k]));
      return a;
    }, []).join('&');
    return str;
  }

  const handleCampaignKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValueCampaign) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        navigate({
          pathname: `/campaigns/${inputValueCampaign}/analytics`,
          search: serialize(filter),
        }, { state: { id: inputValueCampaign } });
        event.preventDefault();
    }
  };
  const handleDomainKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newFilter = { ...filter };
        delete newFilter.domainId;
        if (inputValueDomainId.length > 0) {
          newFilter.domainId = Number(inputValueDomainId);
        }
        setFilter(newFilter);
        event.preventDefault();
    }
  };
  const handleTagidKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newFilter = { ...filter };
        delete newFilter.tagid;
        if (inputValueTagid.length > 0) {
          newFilter.tagid = inputValueTagid;
        }
        setFilter(newFilter);
        event.preventDefault();
    }
  };

  function updateBid(tagIdRate: string, tag: CampaignAnalyticsAbsoluteIndicatorTags) {
    dataContext.setIsLoading(true);
    patch(id, {
      app: tag.app ? 1 : 0,
      tagid: tag.tagid,
      domainId: tag.domainId,
      systemId: tag.systemId,
      data: {
        rate: Number(tagIdRate),
      },
    }).then(r => {
      if (!isUpdateRateResponseOk(r)) {
        r = r as CampaignBundlePatchResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      dataContext.notify('Ставка источника обновлена успешно', 'success');
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  }

  function onKeyDownTagidRate(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault();
        const filtered = response?.message?.tags.filter(t => t.tagid === filter?.tagid) ?? [];
        if (filtered.length > 0) {
          updateBid(tagIdRate, filtered[0]);
          return;
        }
        dataContext.notify('В системе не найден такой источник', 'error');
    }
  }


  useEffect(() => {
    setSearchParams(qs.stringify(filter, { arrayFormat: 'brackets' }));
    requestData();
  }, [filter]);
  useEffect(() => {
    requestData();
  }, [id]);

  function toggleChart(param: string) {
    if (filter[param] !== undefined) {
      delete filter[param];
    } else {
      filter[param] = '1';
    }
    setFilter({ ...filter });
  }

  function hideAllCharts() {
    filter['hideProfit'] = 1;
    filter['hideConvs'] = 1;
    filter['hideSpend'] = 1;
    filter['hideImps'] = 1;
    filter['hideClicks'] = 1;
    filter['hideCpm'] = 1;
    filter['hideWinRate'] = 1;
    filter['hideReqs'] = 1;
    filter['hideCpa'] = 1;
    filter['hideRejectedByCapping'] = 1;
    setFilter({ ...filter });
  }

  const profitChartHidden = filter?.hideProfit !== undefined;
  const winRateChartHidden = filter?.hideWinRate !== undefined;
  const spendChartHidden = filter?.hideSpend !== undefined;
  const convsChartHidden = filter?.hideConvs !== undefined;
  const impsChartHidden = filter?.hideImps !== undefined;
  const clicksChartHidden = filter?.hideClicks !== undefined;
  const cpaChartHidden = filter?.hideCpa !== undefined;
  const cpmChartHidden = filter?.hideCpm !== undefined;
  const reqsChartHidden = filter?.hideReqs !== undefined;
  const rejectedByCappingChartHidden = filter?.hideRejectedByCapping !== undefined;
  return (<Page
    className='AnalyticsPage'
    title='Аналитика кампании'
  >
    {response && <SourcesModal isOpen={isSourceModalOpen} toggle={() => setIsSourceModalOpen(!isSourceModalOpen)}
                               tags={response?.message.tags} />}
    <div className='row'>
      <div className={'col-sm-3 col-12 col-md-2'}>
        <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                    onChange={(date: Date) => setFilter({ ...filter, startDate: formatDate(date) })}
                    value={new Date(filter.startDate?.toString() ?? '')} />
      </div>
      <div className={'col-sm-3 col-12 col-md-2'}>
        <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                    onChange={(date: Date) => setFilter({ ...filter, endDate: formatDate(date) })}
                    value={new Date(filter.endDate?.toString() ?? '')} />
      </div>
      <div className={'col-sm-6 col-12 col-md-8'}>
        <button onClick={() => setFilter({ ...filter, groupBy: 'hour' })}
                className={`btn ml-1 btn-sm btn-${filter?.groupBy === 'hour' ? 'info' : 'primary'}`}>по часам
        </button>
        <button onClick={() => setFilter({ ...filter, groupBy: 'day' })}
                className={`btn ml-1 btn-sm btn-${filter?.groupBy !== 'hour' ? 'info' : 'primary'}`}>по дням
        </button>
      </div>
      <div className='col-sm-4 col-lg-2'>
        <CreatableSelect
          classNames={{ control: (state) => 'campaigns-select' }}
          components={{ DropdownIndicator: null }}
          inputValue={inputValueCampaign}
          menuIsOpen={false}
          onKeyDown={handleCampaignKeyDown}
          onInputChange={(newValue) => setInputValueCampaign(newValue)}
          getOptionLabel={(option: SelectData) => option.name}
          getOptionValue={(option: SelectData) => option.value}
          placeholder='Кампания' value={[{ value: String(id), name: String(id), extra: null }]}
        />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <input type='text' className={'form-control'} onChange={(e) => setInputValueDomainId(e.currentTarget.value)}
               onKeyDown={handleDomainKeyDown} value={inputValueDomainId} placeholder={'Domain id'} />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <input type='text' className={'form-control'} onChange={(e) => setInputValueTagid(e.currentTarget.value)}
               onKeyDown={handleTagidKeyDown} value={inputValueTagid} placeholder={'Tag id'} />
      </div>
      {filter?.tagid !== undefined && response?.message?.tags.filter(t => t.tagid === filter?.tagid).length === 1 &&
        <div className='col-sm-4 col-lg-2'>
          <input type='text' className={'form-control'} placeholder={'Ставка'} value={tagIdRate}
                 onChange={(e) => setTagIdRate(e.currentTarget.value)} onKeyDown={(e) => onKeyDownTagidRate(e)} />
        </div>}
      <QuestionMarkIcon onClick={() => setIsSourceModalOpen(true)} style={{ cursor: 'pointer' }} />
    </div>
    <div className='mt-3'>
      <p style={{ float: 'right' }}>
        <button onClick={() => toggleChart('hideProfit')}
                className={`btn ml-1 btn-sm btn-${profitChartHidden ? 'dark' : 'primary'}`}>прибыль
        </button>
        <button onClick={() => toggleChart('hideConvs')}
                className={`btn ml-1 btn-sm btn-${convsChartHidden ? 'dark' : 'primary'}`}>лиды
        </button>
        <button onClick={() => toggleChart('hideSpend')}
                className={`btn ml-1 btn-sm btn-${spendChartHidden ? 'dark' : 'primary'}`}>траты
        </button>
        <button onClick={() => toggleChart('hideImps')}
                className={`btn ml-1 btn-sm btn-${impsChartHidden ? 'dark' : 'primary'}`}>показы
        </button>
        <button onClick={() => toggleChart('hideClicks')}
                className={`btn ml-1 btn-sm btn-${clicksChartHidden ? 'dark' : 'primary'}`}>клики
        </button>
        <button onClick={() => toggleChart('hideCpm')}
                className={`btn ml-1 btn-sm btn-${cpmChartHidden ? 'dark' : 'primary'}`}>cpm
        </button>
        <button onClick={() => toggleChart('hideWinRate')}
                className={`btn ml-1 btn-sm btn-${winRateChartHidden ? 'dark' : 'primary'}`}>win rate
        </button>
        <button onClick={() => toggleChart('hideReqs')}
                className={`btn ml-1 btn-sm btn-${reqsChartHidden ? 'dark' : 'primary'}`}>запросы
        </button>
        <button onClick={() => toggleChart('hideCpa')}
                className={`btn ml-1 btn-sm btn-${cpaChartHidden ? 'dark' : 'primary'}`}>cpa
        </button>
        <button onClick={() => toggleChart('hideRejectedByCapping')}
                className={`btn ml-1 btn-sm btn-${rejectedByCappingChartHidden ? 'dark' : 'primary'}`}>Откинуто по каппингу
        </button>
        <button onClick={() => hideAllCharts()}
                className={`btn ml-1 btn-sm btn-primary`}>скрыть все
        </button>
      </p>
      <a target={'_blank'}
         href={`http://grafana.inspiritum.com/d/GxTfHbhnk/campaign-analytic?orgId=1&refresh=1m&var-CampaignID=${id}&from=now-7d&to=now&viewPanel=2`}>
        <img src={GrafanaIcon} alt='grafana icon' width={30} /> аналитика
      </a>|
      <a target={'_blank'}
         href={`https://adm-de.inspidsp.com/statistics2?date_from=${filter?.startDate}&hour_from=00&date_to=${formatDate(addDays(filter?.endDate, 1))}&hour_to=00&GMT=0&additional_filters=on&group_blocks%5B%5D=date${filter.domainId ? `&domain%5B%5D=a${filter.domainId}&group_blocks%5B%5D=domain` : ''}&bidfloor_from=&bidfloor_to=&status=on_off&campaign%5B%5D=${id}&sort=date&order=asc`}>В
        детальную</a>
    </div>
    {!profitChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={profitChartOptions}
      />
    </div>}
    {!convsChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={convsChartOptions}
      />
    </div>}
    {!spendChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={spendChartOptions}
      />
    </div>}
    {!impsChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={impsChartOptions}
      />
    </div>}
    {!clicksChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={clicksChartOptions}
      />
    </div>}
    {!cpmChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={cpmChartOptions}
      />
    </div>}
    {!winRateChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={winRateChartOptions}
      />
    </div>}
    {!reqsChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={reqsChartOptions}
      />
    </div>}
    {!cpaChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={cpaChartOptions}
      />
    </div>}
    {!rejectedByCappingChartHidden && <div className='mt-3' style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={rejectedByCappingChartOptions}
      />
    </div>}
  </Page>);
};

function getChartOptions(onlyPrimaryChart: boolean, rows: [number, number][], prevDateRows: [number, number][], titleName: string, prevDateTitleName: string): Highcharts.Options {
  const mapDataFromSecondsToMs = (row: [number, number]): [number, number] => {
    row[0] = convertFromSecondsToMs(row[0]);
    return row;
  };
  const convertFromSecondsToMs = (timestamp: number) => {
    return timestamp * 1000;
  };
  const rowsSeries: SeriesOptionsType = {
    type: 'area',
    name: titleName,
    yAxis: 0,
    color: '#8be7a0',
    data: rows.map((row) => mapDataFromSecondsToMs(row)),
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, 'rgba(139, 231, 160, 0.4)'],
        [1, String(Highcharts.color('#8be7a0').setOpacity(0).get())],
      ],
    },
  };

  let series = [rowsSeries];
  if (!onlyPrimaryChart) {

    const prevDateRowsSeries: SeriesOptionsType = {
      type: 'area',
      name: prevDateTitleName,
      yAxis: 0,
      color: '#f7c02b',
      data: prevDateRows.map((row) => mapDataFromSecondsToMs(row)),
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'rgba(247, 192, 43, 0.3)'],
          [1, String(Highcharts.color('#f7c02b').setOpacity(0).get())],
        ],
      },
    };

    /*const deltaRowsSeries: SeriesOptionsType = {
      type: 'area',
      name: 'E',
      yAxis: 0,
      color: '#5794f2',
      data: rows.map((row, i) => {
        let data = row;
        let lastDayVal = 0;
        if (prevDateRows[i] !== undefined) {
          let prevRow = prevDateRows.find(item => item[0] == data[0])
          if (prevRow) {
            lastDayVal = prevRow[1];
          }
        }
        let val = (data[1] - lastDayVal) * 60;
        val = (Math.floor(val * 100)) / 100;

        return [data[0], (1 - lastDayVal / data[1]) * 100];
      }),
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'rgba(87, 148, 242, 0.3)'],
          [1, String(Highcharts.color('#5794f2').setOpacity(0).get())],
        ],
      },
    };*/

    series = [
      prevDateRowsSeries,
      // deltaRowsSeries,
      rowsSeries,
    ];
  }

  return {
    chart: {
      type: 'spline',
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        format: '{value:%d.%m}',
      },
      type: 'datetime',
    },

    yAxis: [
      {
        title: {
          text: titleName,
        },
        opposite: true,
        showEmpty: false,
      },
    ],
    tooltip: {
      split: false,
      shared: true,
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        let s = Highcharts.dateFormat('%d.%m %H', Number(this.x));
        let val = -1;
        if (this.points?.length) for (let i = 0; i < this.points?.length; i++) {
          val = Number(this.y);
          if (String(this.points[i].series.name) === titleName) {
            s += `<br/>${titleName}: ${Number(this.points[i].y)}`;
          } else if (String(this.points[i].series.name) === prevDateTitleName) {
            s += `<br/>День назад: ${Number(this.points[i].y)}`;
          } else {
            s += `<br/>E: ${Number(this.points[i].y)}`;
          }
        }
        return s;
      },

    },
    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      area: {
        /*fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, '#f7c02b'],
            [0, '#8be7a0'],
            [1, String(Highcharts.color('#8be7a0').setOpacity(0).get())],
            [1, String(Highcharts.color('#f7c02b').setOpacity(0).get())],
          ],
        },*/
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: series,
  };
}

export default CampaignAnalyticsAbsolutePage;
