import React from 'react';
import Switch from 'rc-switch';

const TaskIsAutoExecutableSwitch = (props: { disabled: boolean, checked: boolean, onChange: (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void}) => {
    return (<Switch
              disabled={props.disabled}
              checked={props.checked}
              onChange={props.onChange}
              checkedChildren='on'
              unCheckedChildren='off' />
  );
}
export default TaskIsAutoExecutableSwitch;


