//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/preferences/telegram/chat';

export type TelegramChatGetResponseOk = {
  'status': number,
  'message': TelegramChatGetResponseMessageOk,
}

export type TelegramChatGetResponseMessageOk = {
  data: TelegramChatGet,
}

export type TelegramChatGetResponseError = {
  'status': number,
  'message': TelegramChatGetResponseMessageError,
}

export type TelegramChatGetResponseMessageError = string


export type TelegramChatGet = {
  chatid: number,
  group: number,
}

// @ts-ignore
const key = new apiKey();


export default function get(): Promise<TelegramChatGetResponseOk | TelegramChatGetResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <TelegramChatGetResponseMessageOk | TelegramChatGetResponseError>r.data;
  }).catch(e => {
    const resp: TelegramChatGetResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: TelegramChatGetResponseOk | TelegramChatGetResponseError): boolean {
  return typeof response.message !== 'string';
}