import React, {
  ComponentProps,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CampaignRequest, createCampaignRequest, SelectData, Selects } from '../../api/campaigns/campaigns.get';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import {
  formatDate,
  getCurrentMonthStartDate,
  getCurrentWeekStartDate,
  getCurrentYearStartDate,
  getDateNDaysAgo,
  getLastMonthEndDate,
  getLastMonthStartDate,
  getLastWeekEndDate,
  getLastWeekStartDate,
  getLastYearEndDate,
  getLastYearStartDate,
  getNMonthAgoStartDate,
} from '../../utils/date';
import CreatableSelect from 'react-select/creatable';
import UserColumnSettingsModal from './UserColumnSettingsModal';

function setToMonday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 7;
  if (day !== 1) {
    date.setHours(-24 * (day - 1));
  }
  return date;
}

function setToSunday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 0;
  if (day !== 0) {
    date.setHours(24 * (7 - day));
  }
  return date;
}

function setToFirstMonthDate(date: Date, monthAgo: number = 0) {
  date.setMonth(date.getMonth() - monthAgo);
  date.setDate(1);
  return date;
}

function setToLastMonthDate(date: Date, monthAgo: number = 0) {
  return new Date(date.getFullYear(), date.getMonth() - monthAgo + 1, 0);
}

const regionsOptions = [
  {
    name: 'Америка',
    value: '2',
    extra: null
  },
  {
    name: 'Азия',
    value: '5',
    extra: null
  },
  {
    name: 'Европа',
    value: '14',
    extra: null
  },
];

const dateRanges: SelectData[] = [
  {
    name: '',
    value: '',
    extra: null
  },
  {
    name: '7 дней',
    value: '7_days',
    extra: null
  },
  {
    name: '30 дней',
    value: '30_days',
    extra: null
  },
  {
    name: '90 дней',
    value: '90_days',
    extra: null
  },
  {
    name: 'текущая неделя',
    value: 'curr_week',
    extra: null
  },
  {
    name: 'прошлая неделя',
    value: 'last_week',
    extra: null
  },
  {
    name: 'текущий месяц',
    value: 'curr_month',
    extra: null
  },
  {
    name: 'прошлый месяц',
    value: 'last_month',
    extra: null
  },
  {
    name: 'последние 3 месяца',
    value: '3_months',
    extra: null
  },
  {
    name: 'текущий год',
    value: 'curr_year',
    extra: null
  },
  {
    name: 'прошлый год',
    value: 'last_year',
    extra: null
  },
];

function getRegionsOptionByValue(values: string[]) {
  return regionsOptions.filter(option => values.includes(option.value));
}

function getOptionsByValue(values: string[], options: SelectData[]): SelectData[] {
  if (!values) return [];
  return options.filter(option => values.includes(option.value));
}

interface Option {
  readonly name: string;
  readonly value: string;
}

const createOption = (name: string, value: string = '', extra: any = null) => ({
  name: name,
  value: value ? value : name,
  extra: extra
});

const createOptions = (options: string[] | SelectData[] | undefined) => {
  if (!options) {
    return [];
  }
  if (!Array.isArray(options)) {
    return [];
  }
  if (Array.isArray(options) && options.length > 0 && typeof options[0] === 'string') {
    options = options as string[];
    options = options.filter(function(value: string, index: number, array: string[]): boolean {
      return array.indexOf(value) === index;
    });
  }

  return options.map(val => {
    if (typeof val === 'string') {
      return createOption(val);
    }
    return createOption(val.name, val.value);
  });
};

export default (props: ComponentProps<any> & { filter: CampaignRequest, setFilter: Dispatch<SetStateAction<any>>, headers: Array<string>, selects: Selects }) => {
  const [inputValue, setInputValue] = React.useState('');
  const DatePeriod = useRef<HTMLSelectElement>(null);
  const [campaignsValue, setCampaignsValue] = React.useState<readonly SelectData[]>(createOptions(props.filter.campaigns));
  const [formatsValue, setFormatsValue] = React.useState<readonly SelectData[]>(createOptions(props.filter.formats));
  const [countriesValue, setCountriesValue] = React.useState<readonly SelectData[]>([]);
  const [usersValue, setUsersValue] = React.useState<readonly SelectData[]>(createOptions(props.filter.users));
  const [dateRange, setDateRange] = React.useState<SelectData>({ name: '', value: '', extra: null });
  const [categoriesValue, setCategoriesValue] = React.useState<readonly SelectData[]>([]);
  const [groupIdValue, setGroupIdValue] = React.useState<SelectData | null>(null);
  const [operatorsValue, setOperatorsValue] = React.useState<readonly SelectData[]>([]);
  const [systemsValue, setSystemsValue] = React.useState<readonly SelectData[]>([]);
  const [regionsValue, setRegionsValue] = React.useState<readonly SelectData[]>(props.filter.regions && props.filter.regions.length > 0 ? getRegionsOptionByValue(props.filter.regions) : regionsOptions);
  const [offersValue, setOffersValue] = React.useState<readonly SelectData[]>(createOptions(props.filter.offers));
  const navigate = useNavigate();
  useEffect(() => {
    setFormatsValue(getOptionsByValue(props.filter.formats, props.selects.formats));
    setCountriesValue(getOptionsByValue(props.filter.countries, props.selects.countries));
    setCategoriesValue(getOptionsByValue(props.filter.categories, props.selects.categories));
    setOperatorsValue(getOptionsByValue(props.filter.operators, props.selects.operators));
    setSystemsValue(getOptionsByValue(props.filter.systems, props.selects.systems));
    setOffersValue(getOptionsByValue(props.filter.offers, props.selects.offers));

    const groups = getOptionsByValue([props.filter.groupId], props.selects.groups);
    setGroupIdValue(Array.isArray(groups) && groups.length > 0 ? groups[0] : null);
  }, [props.selects]);


  function getGroupIdValue(campaigns: number[]): null|string {
    const group = props.selects.groups.filter((g:any) => g.value === groupIdValue?.value);
    if (!group) {
      return null;
    }
    if (group.extra === undefined) {
      return null;
    }
    const diff = group.extra.filter((cid:number) => campaigns.includes(cid));
    if (diff.length > 0) {
      return null;
    }

    return group.value
  }

  const onChangeCampaignsSelect = (options: any) => {
    let campaignsValues = options.map((val: Option) => val.value);
    campaignsValues = campaignsValues.filter(function(value: any, index: number, array: any[]) {
      return array.indexOf(value) === index;
    });
    const groupId = getGroupIdValue(campaignsValues);
    const filter = { ...props.filter, campaigns: campaignsValues, page: 1 };
    if (groupId !== null) {
      filter.groupId = groupId;
    } else {
      delete(filter.groupId);
    }
    props.setFilter(filter);
    setCampaignsValue(options);
  };


  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let campaigns = props.filter.campaigns;
        if (campaigns === undefined) {
          campaigns = [];
        }
        campaigns = campaigns.filter(function(value: any, index: number, array: any[]) {
          return array.indexOf(value) === index;
        });

        onChangeCampaignsSelect(createOptions([...campaigns, inputValue]));
        setInputValue('');
        event.preventDefault();
    }
  };

  const resetDatePeriod = () => {
    if (DatePeriod.current !== null) {
      DatePeriod.current.value = '';
    }
  };

  function calculateDatePeriodDiff(date: Date | null, filterParam: string) {
    const secondsInDay = 86400;
    let endDate = null;
    let startDate = null;
    if (filterParam === 'startDate') {
      startDate = date;
      endDate = new Date(props.filter.endDate);
    } else {
      endDate = date;
      startDate = new Date(props.filter.startDate);
    }

    if (!startDate || !endDate) {
      return null;
    }

    let mondayDate = setToMonday(new Date());
    let sundayDate = setToSunday(new Date());
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('curr_week');
      return;
    }

    mondayDate = setToMonday(new Date(), 1);
    sundayDate = setToSunday(new Date(), 1);
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('last_week');
      return;
    }

    let firstDateMonth = setToFirstMonthDate(new Date(), 1);
    let lastDateMonth = setToLastMonthDate(new Date(), 1);
    if (Math.abs(endDate.getTime() - lastDateMonth.getTime()) < 86400000
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('last_month');
      return;
    }

    firstDateMonth = setToFirstMonthDate(new Date(), 2);
    lastDateMonth = setToLastMonthDate(new Date());
    if (((endDate.getTime() - lastDateMonth.getTime()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('3_months');
      return;
    }

    let firstDateYear = getCurrentYearStartDate();
    if (((endDate.getTime() - Date.now()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateYear.getTime()) < 86400000) {
      onDataRangeChange('curr_year');
      return;
    }

    if ((endDate.getTime() - getLastYearEndDate().getTime()) < 86400000
      && Math.abs(startDate.getTime() - getLastYearStartDate().getTime()) < 86400000) {
      onDataRangeChange('last_year');
      return;
    }

    const diff = Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / 1000 / secondsInDay) + 1;
    switch (diff) {
      case 7:
        onDataRangeChange('7_days');
        return;
      case 30:
        onDataRangeChange('30_days');
        return;
      case 90:
        onDataRangeChange('90_days');
        return;
      default:
        break;
    }

  }

  const onChangeDatePicker = (date: Date | null, filterParam: string) => {
    resetDatePeriod();
    calculateDatePeriodDiff(date, filterParam);

    if (date === null) {
      const filter = { ...props.filter, page: 1 };
      delete (filter[filterParam]);
      props.setFilter(filter);
      return;
    }
    props.setFilter({ ...props.filter, [filterParam]: formatDate(date), page: 1 });

  };
  const setDate = (dateStart: Date, dateEnd: Date) => {
    props.setFilter({ ...props.filter, startDate: formatDate(dateStart), endDate: formatDate(dateEnd), page: 1 });
  };

  const onChangeSelect = (values: OnChangeValue<any, any>, meta: ActionMeta<any>) => {
    if (meta.name === undefined) {
      console.warn('Cannot set data to filter. Set to select a name attribute');
      return;
    }

    eval('set' + (String(meta.name).charAt(0).toUpperCase() + String(meta.name).slice(1)) + 'Value')(values);
    if (Array.isArray(values)) {
      const options = [];
      for (let i = 0; i < values.length; i++) {
        options.push(values[i].value);
      }
      props.setFilter({ ...props.filter, [String(meta.name)]: options, page: 1 });
      return;
    }
    props.setFilter({ ...props.filter, [String(meta.name)]: values?.value, page: 1 });
    return;
  };

  const onChangeGroupSelect = (values: OnChangeValue<any, any>, meta: ActionMeta<any>) => {
    if (meta.action === 'clear') {
      setGroupIdValue(null);
      props.setFilter({ ...props.filter, groupId: null, page: 1 });
      return;
    }
    if (meta.action !== 'select-option') {
      return;
    }
    if (meta.name === undefined) {
      console.warn('Cannot set data to filter. Set to select a name attribute');
      return;
    }

    eval('set' + (String(meta.name).charAt(0).toUpperCase() + String(meta.name).slice(1)) + 'Value')(values);
    if (values.extra === null) {
      return;
    }

    const extra = values.extra.map((campaign: number) => String(campaign));
    setCampaignsValue(createOptions(extra));
    props.setFilter({ ...props.filter, groupId: values.value, campaigns: extra, page: 1 });
    return;
  };

  const onChangeCheckbox = (e: any) => {
    if (e.target.name === undefined || e.target.name === '') {
      console.warn('Cannot set data to filter. Set to select a name attribute');
      return;
    }

    if (e.target.checked) {
      props.setFilter({ ...props.filter, [e.target.name]: '', page: 1 });
      return;
    }

    const newFilter = { ...props.filter, page: 1 };
    delete (newFilter[e.target.name]);
    props.setFilter(newFilter);
  };

  function onDataRangeChange(value: string) {
    switch (value) {
      case '7_days':
        setDate(getDateNDaysAgo(7), new Date());
        setDateRange({ name: '7 дней', value: '7_days', extra: null});
        break;
      case '30_days':
        setDate(getDateNDaysAgo(30), new Date());
        setDateRange({ name: '30 дней', value: '30_days', extra: null});
        break;
      case '90_days':
        setDate(getDateNDaysAgo(90), new Date());
        setDateRange({ name: '90 дней', value: '90_days', extra: null});
        break;
      case 'curr_week':
        setDate(getCurrentWeekStartDate(), new Date());
        setDateRange({ name: 'текущая неделя', value: 'curr_week', extra: null});
        break;
      case 'last_week':
        setDate(getLastWeekStartDate(), getLastWeekEndDate());
        setDateRange({ name: 'прошлая неделя', value: 'last_week', extra: null});
        break;
      case 'curr_month':
        setDate(getCurrentMonthStartDate(), new Date());
        setDateRange({ name: 'текущий месяц', value: 'curr_month', extra: null});
        break;
      case 'last_month':
        setDate(getLastMonthStartDate(), getLastMonthEndDate());
        setDateRange({ name: 'прошлый месяц', value: 'last_month', extra: null});
        break;
      case '3_months':
        setDate(getNMonthAgoStartDate(2), new Date());
        setDateRange({ name: 'последние 3 месяца', value: '3_months', extra: null});
        break;
      case 'curr_year':
        setDate(getCurrentYearStartDate(), new Date());
        setDateRange({ name: 'текущий год', value: 'curr_year', extra: null});
        break;
      case 'last_year':
        setDate(getLastYearStartDate(), getLastYearEndDate());
        setDateRange({ name: 'прошлый год', value: 'last_year', extra: null});
        break;
      default:
        setDateRange({ name: '', value: '', extra: null});
        break;
    }
  }

  return (
    <div>
      <div id={'campaign-filter'} className={'campaign-filter'}>
        <div className='row'>
          <div className='col-12 col-sm-4 col-md-4 col-lg-3'>
            <Select
              name={'regions'}
              isMulti={true}
              placeholder={'Все регионы'}
              onChange={onChangeSelect}
              value={regionsValue}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={regionsOptions} />
          </div>
          <div className='col-sm-3'>
            <div className='form-group'>
              <div className='form-check'>
                <label className='form-check-label '>
                  <input id='archivedCampaigns' name={'archived'} type='checkbox' className='form-check-input'
                         onChange={onChangeCheckbox} checked={props.filter?.archived === ''} /> Показывать архивные
                </label>
              </div>
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form-group'>
              <div className='form-check'>
                <label className='form-check-label '>
                  <input id='showDisabledCampaigns' name={'disabled'} type='checkbox' className='form-check-input'
                         onChange={onChangeCheckbox} checked={props.filter?.disabled === ''} /> Показывать выключенные
                </label>
              </div>
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form-group'>
              <div className='form-check'>
                <label className='form-check-label '>
                  <input id='showOnlyAutoCpaCampaigns' name={'onlyAutoCpa'} type='checkbox' className='form-check-input'
                         onChange={onChangeCheckbox} checked={props.filter?.onlyAutoCpa === ''} /> Показывать только autocpa
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'systems'}
              isMulti={true}
              placeholder={'Все системы'}
              value={systemsValue}
              onChange={onChangeSelect}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.systems} />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'operators'}
              isMulti={true}
              value={operatorsValue}
              onChange={onChangeSelect}
              placeholder={'Все операторы'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.operators} />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'formats'}
              isMulti={true}
              value={formatsValue}
              onChange={onChangeSelect}
              placeholder={'Все форматы'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.formats} />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'countries'}
              isMulti={true}
              value={countriesValue}
              onChange={onChangeSelect}
              placeholder={'Все страны'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.countries} />
            <div>
              <label htmlFor='excludeCountries'>
                <input id='excludeCountries' name={'excludeCountries'} onChange={onChangeCheckbox}
                       checked={props.filter?.excludeCountries === ''}
                       type='checkbox' /> Исключить
              </label>
            </div>
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'categories'}
              isMulti={true}
              value={categoriesValue}
              onChange={onChangeSelect}
              placeholder={'Все категории'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.categories} />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'users'}
              isMulti={true}
              value={usersValue}
              onChange={onChangeSelect}
              placeholder={'Все пользователи'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.users}
            />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'groupId'}
              isClearable={true}
              value={groupIdValue}
              onChange={onChangeGroupSelect}
              placeholder={'Все группы кампаний'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.groups} />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <CreatableSelect
              classNames={{control: (state) => state.isFocused ? 'campaigns-select campaigns-select--expanded campaigns-select--expanded--absolute' : 'campaigns-select'} }
              // className={`campaigns-select ${!collapseCampaignsList ? 'campaigns-select--expanded' : ''}`}
              components={{ DropdownIndicator: null }}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={onChangeCampaignsSelect}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value}
              placeholder='Кампании'
              value={campaignsValue}
            />
          </div>
          <div className='col-sm-4 col-lg-3'>
            <Select
              name={'offers'}
              isMulti={true}
              value={offersValue}
              onChange={onChangeSelect}
              placeholder={'Все офферы'}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={props.selects.offers} />
          </div>
        </div>
        {/*<div className={'row'}>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>От</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'startDate')}
                        value={(new Date(props.filter.startDate))} />
          </div>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>До</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'endDate')}
                        value={(new Date(props.filter.endDate))} />
          </div>
          <div className={'col-sm-6 col-12 col-md-3'}>
            <div>Период</div>
            <Select
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '100%',
                }),
              }}
              name={''}
              placeholder={''}
              value={dateRange}
              onChange={(values: OnChangeValue<any, any>, meta: ActionMeta<any>) => {
                onDataRangeChange(values.value);
              }}
              getOptionLabel={(option: SelectData) => option.name}
              getOptionValue={(option: SelectData) => option.value} options={dateRanges} />
          </div>
        </div>*/}
        <div className='col-sm-12'>
          <div className='row' style={{ gap: '3px' }}>
            {/*<div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                resetDatePeriod();
                return setDate(new Date(), new Date());
              }} className='btn btn-sm btn-info'>Сегодня
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                resetDatePeriod();
                setDate(getDateNDaysAgo(1), getDateNDaysAgo(1));
              }} className='btn btn-sm btn-info'>Вчера
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={clearFilter} className='btn btn-sm btn-primary'>очистить</button>
            </div>*/}

          </div>
        </div>
      </div>
    </div>
  );
};