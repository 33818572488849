import React, { ComponentProps } from 'react';
import {
  CreativeImage,
  CreativeNative,
  CreativeVideo,
  MaterialFormat,
} from '../../../api/campaigns/campaign.materials.get';
import { Player } from 'video-react';

import 'video-react/dist/video-react.css';


export default function(props: ComponentProps<any> & { format: MaterialFormat, creative: CreativeImage | CreativeVideo | CreativeNative | null }) {
  return (<React.Fragment>
    {props.format === MaterialFormat.BANNER && props.creative?.img && <CreativeImg creative={props.creative} />}
    {props.format === MaterialFormat.VIDEO && props.creative?.videoName && <CreativeVid creative={props.creative} />}
  </React.Fragment>);
}

function CreativeImg(props: ComponentProps<any> & { creative: CreativeImage | CreativeVideo | CreativeNative | null }) {
  return (<img src={props.creative.img} alt={'Креатив'} style={{ maxWidth: '100px' }} />);
}

const CreativeVid = (props: ComponentProps<any> & { creative: CreativeVideo }) => {
  return (
    <Player
      playsInline
      poster={props.creative.banner ?? undefined}
      src={props.creative.videoName ?? undefined}
    />
  );
};
