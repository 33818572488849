import React, { KeyboardEventHandler, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import appContext from '../../utils/context/appContext';
import { useNavigate, useLocation } from 'react-router-dom';
import get, {
  CampaignAnalyticsResponseError,
  CampaignAnalyticsResponseOk,
  CampaignAnalyticsStat,
  isResponseOK,
} from '../../api/campaigns/campaigns.analytics.get';
import getBundleAnalytics, {
  CampaignBundlesAnalyticsResponseError,
  CampaignBundlesAnalyticsResponseOk,
  CampaignBundlesAnalyticsStat,
  isResponseOK as isBundleAnalyticsResponseOK,
} from '../../api/campaigns/campaigns.bundles.analytics.get';
import CreatableSelect from 'react-select/creatable';
import { SelectData } from '../../api/campaigns/campaigns.get';
import { formatDate, getDateNDaysAgo } from '../../utils/date';
import DatePicker from 'react-date-picker';
import { Paper, TableSortLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellRaw from '@mui/material/TableCell';
import TableCell from '../../components/Dashboard/DataTable/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

const createOption = (name: string, value: string = '', extra: any = null) => ({
  name: name,
  value: value ? value : name,
  extra: extra,
});

const createOptions = (options: string[] | SelectData[] | undefined) => {
  if (!options) {
    return [];
  }
  if (!Array.isArray(options)) {
    return [];
  }
  if (Array.isArray(options) && options.length > 0 && typeof options[0] === 'string') {
    options = options as string[];
    options = options.filter(function(value: string, index: number, array: string[]): boolean {
      return array.indexOf(value) === index;
    });
  }

  return options.map(val => {
    if (typeof val === 'string') {
      return createOption(val);
    }
    return createOption(val.name, val.value);
  });
};

const CampaignAnalyticsPage = function() {
  const getCampaignsFromQuery = () => {
    const queryString = location.search;
    const cleanQueryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;
    const queryParams = qs.parse(cleanQueryString);
    const campaignsParam = queryParams['campaigns'];
    if (campaignsParam !== undefined && typeof campaignsParam === 'string') {
      const campaignsParamSplitted = (campaignsParam as string).split(',');
      const campaignsArray = campaignsParamSplitted.map(value => ({
        name: String(value),
        value: String(value),
        extra: null,
      }));
      return campaignsArray;
    }

    return [];
  };

  type DateType = 'date' | 'prevDate';

  const getDateFromQuery = (dateType: DateType) => {
    const queryString = location.search;
    const cleanQueryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;
    const queryParams = qs.parse(cleanQueryString);
    const dateParam = queryParams[dateType];
    if (dateParam !== undefined && typeof dateParam === 'string') {
      const date = new Date(String(dateParam));
      if (!isNaN(date.getTime())) {
        return String(dateParam);
      }
    }

    let resultDate = new Date();
    if (dateType === 'prevDate') {
      resultDate = getDateNDaysAgo(1);
    }

    return formatDate(resultDate);
  };


  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [response, setResponse] = useState<CampaignAnalyticsResponseOk | null>(null);
  const [data, setData] = useState<CampaignAnalyticsStat[]>([]);
  const [campaigns, setCampaigns] = useState<SelectData[]>(getCampaignsFromQuery);
  const [shownCampaignId, setShownCampaignId] = useState<number>(0);
  const [bundleAnalyticsSources, setBundleAnalyticsSources] = useState<CampaignBundlesAnalyticsStat[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [prevDate, setPrevDate] = useState<string>(getDateFromQuery('prevDate'));
  const [date, setDate] = useState<string>(getDateFromQuery('date'));
  const [inputValueCampaign, setInputValueCampaign] = useState<string>('');
  const [diff, setDiff] = useState<string>('');
  const [minDiff, setMinDiff] = useState<string>('');
  const [groupIdValue, setGroupIdValue] = React.useState<SelectData | null>(null);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortSourceDir, setSortSourceDir] = useState<'desc' | 'asc'>('asc');
  const [sortColumn, setSortColumn] = useState<'prevDate' | 'date' | 'diff' | null>('diff');
  const [sortSourceColumn, setSourceSortColumn] = useState<'prevDate' | 'date' | 'diff' | null>('diff');
  const dataContext = useContext(appContext);


  useEffect(() => {
    if (!shownCampaignId) {
      return;
    }
    requestBundleAnalyticsData(shownCampaignId);
  }, [shownCampaignId]);
  useEffect(() => {
    if (!prevDate || !date) {
      return;
    }

    requestData();
  }, [prevDate, date, campaigns, page]);

  useEffect(() => {
    sortData(data);
  }, [sortDir, sortColumn]);
  useEffect(() => {
    if (bundleAnalyticsSources === null) {
      return;
    }
    sortSourceData(bundleAnalyticsSources);
  }, [sortSourceDir, sortSourceColumn, shownCampaignId]);

  const sortData = (data: CampaignAnalyticsStat[]) => {
    if (sortColumn === null) {
      return;
    }
    setData([...data.sort((a, b) => {
      if (sortDir === 'asc') {
        if (sortColumn === 'diff') {
          return a.profit.prevDate - a.profit.date > b.profit.prevDate - b.profit.date ? -1 : 1;
        }
        return a.profit[sortColumn] > b.profit[sortColumn] ? -1 : 1;
      }
      if (sortColumn === 'diff') {
        return a.profit.prevDate - a.profit.date <= b.profit.prevDate - b.profit.date ? -1 : 1;
      }
      return a.profit[sortColumn] < b.profit[sortColumn] ? -1 : 1;
    })]);
  };

  const sortSourceData = (data: CampaignBundlesAnalyticsStat[]) => {
    if (sortSourceColumn === null) {
      return;
    }
    const newData = [...data];
    newData.sort((a, b) => {
      if (sortSourceDir === 'asc') {
        if (sortSourceColumn === 'diff') {
          return a.profit.prevDate - a.profit.date > b.profit.prevDate - b.profit.date ? -1 : 1;
        }
        return a.profit[sortSourceColumn] > b.profit[sortSourceColumn] ? -1 : 1;
      }
      if (sortSourceColumn === 'diff') {
        return a.profit.prevDate - a.profit.date <= b.profit.prevDate - b.profit.date ? -1 : 1;
      }
      return a.profit[sortSourceColumn] < b.profit[sortSourceColumn] ? -1 : 1;
    });
    setBundleAnalyticsSources(newData);
  };

  const requestData = () => {
    dataContext.setIsLoading(true);
    const id: number[] = [];
    campaigns.map(item => id.push(Number(item.value)));

    const paramsObj = { prevDate, date, 'campaigns': id.length ? id.join(',') : undefined, page };
    const newUri = qs.stringify(paramsObj, { encode: false, allowDots: true, addQueryPrefix: true });
    navigate(currentLocation.pathname + newUri, { relative: 'path', replace: true });

    get({ prevDate, date, campaigns: id, page }).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignAnalyticsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignAnalyticsResponseOk;
      sortData(r.message.data.data);
      setResponse(r);
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };

  const requestBundleAnalyticsData = (campaignId: number) => {
    dataContext.setIsLoading(true);
    setBundleAnalyticsSources(null);
    getBundleAnalytics(campaignId, { prevDate, date, diff, minDiff }).then(r => {
      if (!isBundleAnalyticsResponseOK(r)) {
        r = r as CampaignBundlesAnalyticsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignBundlesAnalyticsResponseOk;
      setBundleAnalyticsSources(r.message.data.data);
    }).catch((e) => {
      setBundleAnalyticsSources(null);
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValueCampaign) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newCampaigns = [];
        let hasValue = false;
        for (let i = 0; i < campaigns.length; i++) {
          newCampaigns.push({ name: campaigns[i].name, value: campaigns[i].value, extra: null });
          if (campaigns[i].value === inputValueCampaign) {
            hasValue = true;
          }
        }

        if (!hasValue) {
          newCampaigns.push({ name: inputValueCampaign, value: inputValueCampaign, extra: null });
        }
        setCampaigns(newCampaigns);
        setInputValueCampaign('');
        event.preventDefault();
    }
  };

  const onChangeGroupSelect = (values: OnChangeValue<any, any>, meta: ActionMeta<any>) => {
    if (meta.action === 'clear') {
      setGroupIdValue(null);
      setCampaigns([]);
      return;
    }
    if (meta.action !== 'select-option') {
      return;
    }
    if (meta.name === undefined) {
      console.warn('Cannot set data to filter. Set to select a name attribute');
      return;
    }

    eval('set' + (String(meta.name).charAt(0).toUpperCase() + String(meta.name).slice(1)) + 'Value')(values);
    if (values.extra === null) {
      return;
    }

    const extra = values.extra.map((campaign: number) => String(campaign));
    setCampaigns(createOptions(extra));
    return;
  };

  const onChangeCampaignsSelect = (options: any) => {
    setCampaigns(options);
  };

  function serialize(obj: object) {
    let str = '?' + Object.keys(obj).reduce(function(a, k) {
      // @ts-ignore
      a.push(k + '=' + encodeURIComponent(obj[k]));
      return a;
    }, []).join('&');
    return str;
  }

  const createSortHandler = (property: 'prevDate' | 'date' | 'diff') => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: 'prevDate' | 'date' | 'diff',
  ) => {
    const isDesc = sortColumn === property && sortDir === 'desc';
    const dir = isDesc ? 'asc' : 'desc';
    if (dir !== sortDir) {
      setSortDir(dir);
    }
    if (property !== sortColumn) {
      setSortColumn(property);
    }
  };

  const createSourceSortHandler = (property: 'prevDate' | 'date' | 'diff') => (event: React.MouseEvent<unknown>) => {
    handleRequestSourceSort(event, property);
  };

  const handleRequestSourceSort = (
    event: React.MouseEvent<unknown>,
    property: 'prevDate' | 'date' | 'diff',
  ) => {
    const isDesc = sortSourceColumn === property && sortSourceDir === 'desc';
    const dir = isDesc ? 'asc' : 'desc';
    if (dir !== sortSourceDir) {
      setSortSourceDir(dir);
    }
    if (property !== sortSourceColumn) {
      setSourceSortColumn(property);
    }
  };

  return (<Page
    className='AnalyticsPage'
    title='Аналитика кампаний'
  >
    <div className='row'>
      <div className={'col-sm-3 col-12 col-md-2'}>
        <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                    onChange={(date: Date) => setPrevDate(formatDate(date))}
                    value={new Date(prevDate)} />
      </div>
      <div className={'col-sm-3 col-12 col-md-2'}>
        <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                    onChange={(date: Date) => setDate(formatDate(date))}
                    value={new Date(date)} />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <CreatableSelect
          classNames={{ control: (state) => state.isFocused ? 'campaigns-select campaigns-select--expanded campaigns-select--expanded--absolute' : 'campaigns-select' }}
          inputValue={inputValueCampaign}
          menuIsOpen={false}
          isMulti={true}
          isClearable={true}
          onChange={onChangeCampaignsSelect}
          onKeyDown={handleKeyDown}
          onInputChange={(newValue) => setInputValueCampaign(newValue)}
          getOptionLabel={(option: SelectData) => option.name}
          getOptionValue={(option: SelectData) => option.value}
          placeholder='Кампания' value={campaigns}
        />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <Select
          name={'groupId'}
          isClearable={true}
          value={groupIdValue}
          onChange={onChangeGroupSelect}
          placeholder={'Все группы кампаний'}
          getOptionLabel={(option: SelectData) => option.name}
          getOptionValue={(option: SelectData) => option.value} options={response?.message.data.filter?.groups ?? []} />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <input className={'form-control'} placeholder={'% расхождений'} type='text' value={diff}
               onChange={e => setDiff(e.currentTarget.value)} />
      </div>
      <div className='col-sm-4 col-lg-2'>
        <input className={'form-control'} placeholder={'мин. расхождение'} type='text' value={minDiff}
               onChange={e => setMinDiff(e.currentTarget.value)} />
      </div>
    </div>
    <div className='row'>
      {campaigns.length === 0 && page !== 1 &&
        <button onClick={() => setPage(page - 1)} className={'btn btn-default'}>Пред. страница</button>}
      {campaigns.length === 0 && data.length === 25 &&
        <button onClick={() => setPage(page + 1)} className={'btn btn-default'}>След. страница</button>}
      <TableContainer component={Paper}>
        <Table id={'campaigns-table'} sx={{ minWidth: 650 }} aria-label='список кампаний'>
          <TableBody>
            <TableRow>
              <TableCellRaw className={'text-center'} rowSpan={2}>Id</TableCellRaw>
              <TableCellRaw className={'text-center'} rowSpan={2}>Кампания</TableCellRaw>
              <TableCellRaw className={'text-center'} rowSpan={2}>Причины</TableCellRaw>
              <TableCellRaw className={'text-center'} colSpan={4}>Профит</TableCellRaw>
            </TableRow>
            <TableRow>
              <TableCell columns={['prevDate', 'date']} className={'sticky-width-70'}
                         targetColumn={'prevDate'}>
                <TableSortLabel
                  active={sortColumn === 'prevDate'}
                  direction={sortDir}
                  onClick={createSortHandler('prevDate')}>
                  {prevDate}
                </TableSortLabel>
              </TableCell>
              <TableCell columns={['prevDate', 'date']} className={'sticky-width-70'}
                         targetColumn={'date'}>
                <TableSortLabel
                  active={sortColumn === 'date'}
                  direction={sortDir}
                  onClick={createSortHandler('date')}>
                  {date}
                </TableSortLabel>
              </TableCell>
              <TableCellRaw className={'text-center'}>Расхождение, %</TableCellRaw>
              <TableCellRaw className={'text-center'}>
                <TableSortLabel
                  active={sortColumn === 'diff'}
                  direction={sortDir}
                  onClick={createSortHandler('diff')}>
                  Расхождение
                </TableSortLabel>
              </TableCellRaw>
            </TableRow>
            {data.map((item) => (
              <React.Fragment key={item.id}>
                <TableRow onClick={() => setShownCampaignId(item.id === shownCampaignId ? 0 : item.id)}>
                  <TableCellRaw className={'text-center'}>
                    <a onClick={e => e.stopPropagation()} target={'_blank'}
                       href={`/campaigns/${item.id}/analytics?startDate=${prevDate}&endDate=${date}`}>{item.id}</a>
                  </TableCellRaw>
                  <TableCellRaw className={'text-center'}>
                    <a onClick={e => e.stopPropagation()} target={'_blank'}
                       href={`/campaigns/${item.id}/analytics?startDate=${prevDate}&endDate=${date}`}>{item.name}</a>
                  </TableCellRaw>
                  <TableCellRaw className={'text-center'}>{item.reasons.join('\n')}</TableCellRaw>
                  <TableCellRaw className={'text-center'}>{item.profit.prevDate}</TableCellRaw>
                  <TableCellRaw className={'text-center'}>{item.profit.date}</TableCellRaw>
                  <TableCellRaw
                    className={'text-center'}>
                    {item.profit.diffPercent > 100 && '>100%'}
                    {Math.abs(item.profit.diffPercent) <= 100 && item.profit.diffPercent + '%'}
                    {item.profit.diffPercent < -100 && '<-100%'}
                  </TableCellRaw>
                  <TableCellRaw
                    className={'text-center'}>{(item.profit.diff).toFixed(2)}</TableCellRaw>
                </TableRow>
                {shownCampaignId === item.id && <TableRow>
                  <TableCellRaw colSpan={7}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCellRaw className={'text-center'} rowSpan={2}>Tag id</TableCellRaw>
                            <TableCellRaw className={'text-center'} rowSpan={2}>Domain id</TableCellRaw>
                            <TableCellRaw className={'text-center'} rowSpan={2}>Причины</TableCellRaw>
                            <TableCellRaw className={'text-center'} colSpan={4}>Профит</TableCellRaw>
                          </TableRow>
                          <TableRow>
                            <TableCell columns={['prevDate', 'date']} className={'sticky-width-70'}
                                       targetColumn={'prevDate'}>
                              <TableSortLabel
                                active={sortSourceColumn === 'prevDate'}
                                direction={sortSourceDir}
                                onClick={createSourceSortHandler('prevDate')}>
                                {prevDate}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell columns={['prevDate', 'date']} className={'sticky-width-70'}
                                       targetColumn={'date'}>
                              <TableSortLabel
                                active={sortSourceColumn === 'date'}
                                direction={sortSourceDir}
                                onClick={(e) => {
                                  createSourceSortHandler('date')(e);
                                }
                                }>
                                {date}
                              </TableSortLabel>
                            </TableCell>
                            <TableCellRaw className={'text-center'}>Расхождение, %</TableCellRaw>
                            <TableCellRaw className={'text-center'}>
                              <TableSortLabel
                                active={sortSourceColumn === 'diff'}
                                direction={sortSourceDir}
                                onClick={createSourceSortHandler('diff')}>
                                Расхождение
                              </TableSortLabel>
                            </TableCellRaw>
                          </TableRow>
                          {bundleAnalyticsSources !== null && bundleAnalyticsSources.map((source) => (
                            <TableRow key={item.id + source.tagid + source.domainId}>
                              <TableCellRaw className={'text-center'}>
                                <a target={'_blank'}
                                   href={`/campaigns/${item.id}/analytics?startDate=${prevDate}&endDate=${date}&tagid=${source.tagid}&domainId=${source.domainId}`}>{source.tagid}</a>
                              </TableCellRaw>
                              <TableCellRaw className={'text-center'}>
                                <a target={'_blank'}
                                   href={`/campaigns/${item.id}/analytics?startDate=${prevDate}&endDate=${date}&domainId=${source.domainId}`}>{source.domainId}</a>
                              </TableCellRaw>
                              <TableCellRaw className={'text-center'}>{source.reasons.join('\n')}</TableCellRaw>
                              <TableCellRaw className={'text-center'}>{source.profit.prevDate}</TableCellRaw>
                              <TableCellRaw className={'text-center'}>{source.profit.date}</TableCellRaw>
                              <TableCellRaw
                                className={'text-center'}>
                                {source.profit.diffPercent > 100 && '>100%'}
                                {Math.abs(source.profit.diffPercent) <= 100 && source.profit.diffPercent + '%'}
                                {source.profit.diffPercent < -100 && '<-100%'}
                              </TableCellRaw>
                              <TableCellRaw
                                className={'text-center'}>{source.profit.diff}</TableCellRaw>
                            </TableRow>
                          ))}
                          {/*{item.sourcesTotal && <TableRow style={{ backgroundColor: 'rgba(50, 200, 50, 0.1)' }}>
                            <TableCellRaw className={'text-right'} colSpan={3}></TableCellRaw>
                            <TableCellRaw className={'text-center'}>{item.sourcesTotal.prevDate}</TableCellRaw>
                            <TableCellRaw className={'text-center'}>{item.sourcesTotal.date}</TableCellRaw>
                            <TableCellRaw
                              className={'text-center'}>{item.sourcesTotal.diffPercent > 100 ? '>100' : '<100'}%</TableCellRaw>
                            <TableCellRaw
                              className={'text-center'}>{item.sourcesTotal.diff}</TableCellRaw>
                          </TableRow>}*/}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCellRaw>
                </TableRow>}
              </React.Fragment>
            ))}
            {response?.message.data.total && <TableRow style={{ backgroundColor: 'rgba(50, 200, 50, 0.1)' }}>
              <TableCellRaw className={'text-right'} colSpan={3}>Итого</TableCellRaw>
              <TableCellRaw className={'text-center'}>{response.message.data.total.prevDate}</TableCellRaw>
              <TableCellRaw className={'text-center'}>{response.message.data.total.date}</TableCellRaw>
              <TableCellRaw className={'text-center'}>{response.message.data.total.diffPercent}%</TableCellRaw>
              <TableCellRaw
                className={'text-center'}>{response.message.data.total.diff}</TableCellRaw>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </Page>);
};


export default CampaignAnalyticsPage;