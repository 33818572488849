//@ts-nocheck
import mock from '../../mocks/campaigns/get';
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate } from '../../utils/date';
import { DateTime } from 'luxon';

const PATH_URL = '/campaigns';

export type SelectData = {
  name: string,
  value: string,
  extra: any
};

export type Selects = {
  systems: SelectData[],
  operators: SelectData[],
  formats: SelectData[],
  countries: SelectData[],
  categories: SelectData[],
  users: SelectData[],
  groups: SelectData[],
  offers: SelectData[]
};

export type CampaignRequest = {
  startDate?: string
  endDate?: string,
  campaigns?: number[],
  regions?: number[],
  archived?: string,
  disabled?: string,
  onlyAutoCpa?: string,
  systems?: number[],
  operators?: number[],
  formats?: string[],
  countries?: string[],
  excludeCountries?: boolean,
  categories?: string[],
  users?: string[],
  sort?: string,
  dir?: string,
  page?: number,
  results?: number,
  networkSystemsShow?: string,
  groupId?: number
}

export function createCampaignRequest(): CampaignRequest {
  const date = new Date();
  return { page: 1, results: 25, startDate: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'), endDate: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') };
}

export function recognizeCampaignRequest(): CampaignRequest {
  const dateStr = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  const r = { page: 1, results: 25, startDate: dateStr, endDate: dateStr };
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return createCampaignRequest();
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    // @ts-ignore
    if(key === 'startDate' || key === 'endDate') {
      r[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
      if (r[key] === 'Invalid DateTime') {
        r[key] = DateTime.fromFormat(params[key], 'yyyy-M-dd').toFormat('yyyy-MM-dd');
      }
      if (r[key] === 'Invalid DateTime') {
        r[key] = dateStr;
      }
      continue;
    }
    r[key] = params[key];
  }
  r.page = Number(r.page);
  r.results = Number(r.results);
  return r;
}

export type CampaignResponseOk = {
  'status': number,
  'message': CampaignResponseMessageOk,
}

export type CampaignResponseMessageOk = {
  total: number,
  pages: number,
  sort: string,
  order: string,
  headers: string[],
  data: Campaign[],
  isAdmin: boolean,
  totalStatistic: TotalCalculated,
  filters?: Selects,
}

export type TotalCalculated = {
  wins: number,
  spend: number,
  views: number,
  totalRequests: number,
  profit: number,
  impsReal: number,
  imps: number,
  impressions: number,
  conversions: number,
  clicks: number,
}

export type CampaignResponseError = {
  'status': number,
  'message': CampaignResponseMessageError,
}

export type CampaignResponseMessageError = string

export type CampaignLists = {
  IGNORE: 'ignore',
  BLACK: 'black',
  WHITE: 'white',
};

export enum CampaignStatus {
  ON = 'on',
  OFF = 'off',
  ARCHIVED = 'archived',
}

export type Campaign = {
  id: number,
  lists: keyof CampaignLists,
  userLogin: string,
  name: string,
  format: string,
  status: string,
  rate: number,
  limitCommon: number,
  limitDaily: number,
  spend: number,
  impsReal: number,
  totalRequests: number,
  wins: number,
  impressions: number,
  views: number,
  clicks: number,
  imps: number,
  trafficVtr: number,
  ctrPercent: number,
  cpc: number,
  winRate: number,
  convs: number,
  limitConvsCommon: number,
  limitConvsDay: number,
  rotation: number,
  conversionRate: number,
  cpm: number,
  cpa: number,
  convCost: number,
  profit: number,
  fraudEnabled: number,
  fraudRefused: number,
  fraudModulesTotal: number,
  fraudModulesEnable: number,
  trafficDynamicForHourChart: string,
  isUserSubscribed: boolean,
  isSleep: boolean
}

// @ts-ignore
const key = new apiKey();


export default function get(request: CampaignRequest): Promise<CampaignResponseOk | CampaignResponseError> {
  return axios.get(process.env.REACT_APP_API_URL + PATH_URL, {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignResponseOk | CampaignResponseError>r.data;
  }).catch(e => {
    const resp: CampaignResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function getMock(request: CampaignRequest): Promise<any> {
  return (new Promise((resolve) => {
    let resp = mock;
    resolve(resp);
  }));
}

export function isResponseOK(response: CampaignResponseOk | CampaignResponseError): boolean {
  return typeof response.message === 'object';
}