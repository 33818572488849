import React from 'react';

import { Navbar } from 'reactstrap';

const Footer = () => {
  return (
    <Navbar>
    </Navbar>
  );
};

export default Footer;
