//@ts-nocheck
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/campaigns/{id}/copyAutoCPA';

export type CampaignCopyResponseOk = {
  'status': number,
  'message': CampaignCopyResponseMessageOk,
}

export type CampaignCopyResponseMessageOk = {
  data: CampaignCopy,
}

export type CampaignCopyResponseError = {
  'status': number,
  'message': CampaignCopyResponseMessageError,
}

export type CampaignCopyResponseMessageError = string


export type CampaignCopy = {
  campaign: {
    id: number
  }
}

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string
{
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}
export default function post(id: number, copyBlacklistDetail: boolean = false ): Promise<CampaignCopyResponseOk | CampaignCopyResponseError> {
  return axios.post(getUrl(id), {
    copyBlacklistDetail: copyBlacklistDetail ? 1 : 0
  }, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignCopyResponseMessageOk | CampaignCopyResponseError>r.data;
  }).catch(e => {
    const resp: CampaignCopyResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignCopyResponseOk | CampaignCopyResponseError): boolean {
  return typeof response.message !== 'string';
}