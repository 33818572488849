import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import PropTypes from './../utils/propTypes';
import bn from './../utils/bemnames';

import Typography from './Typography';

const bem = bn.create('page');

type BreadcrumbType = {
  name: string,
  active: boolean
}

const Page = ({
                title,
                breadcrumbs,
                tag: Tag,
                className,
                children,
                ...restProps
              }: any) => {
  const classes = bem.b('px-3', className);
  return (
    <Tag className={classes} {...restProps}>
      <div className={bem.e('header')}>
        {title && typeof title === 'string' ? (
          <Typography type='h1' className={bem.e('title')}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumb className={bem.e('breadcrumb')}>
            <BreadcrumbItem>Home</BreadcrumbItem>
            {breadcrumbs.map(({ name, active }: BreadcrumbType, index: number) => (
              <BreadcrumbItem key={index} active={active}>
                {name}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
  className: '',
  children: null,
  breadcrumbs: [],
};

export default Page;
