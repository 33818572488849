//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatServerDateYmd } from '../../utils/date';

const PATH_URL = '/offer-statistic/{id}';

export type OffersResponseOk = {
  'status': number,
  'message': OffersResponseMessageOk,
}

export type Statistic = {
  date: string,
  spent: number,
  convs: number,
  profit: number,
  cpa: number
}



export type OffersResponseMessageOk = {
  data: Statistic[]
}

export type OffersResponseError = {
  'status': number,
  'message': OffersResponseMessageError,
}

export type OffersResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function getStatistic(id: number): Promise<OffersResponseOk | OffersResponseError> {
  return axios.get(getUrl(id), {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <OffersResponseOk | OffersResponseError>r.data;
  }).catch(e => {
    const resp: OffersResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: OffersResponseOk | OffersResponseError): boolean {
  return typeof response.message === 'object';
}

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id.toString());
}