import React, { ComponentProps, useContext } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import copy, {
  CampaignCopyResponseError,
  CampaignCopyResponseOk,
  isResponseOK as isCopyResponseOk,
} from '../../api/campaigns/campaign.copy.autoCPA.post';

export default function(props: ComponentProps<any> & { toggle: () => {}, campaignId: number, isOpen: boolean }) {
  const dataContext = useContext(appContext);

  const copyCampaign = () => {
    dataContext.setIsLoading(true);
    copy(props.campaignId).then(r => {
      if (!isCopyResponseOk(r)) {
        r = r as CampaignCopyResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignCopyResponseOk;
      window.open(`${process.env.REACT_APP_OLD_ADMIN}/campaign?cid=${r.message.data.campaign.id}`,'_blank');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}>
    <ModalBody>
      <h3 className='h5 text-center'>
        <b>Копирование Рекламной кампании c AutoCPA</b>
      </h3>
      <p className={'text-center'}>
        Скопировать РК?<br />
      </p>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        copyCampaign();
        props.toggle();
      }}>
        Да
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Нет
      </button>
    </ModalFooter>
  </Modal>;
}