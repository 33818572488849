import React, { ComponentProps, useContext, useEffect, useRef, useState } from 'react';
import get, {
  CampaignMaterial,
  CampaignMaterialsResponseError,
  CampaignMaterialsResponseOk,
  CreativeNative,
  isResponseOK,
  MaterialStatus,
} from '../../../api/campaigns/campaign.materials.get';
import { Paper, TableSortLabel } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Tooltip } from 'reactstrap';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Creative from './Creative';
import MaterialStatusСomp from './MaterialStatus';
import BlockIcon from '@mui/icons-material/Block';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DashboardContext from '../../../utils/context/dashboardContext';
import Switch from 'rc-switch';
import materialMassStatusUpdate, { CampaignMaterialMassStatusUpdateResponseError } from '../../../api/campaigns/campaign.material.mass_status_update.post';
import appContext from '../../../utils/context/appContext';

enum Format {
  BANNER = 'banner',
  POPUNDER = 'popunder',
  NATIVE = 'native',
  VIDEO = 'video',
  CONTEXT = 'context',
  PUSH_NATIVE = 'push-native',
  IOS_PUSH = 'ios_push',
}

enum MaterialSort {
  ID = 'id',
  NAME = 'name',
}
enum StatsMaterialSort {
  CTR_PERCENT = 'ctrPercent',
  REQUESTS = 'impsAll',
}


type SortDirection = 'asc' | 'desc';
export default function(props: ComponentProps<any> & { format: Format, campaignId: number }) {
  const [allStatusEnable, setAllStatusEnable] = useState<boolean>(false);
  const [materials, setMaterials] = useState<CampaignMaterial[]>([]);
  const [isOpenedModerationGuide, setIsOpenedModerationGuide] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<StatsMaterialSort| MaterialSort | null>(null);
  const [sortDir, setSortDir] = useState<SortDirection>('asc');
  const isMounted = useRef(false)
  const pageContext = useContext(DashboardContext);
  const dataContext = useContext(appContext);


  function sort(rows: CampaignMaterial[], sortEl: StatsMaterialSort | MaterialSort, isStats: boolean = false, isAsc: boolean = true) {
    rows.sort(function (a, b): number {
      if (isStats) {
        /** @ts-ignore */
        const val = a['stats'][sortEl as StatsMaterialSort] > b['stats'][sortEl as StatsMaterialSort] ? 1 : -1;
        if (isAsc) {
          return -1 * val;
        }
        return val;
      }
      const val = a[sortEl as MaterialSort] > b[sortEl as MaterialSort] ? 1 : -1;
      if (isAsc) {
        return -1 * val;
      }
      return val;
    })
  }

  const createSortHandler = (property: StatsMaterialSort | MaterialSort, isStats: boolean = false) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property, isStats);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: StatsMaterialSort | MaterialSort,
    isStats: boolean
  ) => {

    const isAsc = sortColumn === property && sortDir === 'asc';
    const dir = isAsc ? 'desc' : 'asc';
    setSortDir(dir);
    setSortColumn(property);
    sort(materials, property, isStats, dir === 'asc');
  };

  const updateMaterials = () => {
    get(props.campaignId, pageContext.filter?.startDate, pageContext.filter?.endDate).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignMaterialsResponseError;
        // dataContext.notify(r.message, 'error');
        return [];
      }

      r = r as CampaignMaterialsResponseOk;
      if (isMounted.current) {
        setMaterials(r.message.data);
      }
    });
  }

  useEffect(() => {
    isMounted.current = true
    updateMaterials();
    return () => {
      isMounted.current = false
    }
  }, []);

  useEffect(() => {
    let allStatusEnable: boolean = true;
    materials.map((el: CampaignMaterial) => {
      if (el.status === MaterialStatus.ON) {
        return;
      }
      allStatusEnable = false;
    })
    setAllStatusEnable(allStatusEnable);
  }, [materials]);
  return (
    <TableContainer sx={{ m: '10px' }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='материалы'>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortColumn === MaterialSort.ID}
                direction={sortDir}
                onClick={createSortHandler(MaterialSort.ID)}>
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortColumn === MaterialSort.NAME}
                direction={sortDir}
                onClick={createSortHandler(MaterialSort.NAME)}>
                Название
              </TableSortLabel>
            </TableCell>
            {![Format.NATIVE, Format.PUSH_NATIVE, Format.IOS_PUSH, Format.POPUNDER].includes(props.format) &&
              <TableCell>Креатив</TableCell>}
            {[Format.NATIVE, Format.PUSH_NATIVE].includes(props.format) &&
              (<React.Fragment>
                <TableCell>Картинка</TableCell>
                <TableCell>Лого</TableCell>
                <TableCell>Иконка</TableCell>
                <TableCell>Дата-поля</TableCell>
              </React.Fragment>)
            }
            {[Format.IOS_PUSH].includes(props.format) &&
              (<React.Fragment>
                <TableCell>Дата-поля</TableCell>
              </React.Fragment>)
            }
            <TableCell>
              <Switch checked={allStatusEnable} checkedChildren='on'
                      onChange={()=> {
                        materialMassStatusUpdate(props.campaignId, allStatusEnable ? MaterialStatus.OFF : MaterialStatus.ON).then(r => {
                          if (!isResponseOK(r)) {
                            dataContext.notify("Cannot update all materials statuses", 'error');
                            return;
                          }
                          updateMaterials();
                        })
                      }} unCheckedChildren='off' />
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortColumn === StatsMaterialSort.REQUESTS}
                direction={sortDir}
                onClick={createSortHandler(StatsMaterialSort.REQUESTS, true)}>
                Запросы
              </TableSortLabel>
            </TableCell>
            <TableCell>Хиты</TableCell>
            {props.format !== Format.POPUNDER && <TableCell>Показы</TableCell>}
            <TableCell>Клики</TableCell>
            <TableCell>VTR</TableCell>
            {props.format !== Format.POPUNDER && <TableCell>
              <TableSortLabel
                active={sortColumn === StatsMaterialSort.CTR_PERCENT}
                direction={sortDir}
                onClick={createSortHandler(StatsMaterialSort.CTR_PERCENT, true)}>
                CTR
              </TableSortLabel>
            </TableCell>}
            <TableCell>Потрачено</TableCell>
            <TableCell>CPM</TableCell>
            <TableCell>CPC</TableCell>
            <TableCell>Лиды</TableCell>
            <TableCell>Прибыль</TableCell>
            <TableCell>CPA</TableCell>
            <TableCell>
              Модерация
              <QuestionMarkIcon style={{ fontSize: '1rem' }} id={'moderationGuide'} />
              <Tooltip placement='right' target={'moderationGuide'} isOpen={isOpenedModerationGuide} toggle={() => {
                setIsOpenedModerationGuide(prev => !prev);
              }}>
                Для некоторых систем существует дополнительная модерация креативов. Ваш материал будет откручиваться
                только в случае, если модерация пройдена
              </Tooltip>
            </TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map(material => <TableRow key={material.id}>
            <TableCell>{material.id}</TableCell>
            <TableCell>{material.name}</TableCell>
            {![Format.NATIVE, Format.PUSH_NATIVE, Format.IOS_PUSH, Format.POPUNDER].includes(props.format) &&
              <TableCell sx={{ p: '10px' }}>
                <Creative format={material.format} creative={material.creative} />
              </TableCell>}
            {[Format.NATIVE, Format.PUSH_NATIVE].includes(props.format) &&
              (<React.Fragment>
                {<CreativeNativeImage creative={material.creative} />}
                {<CreativeNativeLogo creative={material.creative} />}
                {<CreativeNativeIcon creative={material.creative} />}
                {<CreativeNativeDataFields creative={material.creative} />}
              </React.Fragment>)
            }
            {[Format.IOS_PUSH].includes(props.format) &&
              (<React.Fragment>
                {<CreativeNativeDataFields creative={material.creative} />}
              </React.Fragment>)
            }
            <TableCell>
              <MaterialStatusСomp material={material} />
            </TableCell>
            <TableCell>{material.stats.impsAll}</TableCell>
            <TableCell>{material.stats.wins}</TableCell>
            {props.format !== Format.POPUNDER && <TableCell>{material.stats.hitreal}</TableCell>}
            <TableCell>{material.stats.clicks}</TableCell>
            <TableCell>{material.stats.vtrPercent.toFixed(2)}</TableCell>
            {props.format !== Format.POPUNDER && <TableCell>{material.stats.ctrPercent.toFixed(2)}</TableCell>}
            <TableCell>{material.stats.spend.toFixed(2)}</TableCell>
            <TableCell>{material.stats.cpm.toFixed(2)}</TableCell>
            <TableCell>{material.stats.cpc.toFixed(3)}</TableCell>
            <TableCell>{material.stats.convs}</TableCell>
            <TableCell>{material.profit}</TableCell>
            <TableCell>{material.stats.cpa.toFixed(2)}</TableCell>
            <TableCell>
              {material.moderationStatus[0] && material.moderationStatus[0] === material.moderationStatus[1] ?
                (<span style={{ color: 'green' }}>Принят для всех систем</span>) : null}
              {material.moderationStatus[0] && material.moderationStatus[0] !== material.moderationStatus[1] ?
                (<span
                  style={{ color: 'green' }}>Принят для {material.moderationStatus[0]} из {material.moderationStatus[1]} систем</span>) : null}
            </TableCell>
            <TableCell>
              <a href={'#'}><BlockIcon style={{ fontSize: '20px' }} /></a>
              <a href={'#'}><FileCopyIcon style={{ fontSize: '20px' }} /></a>
              <a target={'_blank'}
                 href={process.env.REACT_APP_OLD_ADMIN + `/edit_material?adid=${material.id}`}><EditIcon
                style={{ fontSize: '20px' }} /></a>
              <a href={'#'}><DeleteIcon style={{ fontSize: '20px' }} /></a>
              <a target={'_blank'}
                 href={process.env.REACT_APP_OLD_ADMIN + `/statistics2?date_from=${pageContext.filter?.startDate}&hour_from=00&date_to=${pageContext.filter?.endDate}&hour_to=00&GMT=0&group_blocks%5B%5D=date&bidfloor_from=&bidfloor_to=&status=on_off&campaign%5B%5D=${props.campaignId}&sort=date&order=desc`}><QueryStatsIcon
                style={{ fontSize: '20px' }} /></a>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>);
}

function CreativeNativeLogo(props: ComponentProps<any> & { creative: CreativeNative }) {
  if (props.creative.img === undefined || props.creative.img[2] === undefined) {
    return (<TableCell><span>Отсутствует</span></TableCell>);
  }
  return (<TableCell>
    <img style={{ maxWidth: '100px' }} src={props.creative.img[2].value} />
  </TableCell>);
}

function CreativeNativeImage(props: ComponentProps<any> & { creative: CreativeNative }) {
  if (props.creative.img === undefined || props.creative.img[3] === undefined) {
    return (<TableCell><span>Отсутствует</span></TableCell>);
  }
  return (<TableCell>
    <img style={{ maxWidth: '100px' }} src={props.creative.img[3].value} />
  </TableCell>);
}

function CreativeNativeIcon(props: ComponentProps<any> & { creative: CreativeNative }) {
  if (props.creative.img === undefined || props.creative.img[1] === undefined) {
    return (<TableCell><span>Отсутствует</span></TableCell>);
  }
  return (<TableCell>
    <img style={{ maxWidth: '100px' }} src={props.creative.img[1].value} />
  </TableCell>);
}

function CreativeNativeDataFields(props: ComponentProps<any> & { creative: CreativeNative }) {
  return (<TableCell>
    {props.creative?.title ? (<span>
          <span><b>Title:</b> {props.creative?.title.value}</span>
          <br />
        </span>) : null}
    {props.creative?.data && Object.keys(props.creative.data).map((key) => {
      return (<span key={key}>
          <span><b>{props.creative.data[key]?.desc}:</b> {props.creative.data[key]?.value}</span>
          <br />
        </span>);
    })}
  </TableCell>);
}